import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";

function CloseTicketList(props) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;

  // console.log(userstatus.userstatus);

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [status, setStatus] = useState("Closed");
  const [showFilters, setShowFilters] = useState(true);

  //   console.log(status);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [ticketCategory, setticketCategory] = useState("All");

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Martinpay Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: status,
      TicketType: ticketCategory

    };


    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetSupportTicket",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofTickets);
          // console.log(response.data.ListofTickets);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, status, userstatusVal, ticketCategory]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            {/* <td>{count++ + pageSize * (pageNo - 1)}</td> */}
            <td>{item.TicketID}</td>
            <td></td>
            <td>
              {moment(item.Dated).format('DD MMM, YYYY HH:mm:ss')}
            </td>
            <td>{item.TicketType}</td>
            <td>
              {item.Subject.length > 50
                ? `${item.Subject.substring(0, 50)}...`
                : item.Subject}
            </td>



            <td className="text-center">
              <NavLink
                to={`/admin/support-dashboard/detail/${item.ID}`}
                className="button-detail support-action">
                Detail <span><i className="material-symbols-outlined">chevron_right</i></span>
              </NavLink>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4 className="else">
                            No Data Found
                        </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <AdminRightsCheck />
      <div className="admin-content">

        {/* <div className="card card-table mb-5 mt-3 ">
          <div className="card-inner d-flex">
            <h6 className="my-auto mx-4"> Select Status</h6>
            <select
              id="dropdown"
              className="form-select"
              style={{ width: "10rem" }}
              value={userstatusVal ? userstatusVal : status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Open Closed Pending" selected>
                All
              </option>
              <option value="Open"> Opene</option>
              <option value="Closed"> Close</option>
              <option value="Pending"> Pending</option>

            </select>
            <h6 className="my-auto mx-4"> Select Type</h6>
            <select
              id="dropdown"
              className="form-select"
              style={{ width: "10rem" }}
              value={userstatusVal ? userstatusVal : status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Open Closed Pending" selected>
                All
              </option>
              <option value="Open"> Opene</option>
              <option value="Closed"> Close</option>
              <option value="Pending"> Pending</option>

            </select>

          </div>
        </div>
        <div className="text-end">
          <NavLink to="/admin/support-dashboard">
            <button type="button" className="button button-primary">
              Back
            </button>
          </NavLink>
        </div> */}
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              List of Tickets
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <NavLink to="/admin/support-dashboard">
              <button type="button" className="button button-outline-bs">
                Back
              </button>
            </NavLink>
            <button
              onClick={() => {
                setShowFilters((prevIsActive) => !prevIsActive);
              }}
              className="button button-primary ms-3"
              style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
            >
              {showFilters ? "Hide Filters" : "Show Filters"}
              <i className="ri-filter-3-line ms-3"></i>
            </button>
          </div>
        </div>





        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4 ">
            <div className="card-inner  ">

              <div className="form_default">
                <div className="row p-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor=""> Select Ticket Type</label>


                      <div className="inputwrap">

                        <select
                          id="dropdown"
                          className="form-select"
                          // style={{ width: "10rem" }}
                          onChange={(e) => setticketCategory(e.target.value)}
                        >
                          <option value="All" selected>
                            All
                          </option>
                          <option value="General">General</option>
                          <option value="Priority Support"> Priority Support </option>
                          <option value="Verification"> Verification </option>
                          <option value="Deposit"> Deposit </option>
                          <option value="Withdrawal"> Withdrawal </option>
                          <option value="Transfer"> Transfer </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-table mb-5 mt-3">
        <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Ticket ID</th>
                  <th>Username</th>
                  <th>Raised On</th>
                  <th>Department</th>
                  <th>Subject</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="10">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CloseTicketList;
