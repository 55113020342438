import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import RoiTable from "./RoiTable";
import TreeBonusTable from "./TreeBonusTable";
import MatrixBonusTable from "./MatrixBonusTable";
import ClubReturnTable from "./ClubReturnTable";
import CommodityRoiTable from "./CommodityRoiTable";
import RepurchaseMembershipsTable from "./RepurchaseMembershipsTable";
import CashbackMembershipsTable from "./CashbackMembershipsTable";
import NetworkBonusTable from "./NetworkBonusTable";
import Settle6xTable from "./Settle6xTable";
import AmbassadorPoolTable from "./AmbassadorPoolTable";
import PasstiveToCashTable from "./PasstiveToCashTable";
import PromoVoucherTable from "./PromoVoucherTable";

function Schedulers(props) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;

  // console.log("date", userstatus.userstatus);

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [RoiLoading, setRoiLoading] = useState(false);
  const [TreeBonusLoading, setTreeBonusLoading] = useState(false);
  const [MatrixBonusLoading, setMatrixBonusLoading] = useState(false);
  const [TwentyBonusLoading, setTwentyBonusLoading] = useState(false);
  const [NetworkBonusLoading, setNetworkBonusLoading] = useState(false);
  const [SendInMatrixLoading, setSendInMatrixLoading] = useState(false);
  const [Settle6xLoading, setSettle6xLoading] = useState(false);
  const [GenerateAmbassadorLoading, SetGenerateAmbassadorLoading] = useState(false);
  const [TransferAmbassadorLoading, SetTransferAmbassadorLoading] = useState(false);
  const [TransferPassivetoCashLoading, SetTransferPassivetoCashLoading] = useState(false);
  const [SendPromoVoucherLoading, setSendPromoVoucherLoading] = useState(false);

  const [listOfLedger, setLedger] = useState([]);
  const [status, setStatus] = useState("");
  const [Date, setDate] = useState("");

  //   console.log(status);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let navigate = useNavigate();

  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => {
    document.title = "Martinpay Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: userstatusVal ? userstatusVal : status,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMembersList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          //console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          // navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, status, userstatusVal]);

  const SendROIScheduler = (page) => {
    setRoiLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      startDate: Date,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/SendROIScheduler",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRoiLoading(false);
        } else if (
          response.data.status_code === 0 ||
          response.data.status_code === 2
        ) {
          setRoiLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRoiLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const SendTreeBonusScheduler = (page) => {
    setRoiLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      startDate: Date,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/SendTreeBonusScheduler",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTreeBonusLoading(false);
        } else if (
          response.data.status_code === 0 ||
          response.data.status_code === 2
        ) {
          setTreeBonusLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTreeBonusLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };


  const TransferPassivetoCashScheduler = (page) => {
    SetTransferPassivetoCashLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      startDate: Date,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/TransferPassiveToCashScheduler",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          SetTransferPassivetoCashLoading(false);
        } else if (
          response.data.status_code === 0 ||
          response.data.status_code === 2
        ) {
          SetTransferPassivetoCashLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          SetTransferPassivetoCashLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const SendPromoVoucherScheduler = (page) => {
    setSendPromoVoucherLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/SendPromoVoucherScheduler",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSendPromoVoucherLoading(false);
        } else if (
          response.data.status_code === 0 ||
          response.data.status_code === 2
        ) {
          setSendPromoVoucherLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSendPromoVoucherLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">ROI Distribution Panel</div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/userstatistics-dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary text-white ms-3"
                style={{
                  fontWeight: "500",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="scheduler">
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row p-3 gy-3 align-items-md-end align-align-items-center">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Select Date</label>
                      <div className="inputwrap">
                        <input
                          value={Date}
                          onChange={(e) => setDate(e.target.value)}
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button className="button button-primary button-smlg input-height input-radius w-100">
                      Load Page Summary
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-title mb-3">
            Profit Distribution{" "}
            <span className="fs-6">(Daily working Days)</span>
          </div>
          <div className="card card-table gray-outline">
            <div className="card-inner px-md-5 py-md-4 px-3 py-md-4">
              <div className="d-title mt-md-0 mt-3">ROI</div>
              <div className="card mb-3 mt-4" style={{background:"1c1b19",borderRadius:"16px"}}>
                <div className="card-inner" style={{background:"#1c1b19",borderRadius:"16px"}}>
                  <div className="form_default">
                    <div className="row p-md-3 p-2 gy-3 align-items-top">
                      <div className="col-md-10">
                        <div className="section-detail">
                          <span className="text-primary">Auto: </span>
                          Send Daily Roi Scheduler is already running at 00 1
                          AlertSendDailyROI Automatic Scheduler substracts 1 day
                          from the current date
                          <br />
                          Manual: it sends for the same date selected above
                        </div>
                      </div>
                      <div className="col-md-2 text-md-end">
                        <button
                          disabled={RoiLoading}
                          className="button small-button button-primary button-smlg"
                          onClick={SendROIScheduler}
                        >
                          Send ROI {RoiLoading ? '...' : ''}
                        </button>
                      </div>
                    </div>
                    <div className="px-3">
                      <div className="sub_heading">
                        <Link
                          className=""
                          to={`/admin/reports/Scheduler/roi-Summary`}
                        >
                          Recent Transactions
                        </Link>
                      </div>
                      <div
                        className="card card-table mb-5 mt-3"
                      >
                        <div className="card-inner">
                          <RoiTable />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-title">Royalty Income</div>
              <div className="card mb-3 mt-4" style={{background:"1c1b19",borderRadius:"16px"}}>
                <div className="card-inner" style={{background:"#1c1b19",borderRadius:"16px"}}>
                  <div className="form_default">
                    <div className="row p-md-3 p-2 gy-3 align-items-top">
                      <div className="col-md-9">
                        <div className="section-detail">
                          <b>
                            Scheduler is already running with the name
                            AlertSendTreeBonus at trgSendTreeBonus 00:10
                          </b>
                          <br />
                          <span className="text-primary">Auto: </span>
                          It substract 1 day from the current date time for
                          sending
                          <br />
                          Manual: It sends in same date selected above
                        </div>
                      </div>
                      <div className="col-md-3 text-md-end">
                        <button
                          disabled={TreeBonusLoading}
                          className="button small-button button-primary button-smlg"
                          onClick={SendTreeBonusScheduler}
                        >
                          Send Royalty Income {TreeBonusLoading ? '...' : ''}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="sub_heading">
                      <Link to={`/admin/reports/Scheduler/TreeBonus-Summary`}>
                        Recent Transactions
                      </Link>
                    </div>
                    <div
                      className="card card-table mb-5 mt-3"
                    >
                      <div className="card-inner">
                        <TreeBonusTable />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-title my-3">
            Profit Wallet Distribution <span className="fs-6">(Monthly)</span>
          </div>
          <div className="card card-table gray-outline">
            <div className="card-inner px-md-5 py-md-4 px-3 py-md-4">

              <div className="d-title">
                Profit Wallet <span>(Monthly)</span>
              </div>
              <div className="card mb-3 mt-4" style={{background:"1c1b19",borderRadius:"16px"}}>
                <div className="card-inner" style={{background:"#1c1b19",borderRadius:"16px"}}>
                  <div className="form_default">
                    <div className="row p-md-3 p-2 gy-3 align-items-center">
                      <div className="col-md-9">
                        <div className="section-detail">
                          <span className="text-primary">Auto: </span> Scheduler
                          is running on 1st of Every Month at 1:30AM
                          (trgSendPassiveToCash) <br />
                          Manual: It sends on same date selected above for
                          manual.
                        </div>
                      </div>
                      <div className="col-md-3 text-md-end">
                        <button
                          disabled={TransferPassivetoCashLoading}
                          className="button small-button button-primary button-smlg"
                          onClick={TransferPassivetoCashScheduler}
                        >
                          Transfer Profit To Martin Pay {TransferPassivetoCashLoading ? '...' : ''}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="sub_heading">
                      <Link
                        className=""
                        to={`/admin/reports/Scheduler/PassiveWallet-Summary`}
                      >
                        Recent Transactions
                      </Link>
                    </div>
                    <div
                      className="card card-table mb-5 mt-3"
                    >
                      <div className="card-inner">
                        <PasstiveToCashTable />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-title">Promo Voucher</div>
              <div className="card mb-3 mt-4" style={{background:"1c1b19",borderRadius:"16px"}}>
                <div className="card-inner" style={{background:"#1c1b19",borderRadius:"16px"}}>
                  <div className="form_default">
                    <div className="row p-md-3 p-2 gy-3 align-items-center">
                      <div className="col-md-4 offset-md-8 text-md-end">
                        <button
                          disabled={SendPromoVoucherLoading}
                          className="button small-button button-primary button-smlg"
                          onClick={SendPromoVoucherScheduler}
                        >
                          Transfer MAG Promo Amount {SendPromoVoucherLoading ? '...' : ''}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="sub_heading">
                      <Link
                        className=""
                        to={`/admin/reports/Scheduler/PromoVoucher-Summary`}
                      >
                        Recent Transactions
                      </Link>
                    </div>
                    <div
                      className="card card-table mb-5 mt-3"
                    >
                      <div className="card-inner">
                        <PromoVoucherTable />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Schedulers;
