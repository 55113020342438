import React, { useState, useEffect } from "react";
import { BanknotesIcon, CalendarDaysIcon, ChartBarIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { NavLink, Link } from "react-router-dom";
import wallet from "../../assets/images/martinpay/wallet.svg";
import foundation from "../../assets/images/martinpay/foundation.png";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import Config from "../../Config";
import { NumberFormat } from "./General/Functions";
import BsPagination from "./BsPagination";

function BusinessFoundation(props) {
    const [Day, setDay] = useState('Today');
    const [BarShape, setBarShape] = useState('Cell');

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPaid, settotalPaid] = useState(0);



    useEffect(() => {

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            PageNo: pageNo,
            PageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDeposits',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                setLedger(response.data.ListofOrders);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                settotalPaid(response.data.totalPaid);
            })
            .catch(function (error) {
                console.log(error);
            });



    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };




    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.PaymentID}</td>
                            <td> {moment(item.Dated).format('DD/MM/YYYY HH:mm')}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="6">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }

    return (
        <>
            <div className="section-title">Foundation</div>
            <img src={foundation} alt="foundation" className="img-fluid rounded-4 mb-4" />
            <div className="row gy-3 align-items-center mb-4">
                <div className="col-md-4">
                    <div className="dashboard_wallets dash-bg-card">
                        <h4>Donation Wallets</h4>
                        <div className="thumnail">
                            <img src={wallet} alt="" />
                            <div className="content">
                                <span>Donation Amount</span>
                                <h3>PKR 23,600</h3>
                            </div>
                        </div>
                        <div className="">
                            <NavLink to="/martinpay/addmoney" className="button button-bs input-height w-100 button-icon-bs justify-content-center">
                                Donate Now
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="earning-card dash-bg-card">
                        <div className="row align-items-end">
                            <div className="col-md-12">
                                <div className="dash-header">
                                    <div className="heading">
                                        Transactions
                                    </div>
                                    <div className="menu">
                                        <ul className="menu-link">
                                            <li><a className={Day == "Today" ? "active" : ""} onClick={(e) => setDay("Today", e)} href="#">Today</a></li>
                                            <li><a className={Day == "Week" ? "active" : ""} onClick={(e) => setDay("Week", e)} href="#">This Week</a></li>
                                            <li><a className={Day == "LastFifteen" ? "active" : ""} onClick={(e) => setDay("LastFifteen", e)} href="#">Last 15 Days</a></li>
                                            <li><a className={Day == "LastMonth" ? "active" : ""} onClick={(e) => setDay("LastMonth", e)} href="#">Last Month</a></li>
                                            <li>
                                                <button className="" onClick={(e) => {
                                                    const picker1 = document.getElementById("header-date");
                                                    picker1.showPicker();
                                                }}>
                                                    <CalendarDaysIcon />
                                                </button>
                                                <input
                                                    type="date"
                                                    id="header-date"
                                                    name="header-date"
                                                    className="menu-date"
                                                    placeholder="DD/MM/YY"
                                                />
                                            </li>
                                        </ul>
                                        <ul className="menu-icon">
                                            <li><a className={BarShape == "Cell" ? "active" : ""} onClick={(e) => setBarShape("Cell", e)} href="#"><TableCellsIcon /></a></li>
                                            <li><a className={BarShape == "Bar" ? "active" : ""} onClick={(e) => setBarShape("Bar", e)} href="#"><ChartBarIcon /></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="bar-background">
                                    {/* <PaymentStatistics /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="partner-table">
                <b className="text-start p-3 pt-0 w-100">Charity Transactions</b>
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Donate By</th>
                                <th>Package</th>
                                <th>Type</th>
                                <th>Donation Amount</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }
                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                       
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                          onPageSizeChange={(newPageSize) =>  { 
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default BusinessFoundation;