import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import csvDownload from "json-to-csv-export";
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { FirstColumnData, calculateColumnTotal } from "../../Dashboard/General/Util";
import { Form } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import ReactTooltip from "react-tooltip";

function RoiTransaction(props) {
    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );

    const [showFilters, setShowFilters] = useState(true);

    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfRecord, setListOfRecord] = useState([]);
    const [packageName, setPackageName] = useState("");

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    const [ExcelPageSize, setExcelPageSize] = useState(10000);
    const [ExcelArray, setExcelArray] = useState([]);

    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    useEffect(() => {
        document.title = "Martinpay Admin";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: StartDate,
            endDate: EndDate,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetROIGroupDetails",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        // console.log(data)s;

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {

                    // console.log("data", response.data);
                    setListOfRecord(response.data.ListofRecords);
                    setGrandTotal(response.data);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                }
                else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {

                    // navigate("/admin/login")

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, StartDate, EndDate]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        if (listOfRecord != null && listOfRecord.length > 0) {
            let count = 1;
            return listOfRecord.map((item, index) => {
                //console.log(item);
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.RewardDated).format('DD/MM/YYYY HH:mm')}</td>
                        <td>PKR {NumberFormat(item.PackageAmount)}</td>
                        <td>PKR {NumberFormat(item.Released)}</td>
                        <td>PKR {NumberFormat(item.TotalReceived)}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="8">
                        <h4 className="else">
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    useEffect(() => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetROIGroupDetails",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                let data = response.data.ListofRecords;
                let count = 0;
                const listOfLedgerNew = data.map(({ ...rest }) => ({
                    Serial_No: count++ + pageSize * (pageNo - 1),
                    Date: moment(rest.RewardDated).format("DD/MM/YYYY HH:mm"),
                    Total_Packages: rest.PackageAmount,
                    Daily_Released: rest.Released,
                    Total_Profit_Released: rest.TotalReceived,
                }));

                setExcelArray(listOfLedgerNew)
            })
            .catch(function (error) {
            });
    }, [pageNo, pageSize, isUpdated, StartDate, EndDate]);



    return (
        <>
            <AdminRightsCheck />
            <ToastContainer />
            <ReactTooltip />
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            ROI Summary
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/reports/Scheduler">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3">

                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/reports/Scheduler/roi-Summary-pdf/?StartDate=${StartDate}&EndDate=${EndDate}`}>PDF</Link>
                                            </li>
                                            <li className="round-button" data-tip={'Download as Excel'} data-place="bottom">
                                                <JsonToExcel
                                                    title="EXCEL"
                                                    data={ExcelArray}
                                                    fileName="MAG TOTAL PAID WITHDRAWAL REPORT"
                                                    btnClassName="excel-button"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card-table mb-5 mt-3">
                        <div className="card-inner responsive-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Date</th>
                                        <th>Total Packages</th>
                                        <th>Daily Released</th>
                                        <th>Total Profit Released</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td className="text-center" colSpan="10">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>{renderLedger()}

                                            {
                                                listOfRecord != null && listOfRecord.length > 0 &&
                                                <>
                                                    <tr style={{ borderButtom: "none !important" }}>
                                                        <th className="text-end border-bottom-0 pt-3" colSpan={2}>Subtotal:</th>
                                                        <th className="border-bottom-0"></th>
                                                        <th className="border-bottom-0"></th>
                                                        <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfRecord, 'TotalReceived'))}</th>
                                                    </tr>
                                                    <tr className="">
                                                        <th className="text-end border-bottom-0" colSpan={2}>Grand Total: </th>
                                                        <th className="border-bottom-0 pt-3">PKR {NumberFormat(FirstColumnData(listOfRecord, 'PackageAmount'))}</th>
                                                        <th className="border-bottom-0"></th>
                                                        <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.totalReceived)}</th>
                                                    </tr>
                                                </>
                                            }
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {totalRecords > 10 && (
                        <div className="pagination-wrap">
                            <div className="row align-items-center">
                                <div className="col-md-2 form_default">
                                    <div className="d-flex align-items-center">
                                        <label className="me-2" style={{ fontSize: "15px" }}>
                                            Page size
                                        </label>
                                        <div className="form-group">
                                            <select
                                                className="form-select"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                    setPageNo(1);
                                                    setPageSize(parseInt(e.target.value));
                                                }}
                                            >
                                                <option value="10" selected>
                                                    10
                                                </option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <Pagination
                                    pageNo={pageNo}
                                    pageSize={pageSize}
                                    totalRecords={totalRecords}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
}

export default RoiTransaction