import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import config from "../../../Config";
import UserGroupDropDown from "./UserGroupDropDown";
import { useParams } from "react-router-dom";
import AdminRightsCheck from "../AdminRightsCheck";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
const UpdateUser = () => {
  let params = useParams();

  let navigate = useNavigate();

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [Id, setId] = useState(params.id);

  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState("");
  const [fullName, setfullName] = useState();
  const [password, setpassword] = useState("");
  const [group, setgroup] = useState();
  const [retype_password, setretype_password] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [needToChangePassword, setNeedToChangePassword] = useState(false);
  const [disabled, setDisabled] = useState(false);


  const [PinCode, setPinCode] = useState('');

  const [PinCodeShow, setPinCodeShow] = useState(true);

  const [User, setUser] = useState({});

  useEffect(() => {
    document.title = "Martinpay Admin";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: Id,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Security/GetUserByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setUser(response.data);
          setgroup(response.data.Group_ID);
          setfullName(response.data.Full_Name);
          setemail(response.data.Email_Address);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: Id,
      Email_Address: email,
      Password: password,
      Group_ID: group,
      Full_Name: fullName,
      PinCode: PinCode,
    };

    if (!isValidEmail || !passwordsMatch) {

      toast.error("Wrong email pattren entered!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      var api_config = {
        method: "post",
        url: config.base_url + "Security/UpdateUser",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setTimeout(() => {
              navigate("/admin/security-dashboard/manage-users");
            }, 2000);
          } else if (response.data.status_code == 2) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });

      // setValidated(true);
    }

  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNeedToChangePassword(true);
    setpassword(newPassword);
    setPasswordsMatch(newPassword === retype_password);
    setDisabled(newPassword !== retype_password);
  };

  const handleRetypePasswordChange = (e) => {
    const newRetypedPassword = e.target.value;
    setretype_password(newRetypedPassword);
    setPasswordsMatch(password === newRetypedPassword);
    setDisabled(newRetypedPassword !== password);
  };

  const validateEmail = (email) => {
    // You can implement your own email validation logic here
    // For simplicity, let's use a basic regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };
  const checkEmail = () => {
    setIsValidEmail(validateEmail(email));
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <ToastContainer />
      <AdminRightsCheck />

      <div className="admin-content">
        <div className="row align-items-center mb-3 gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Update User
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <NavLink to="/admin/security-dashboard/manage-users">
              <button type="button" className="button button-outline-bs">
                Back
              </button>
            </NavLink>
          </div>
        </div>

        <div className="card card-table form_default">
          <form onSubmit={handleSubmit}>
            <div className="card-inner p-4 form-group">

              <div className="row align-items-center gy-3">
                <div className="col-md-3">
                  <h6 className="">Full Name</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={User.Full_Name}
                      onChange={(e) => setfullName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <h6 className="">Email Address</h6>
                  <div className="inputwrap">
                    <input
                      type="email"
                      className="form-control"
                      defaultValue={User.Email_Address}
                      onChange={handleEmailChange}
                      onBlur={checkEmail}
                      required
                    />
                  </div>

                  {!isValidEmail && <div style={{ fontSize: "12px", color: "red" }}>Invalid email address</div>}
                </div>

                <div className="col-md-3">
                  <div className="icon-input-form">
                    <label htmlFor="">Password</label>
                    <div className="icon-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        defaultValue={password}
                        onChange={handlePasswordChange}
                      // maxLength={18}
                      />
                      <div className="icon" onClick={togglePasswordVisibility}>
                        {
                          showPassword ?
                            <EyeSlashIcon />
                            :
                            <EyeIcon />
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="icon-input-form">
                    <label htmlFor="">Retype Password</label>
                    <div className="icon-input">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                        defaultValue={retype_password}
                        onChange={handleRetypePasswordChange}
                      // maxLength={18}
                      />
                      <div className="icon" onClick={toggleConfirmPasswordVisibility}>
                        {
                          showConfirmPassword ?
                            <EyeSlashIcon />
                            :
                            <EyeIcon />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-form-group">
                    <label className="">Pin Code</label>
                    <div className="icon-input">

                      <input
                        type={PinCodeShow ? "text" : "password"}
                        placeholder={User.PinCode}
                        value={PinCode}
                        onChange={(e) => {
                          if (e.target.value.length <= 6) {
                            setPinCode(e.target.value);
                          }
                        }}
                      />
                      <div className="icon" onClick={() => setPinCodeShow(!PinCodeShow)}>
                        {PinCodeShow ? <EyeSlashIcon /> : <EyeIcon />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-form-group">
                    <label className="">Select User Group</label>
                    <div className="icon-input">
                      <select
                        id="dropdown"
                        className="form-select"
                        //  required
                        onChange={(e) => setgroup(e.target.value)}
                      >
                        <UserGroupDropDown selected={User.Group_ID} />
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 offset-md-3">
                  <button
                    type="submit"
                    className="button button-primary input-height input-radius mt-md-3 w-100"
                    disabled={disabled || !isValidEmail || loading}
                  >
                    Update {loading ? '...' : ''}
                  </button>
                </div>
                <div className="col-md-12 text-center">

                  <h6 className="text-primary">If you don't want to change password and Pin Code, keep them blank.</h6>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateUser;
