import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import OtpInput from "react-otp-input";
import SendEmail from "../../Common/SendEmail";
import { ArrowDownLeftIcon, ArrowLongLeftIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function Step2(props) {

    const [percentage, setPercentage] = useState(props.Commission);
    const [finalAmount, setFinalAmount] = useState(0);
    const [remainingBalance, setRemainingBalance] = useState(props.balance);
    const [UserBalance, setUserBalanceBalance] = useState(props.balance);
    const [OTP, setOTP] = useState(0);
    const [commissionAmount, setcommissionAmount] = useState(0);
    const [totalDeduction, setTotalDeduction] = useState(0);
    const [balanceLimit, setbalanceLimit] = useState();
    const [amount, setAmount] = useState(props.step2Data.Current_Balance);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [CodeCheck, setCodeCheck] = useState("");

    // console.log(props.step2Data);

    const [showError, setShowError] = useState(false);

    const [Amounterror, setAmounterror] = useState(false);

    const CalcPercentage = (e) => {
        let amount = e.target.value;

        if (amount > UserBalance) {
            toast.error("Amount should be less than your balance", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setAmount("");
            return false;
        }

        if (amount == 0 || amount != "") {
            if (amount < 1000) {
                setAmounterror(true);

            }
            else {
                setAmounterror(false)
            }

        }

        //setAmount(amount.slice(0, 7));

        // if (amount <= props.balance) {
        //     setAmount(amount);
        // }


        let commissionAmount = amount / 100 * percentage;
        let final = parseInt(amount) - parseInt(commissionAmount);
        setFinalAmount(final);
        setcommissionAmount(commissionAmount);
        // console.log(commissionAmount);
        setRemainingBalance(props.balance - final);
        setTotalDeduction(parseInt(amount) + parseInt(commissionAmount));


        if (amount == 0) {
            setRemainingBalance(props.balance);
        }
        setAmount(amount);


        // Check if the value is a valid multiple of 5 or 10

        // if (final === '' || isMultipleOf5Or10(final)) {
        //     setAmount(final);
        //     setShowError(false); // Hide the error message when a valid amount is entered
        // } else {
        //     setShowError(true); // Show the error message when an invalid amount is entered
        // }

    }


    useEffect(() => {
        let length = props.balance.toString().split('.')[0].length;
        setbalanceLimit(length);
    }, []);


    const handleSendCode = () => {
        const props = {
            TransactionType: 'BalanceTransfer'
        };


        SendEmail(props);

        setIsButtonDisabled(true);

        // Enable the button after 1 minute
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);
        setTimer(60);
    }

    const [timer, setTimer] = useState(60); // 60 seconds

    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isButtonDisabled]);

    const Checkcode = (e) => {
        let value = e.target.value
        setCodeCheck(value)
    }

    return (
        <>
            <ToastContainer />
            <Form noValidate validated={props.validated2} onSubmit={props.handleSubmitAmount}>
                {props.hasError ? <Alert key="danger" variant="danger">{props.errorMessage}</Alert> : ""}
                <div className="transfer-wrap">
                    <div className="available-balance">
                        <label htmlFor="">Available Balance </label>
                        {/* <span>PKR {!isNaN(amount) ? - (UserBalance - amount) : UserBalance}</span> */}
                        <span>{!isNaN(amount) ? (UserBalance - amount).toFixed(3) : UserBalance}</span>
                    </div>
                    <div className="transfer-body">
                        <div className="fake-input mb-3">
                            <label>Beneficiary</label>
                            <span>{props.user.User_Name}</span>
                        </div>
                        <div className="mb-3">
                            <div className="icon-input">
                                <input type="number"
                                    name="amount"
                                    placeholder="Add Amount"
                                    onChange={(e) => CalcPercentage(e)}
                                    autoComplete="off"
                                    value={amount}
                                    required />
                            </div>
                            <ul className="add-condition">
                                {Amounterror && <li> <p className="Resend m-0"> Minimum amount: 1,000 PKR</p></li>}
                                {/* {showError && <li><p className="Resend p-0"> Amount should be in (multiples of 5 or 10).</p></li>} */}
                            </ul>
                            {/* <p className="available-amout">Available Balance: <span> $ {remainingBalance.toFixed(3)}</span></p> */}
                        </div>
                        <div className="icon-input">
                            <input type="text" placeholder="Add Note (Optional)" name="notes" maxLength={20} />
                        </div>

                        <div className="icon-input">
                            <input type="text" placeholder="Fee" className="disabled" value={commissionAmount != 0 ? commissionAmount : ""} readOnly={true} />
                            <div className="icon">
                                <b>({percentage}%)</b>
                            </div>
                        </div>
                        <div className="icon-input">
                            <input type="text" placeholder="Receivable Amount in PKR" className="disabled" readOnly={true} />
                            <div className="icon">
                                <b>{`${!isNaN(finalAmount) ? finalAmount : ''}`}</b>
                            </div>
                        </div>

                        <div className="code pb-2 pt-3 mb-3">
                            <div className="row align-items-top">
                                <div className="col-md-9">
                                    <div className="icon-form-group">
                                        <div className="icon-input">
                                            <input type="text"
                                                onChange={Checkcode}
                                                placeholder='Email Verification Code' name="AuthCode" autoComplete="disable" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <button
                                        className="button button-outline-bs input-height w-100 text-nowrap"
                                        style={{ borderRadius: '12px' }}
                                        onClick={handleSendCode}
                                        disabled={isButtonDisabled}
                                    >
                                        GET CODE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="icon-form-group">
                            <label htmlFor="">Transaction PIN</label>
                            <div className="icon-input">
                                <input type="text"
                                    placeholder='XXXXXX' name="pincode" maxLength={6} autoComplete="disable" required className="text-center" />
                            </div>
                        </div>
                    </div>
                    <div className="transfer-footer">
                        <button onClick={props.toStep1} className="button button-outline-bs button-icon-bs justify-content-center" ><ArrowLongLeftIcon /><span>BACK</span></button>
                        <button className={`button button-bs`} disabled={OTP.length === 0 || amount?.length === 0 || CodeCheck.length === 0 || Amounterror == true || props.loading}>
                            Transfer {props.loading ? "..." : ""}
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default Step2;