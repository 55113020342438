import React from 'react'

const TradingCard = (props) => {
    return (
        <>
            <div className="tranding-card">
                <div className="photo">
                    <img src={props.img} alt="WeddingRing" className='img-fluid' />
                </div>
                <div className="content">
                    <h5>{props.heading}</h5>
                    <p className='section-detail'>{props.detail}</p>
                    <span>{props.price}</span>
                </div>
            </div>
        </>
    )
}

export default TradingCard