import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import config from "../../../Config";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import Config from "../../../Config";
import { Cropper } from "react-cropper";
import { ArrowUpTrayIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";

function AddProductImages(props) {

    let params = useParams();


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [Loading, setLoading] = useState('')
    const [SortOrder, setSortOrder] = useState('')


    // form
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    // categories
    const [GetProductImages, setGetProductImages] = useState([]);
    const [LoadingProductImages, setLoadingProductImages] = useState(false);
    const [DeleteImage, setDeleteImage] = useState(false);

    const [Product, setProduct] = useState({});


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Product/GetProductByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data,'GetProductByID');
                if (response.data.status_code == 1) {
                    setLoading(false);

                    setProduct(response.data.Product);

                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    }, [LoadingProductImages, DeleteImage]);

    useEffect(() => {

        setLoadingProductImages(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ProductID: params.id,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'ProductImage/GetProductImages',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {

                    setGetProductImages(response.data.listofRecords);
                    setLoadingProductImages(false);
                }
                else {
                    setLoadingProductImages(false);
                }
            })
            .catch(function (error) {

            });
    }, [DeleteImage]);



    //const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const MAX_IMAGE_SIZE = 4096 * 1024; // 2MB in bytes


    const [dragging, setDragging] = useState(false);
    const [dragFront, setdragFront] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };
    const handleDragfirst = (event) => {
        event.preventDefault();
        setdragFront(true);
    };

    const handleDropfirst = (event) => {

        event.preventDefault();
        setSmallImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {


            if (file.size > MAX_IMAGE_SIZE) {
                setSmallImageSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                setSmallImage(e.target.result);
                if (MediumImageCropper) {
                    MediumImageCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const handleDrop = (event) => {

        event.preventDefault();
        setMediumImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setMediumImageSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            // console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setMediumImage(e.target.result);
                if (MediumImageCropper) {
                    MediumImageCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };


    // SmallImage
    const [SmallImage, setSmallImage] = useState("");
    const [SmallImageSelected, setSmallImageSelected] = useState(false);
    const [SmallImageCropper, setSmallImageCropper] = useState();

    const DeleteSmallImage = () => {
        setSmallImage("");
        setSmallImageSelected(false);
        setSmallImageCropper();
    }
    // MediumImage
    const [MediumImage, setMediumImage] = useState("");
    const [MediumImageSelected, setMediumImageSelected] = useState(false);
    const [MediumImageCropper, setMediumImageCropper] = useState();

    const DeleteMediumImage = () => {
        setMediumImage("");
        setMediumImageSelected(false);
        setMediumImageCropper();
    }

    // LargeImage
    const [LargeImage, setLargeImage] = useState("");
    const [LargeImageSelected, setLargeImageSelected] = useState(false);
    const [LargeImageCropper, setLargeImageCropper] = useState();

    const DeleteLargeImage = () => {
        setLargeImageSelected(false);
        setLargeImageCropper();
        setLargeImage("")
    }



    const onChangeFront = (e) => {
        e.preventDefault();

        setSmallImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];



        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setSmallImageSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2), { position: toast.POSITION.TOP_RIGHT, });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {

            setSmallImage(reader.result);



        };
        reader.readAsDataURL(files[0]);
    }

    const onChangeBack = (e) => {
        e.preventDefault();
        setMediumImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setMediumImageSelected(false);
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }


        const reader = new FileReader();
        reader.onload = () => {
            setMediumImage(reader.result);


        };
        reader.readAsDataURL(files[0]);
    }


    const onChangeFronLicence = (e) => {
        e.preventDefault();

        setLargeImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setLargeImageSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2
            ), {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setLargeImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    }






    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setLoading(true);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Product_ID: params.id,
                SmallImage: SmallImage,
                MediumImage: MediumImage,
                LargeImage: LargeImage,
                Member_ID: UserID,
                SortOrder: SortOrder,
                Is_Default: event.target.checked,

            };
            console.log(data);

            if (SmallImage == "" || SmallImage == null) {
                toast.error('Please select Small Image', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            }
            else if (MediumImage == "" || MediumImage == null) {
                toast.error('Please select Medium Image', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            }
            else if (LargeImage == "" || LargeImage == null) {
                toast.error('Please select Large Image', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            }
            else if (!SmallImage.startsWith("data:image/png;base64")) {
                toast.error('Please select PNG Small Image', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            }
            else if (!MediumImage.startsWith("data:image/png;base64")) {
                toast.error('Please select PNG Medium Image', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            }
            else if (!LargeImage.startsWith("data:image/png;base64")) {
                toast.error('Please select PNG Large Image', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            }
            else {

                var api_config = {
                    method: "post",
                    url: Config.base_url + "ProductImage/AddProductImage",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                axios(api_config)
                    .then(function (response) {
                        // console.log('response', response.data);
                        if (response.data.status_code = 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            setLoading(false);

                            setSortOrder('');
                            DeleteLargeImage();
                            DeleteMediumImage();
                            DeleteSmallImage();

                        } else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }

                    })
                    .catch(function (error) {
                        setLoading(false);
                        toast.error("Network Error..", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }
        }
        setValidated(true);
    };

    const renderLedger = () => {
        if (GetProductImages != null && GetProductImages.length > 0) {
            return GetProductImages.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <img className="list-img" src={`${Config.path}Images/Products/${item.SmallImage}`} alt={item.SmallImage} />
                        </td>
                        <td>{item.MediumImage}</td>
                        <td>{item.LargeImage}</td>
                        <td>{item.SortOrder}</td>
                        <td>
                            <input class="form-check-input" defaultChecked={(item.Is_Default)} type="checkbox" disabled />
                        </td>
                        <td>
                            <ul
                                className="inline-action"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li><Link className="red" onClick={(e) => deleteGroup(item.ID)} type="button"><i className="ri-delete-bin-line"></i></Link></li>
                            </ul>
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="8">
                        <h4
                            className=" mb-0"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    const deleteGroup = (ID) => {
        if (window.confirm("Are you sure to delete this images ?")) {
            setDeleteImage(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: ID,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "ProductImage/DeleteProductImage",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setDeleteImage(false);
                    }
                    else if (response.data.status_code == 0) {

                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    };


    return (
        <>

            <ToastContainer />

            <div className="admin-content kyc-section">
                <div className="row gy-3 align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Add Images
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/product/manage-product">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <NavLink to={`/admin/product/manage-product`} className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                Manage Product
                                <i className="ri-add-line ms-3"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-3 mt-4">
                    <div className="card-inner form_default">
                        <div className="row p-3 gy-3 align-items-end">
                            <div className="col-md-12">

                                <ul className="kyc-upload d-flex m-0 mt-3">
                                    <li>
                                        <div className={`kyc-img-card white`}>
                                            <div className="card-inner">
                                                <div className={`upload-box`}
                                                    id="drop-area"
                                                    onDragOver={handleDragfirst}
                                                    onDrop={handleDropfirst}
                                                >

                                                    <input id="ChoosePhotoFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFront} />

                                                    {
                                                        SmallImageSelected ?
                                                            <>
                                                                <Cropper
                                                                    style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                    zoomTo={0}
                                                                    preview=".img-preview"
                                                                    src={SmallImage}
                                                                    dragMode="move"
                                                                    viewMode={2}
                                                                    minCropBoxHeight={10}
                                                                    minCropBoxWidth={10}
                                                                    background={false}
                                                                    responsive={true}
                                                                    autoCropArea={1}
                                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                    onInitialized={(instance) => {
                                                                        setSmallImageCropper(instance);
                                                                    }}
                                                                    guides={true}
                                                                />
                                                                <div className="delete" onClick={DeleteSmallImage}>
                                                                    <TrashIcon />
                                                                </div>
                                                            </>
                                                            :
                                                            <label htmlFor="ChoosePhotoFront" className='thumnail'>
                                                                <ArrowUpTrayIcon />
                                                                <p>Upload Small Image</p>
                                                            </label>

                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                    <li>
                                        <div className={`kyc-img-card white`}>
                                            <div className="card-inner">
                                                <div className={`upload-box`}
                                                    id="drop-area"
                                                    onDragOver={handleDragOver}
                                                    onDrop={handleDrop}
                                                >

                                                    <input id="ChoosePhotoBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBack} />

                                                    {
                                                        MediumImageSelected ?
                                                            <>
                                                                <Cropper
                                                                    style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                    zoomTo={0}
                                                                    preview=".img-preview"
                                                                    src={MediumImage}
                                                                    dragMode="move"
                                                                    viewMode={2}
                                                                    minCropBoxHeight={10}
                                                                    minCropBoxWidth={10}
                                                                    background={false}
                                                                    responsive={true}
                                                                    autoCropArea={1}
                                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                    onInitialized={(instance) => {
                                                                        setMediumImageCropper(instance);
                                                                    }}
                                                                    guides={true}
                                                                />
                                                                <div className="delete" onClick={DeleteMediumImage}>
                                                                    <TrashIcon />
                                                                </div>
                                                            </>
                                                            :
                                                            <label htmlFor="ChoosePhotoBack" className='thumnail'>
                                                                <ArrowUpTrayIcon />
                                                                <p>Upload Medium Image</p>
                                                            </label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={`kyc-img-card blue mb-3`}>
                                            <div className="card-inner">
                                                <div className={`upload-box ${dragFront ? 'dragFront' : ''}`}
                                                    id="drop-area"
                                                    onDragOver={handleDragfirst}
                                                    onDrop={handleDropfirst}
                                                >

                                                    <input id="ChooseLicenceFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFronLicence} />


                                                    {
                                                        LargeImageSelected ?
                                                            <>
                                                                <Cropper
                                                                    style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                    zoomTo={0}
                                                                    preview=".img-preview"
                                                                    src={LargeImage}
                                                                    dragMode="move"
                                                                    viewMode={2}
                                                                    minCropBoxHeight={10}
                                                                    minCropBoxWidth={10}
                                                                    background={false}
                                                                    responsive={true}
                                                                    autoCropArea={1}
                                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                    onInitialized={(instance) => {
                                                                        setLargeImageCropper(instance);
                                                                    }}
                                                                    guides={true}
                                                                />
                                                                <div className="delete" onClick={DeleteLargeImage}>
                                                                    <TrashIcon />
                                                                </div>
                                                            </>
                                                            :
                                                            <label htmlFor="ChooseLicenceFront" className='thumnail'>
                                                                <ArrowUpTrayIcon />
                                                                <p>Upload Large Image</p>
                                                            </label>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Sort Order</label>
                                    <div className="inputwrap">
                                        <input minLength={0} type="number" onChange={(e) => setSortOrder(e.target.value)} className="form-control" value={SortOrder} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="IsNew" id="IsNew" />
                                        <label class="form-check-label" for="IsNew">
                                            Is Default
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3">
                                <button className="button button-primary input-height input-radius" disabled={Loading} onClick={handleSubmit}>Upload Image{Loading ? '...' : ''}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gy-3">
                    <div className="col-md-4">
                        <h5 className="text-primary my-3">PRODUCT DETAIL</h5>
                        <div className="card card-table mb-5 mt-3">
                            <div className="card-inner responsive-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Loading ? (
                                            <tr>
                                                <td className="text-center" colSpan="10">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                <tr>
                                                    <td>{Product.Code}</td>
                                                    <td>{Product.Name}</td>
                                                    <td>{Product.CategoryName}</td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-8">
                        <h5 className="text-primary my-3">Uploaded Images</h5>
                        <div className="card card-table mb-5 mt-3">
                            <div className="card-inner responsive-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Small Image</th>
                                            <th>Mediam Image</th>
                                            <th>Large Image</th>
                                            <th>Sort Order</th>
                                            <th>Default</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {LoadingProductImages ? (
                                            <tr>
                                                <td className="text-center" colSpan="6">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>{renderLedger()}</>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddProductImages;