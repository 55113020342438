import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/logo/logo.svg";
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, UserIcon } from '@heroicons/react/24/outline'
import { Dropdown, Offcanvas } from "react-bootstrap";

function Header(props) {

    const UserID = localStorage.getItem("ID");
    const Full_Name = localStorage.getItem("Full_Name");

    const [isScrolled, setIsScrolled] = useState(false);

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [ListofNotifcations, setListofNotifcations] = useState([]);
    const [unseenCount, setunseenCount] = useState(0);
    const [loadingReadAll, setloadingReadAll] = useState(false);
    const [loadingDeleteAll, setloadingDeleteAll] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollThreshold = 150;

        const shouldAddClass = window.scrollY > scrollThreshold;

        setIsScrolled(shouldAddClass);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const renderLoginButtons = () => {
        if (UserID && Full_Name) {
            return (
                <NavLink to="/martinpay/dashboard">
                    <UserIcon />
                    <span className="lable">Dashboard</span>
                </NavLink>
            )
        }
        else {
            return (
                <NavLink to="/login">
                    <UserIcon />
                    <span className="lable">Login</span>
                </NavLink>
            )
        }
    }

    return (
        <>
            <Offcanvas className='cart-offcanvas' show={show} onHide={handleClose} placement={'end'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Cart (1)</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul>
                        <li>
                            <div class="cart-item">
                                <div className="top-content">
                                    <div class="product-thumbnail">
                                        <a href="#/">
                                            <img src="https://bijoux.vamtam.com/wp-content/uploads/2020/10/j3378ar043200.pw-1280-420x583.jpg"
                                                class="attachment img-fluid" alt="" />
                                        </a>
                                    </div>
                                    <div class="product-name">
                                        <a href="#/">Portlligat Golden Earrings</a>
                                    </div>
                                    <div class="product-remove">
                                        <a href="#/" >
                                            <i class="vamtam-remove-product">
                                                <svg class="vamtam-close vamtam-trash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" version="1.1">
                                                    <path xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z">
                                                    </path>
                                                </svg>
                                            </i>
                                        </a>
                                    </div>
                                </div>

                                <div className="bottom-content">
                                    <div class="product-price">
                                        <span class="quantity">
                                            <div class="quantity-select">
                                                <select>
                                                    <option value="1" selected>1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            </div>
                                            <span class="amount">
                                                <bdi>
                                                    <span class="woocommerce-Price-currencySymbol">$</span>119.00
                                                </bdi>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
            <nav className="top-bar">
                <div className="row">
                    <div className="col-md-6">
                        <p className="welcome-text">
                            Free Shipping On All Domestic Orders
                        </p>
                    </div>
                    <div className="col-md-6">
                        <ul className="">
                            <li>
                                Mon - Sat 9.00 - 18.00
                            </li>

                            <li>
                                Mon - Sat 9.00 - 18.00
                            </li>
                        </ul>
                    </div>
                </div >
            </nav >
            <header className={`navbar-website ${isScrolled ? 'sticky' : ''}`}>
                <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="material-symbols-outlined text-white open">menu</span>
                        <span className="material-symbols-outlined text-white close">close</span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/products">Products</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/categories">Categories</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Blog</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                    <ul className="help-list">
                        <li>
                            {renderLoginButtons()}
                        </li>
                        <li>
                            <a onClick={handleShow} type="button" className='cart'>
                                <span className={`cart-count ${unseenCount === 0 ? 'active' : 'active'}`}>{unseenCount === 0 ? '10' : '0'}</span>
                                <ShoppingBagIcon />
                                <span className="lable">Cart</span>
                            </a>
                        </li>
                    </ul>
                </nav >
            </header >

        </>
    );
}

export default Header;