import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useParams } from 'react-router-dom';
import Config from "../../../Config";


function ProductAttributes(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    

    const [Attributes, setAttributes] = useState([]);
    const [Product, setProduct] = useState({});


    let params = useParams();



    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingSave, setloadingSave] = useState(false);
    

    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ProductID: params.id,
        };
        // console.log("id",params.id)
        var api_config = {
            method: 'post',
            url: Config.base_url + 'Attribute/GetAllAttributeWithValues',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                //console.log(response.data)
                if (response.data.status_code == 1) {
                    setAttributes(response.data.listofRecords);
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    }, [loadingSave]);


    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Product/GetProductByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {

                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setProduct(response.data.Product);
                    setLoading(false);
                }
                else {

                    setLoading(false);
                }
            })
            .catch(function (error) {

            });


    }, []);


    const onFinish = () => {

        setloadingSave(true);

        const selectedItems = [];
        const inputs = document.getElementsByClassName('ant-checkbox-input');

        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                const ProductID = params.id;
                const AttributeID = inputs[i].dataset.parentId;
                const ValueID = inputs[i].value;
                selectedItems.push({ ProductID, AttributeID, ValueID });
            }
        }

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ListofSelectedValues: selectedItems
        };

     
        var api_config = {
            method: 'post',
            url: Config.base_url + 'Product/AddProductValues',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                   
                    setloadingSave(false);
                    
                }
                else {
                  
                    setloadingSave(false);
                }
            })
            .catch(function (error) {
            });
    };



    return (
        <>
            
          
            <div gutter={[24, 4]} justify={'center'} align={'middle'} wrap className="page-lable">
                <div xs={24} md={12}>
                    <h1 className="admin-heading">{Product.Name}</h1>
                </div>
                <div xs={24} md={12}>
                    <NavLink to={'/admin/product/manage-product'} className="align-button">
                        <button type="dashed">Manage Product</button>
                    </NavLink>
                </div>
            </div>
            <div>


                <div>
                    <form
                    >
                        <div>
                            {
                                Attributes.map((item, index) => (
                                    <>
                                        <div xs={24} md={24} key={index}>
                                            <h4 className="m-0">{item.AttributeName}</h4>
                                            <div>
                                                {item.ListofAttributeValues != null && item.ListofAttributeValues.length > 0 ? (
                                                    <div gutter={[24, 10]}>
                                                        {item.ListofAttributeValues.map((innerItem, innerIndex) => (
                                                            <div xs={12} md={4} key={innerIndex}>
                                                                <input type="checkbox" />
                                                                {/* onChange={(e) => onChange(e, item.ID)} */}
                                                                {/* <Checkbox
                                                                    defaultChecked={innerItem.IsSelected}
                                                                    data-parent-id={item.ID}
                                                                    value={innerItem.ID}>
                                                                    {innerItem.Value}
                                                                </Checkbox> */}

                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <p className="m-0">Empty</p>
                                                )}
                                            </div>

                                        </div>
                                    </>
                                ))}
                            <div xs={24} md={24} justify={'end'} align={'end'}>

                                <button type="primary" onClick={onFinish} loading={loadingSave}>
                                    Add Attributes
                                </button>


                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </>
    );
}

export default ProductAttributes;