import { ArrowLongRightIcon, ArrowPathRoundedSquareIcon, EyeIcon, HeartIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'

const ProductsSaleCard = (props) => {
    return (
        <>
            <Link to={props.link} className="product-sale-card">
                <div className="photo">
                    <ul className='sale-list'>
                        <li>{props.sale}</li>
                        <li>{props.discount}</li>
                    </ul>
                    <ul className="feedback-list">
                        <li>
                            <HeartIcon />
                        </li>
                        <li>
                            <EyeIcon />
                        </li>
                        <li>
                            <ArrowPathRoundedSquareIcon />
                        </li>
                    </ul>
                    <img src={props.img} alt="thumbnail" className='img-fluid' />
                </div>
                <div className="content">
                    <div className="info">
                        <h5>{props.heading}</h5>
                        <p className='section-detail'>{props.detail} <span>{props.cut}</span></p>
                    </div>
                    <div className="arrow">
                        <ArrowLongRightIcon />
                    </div>
                </div>
            </Link>
        </>
    )
}

export default ProductsSaleCard