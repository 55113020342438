import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import config from "../../../Config";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

function AddProduct(props) {

    let params = useParams();


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [Loading, setLoading] = useState('')


    // form
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    // categories
    const [Category, setCategory] = useState([]);
    const [SubCategory, setSubCategory] = useState([]);
    const [ParentCategoryID, setParentCategoryID] = useState(0);
    const [SubCategoryID, setSubCategoryID] = useState(0);
    const [CurrentStatus, setCurrentStatus] = useState(0);


    const [Product, setProduct] = useState({});
    const [IsUpdated, setIsUpdated] = useState(false);

    // loadings
    const [loadingSubCategory, setloadingSubCategory] = useState(false);

    useEffect(() => {

        setLoading(true);
        setIsUpdated(false);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Product/GetProductByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data)
                if (response.data.status_code == 1) {
                    setLoading(false);
                    setParentCategoryID(response.data.Product.CategoryID);
                    setSubCategoryID(response.data.Product.SubCategoryID);
                    setCurrentStatus(response.data.Product.CurrentStatus);
                    setProduct(response.data.Product);

                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    }, []);

    useEffect(() => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'ProductCategory/GetMainCategories',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {

                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setCategory(response.data.listofRecords)
                }
            })
            .catch(function (error) {

            });
    }, []);




    useEffect(() => {

        // console.log(ParentCategoryID);
        if (ParentCategoryID > 0) {
            setloadingSubCategory(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ParentCategoryID: ParentCategoryID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'ProductCategory/GetCategories',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        setSubCategory(response.data.listofRecords);
                        setloadingSubCategory(false);
                    }
                    else {
                        setloadingSubCategory(false);
                    }
                })
                .catch(function (error) {

                });
        }
    }, [ParentCategoryID]);





    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: params.id,
                Details: Detail,
                Code: event.target.Code.value,
                Name: event.target.Name.value,
                CategoryID: event.target.CategoryID.value,
                SubCategoryID: event.target.SubCategoryID.value,
                ShortDescription: event.target.ShortDescription.value,
                BuyingPrice: event.target.BuyingPrice.value,
                SellingPrice: event.target.SellingPrice.value,
                IsNew: event.target.checked,
                StockStatus: event.target.checked ? "In Stock" : "Out of Stock",
                SEOTitle: event.target.SEOTitle.value,
                SEOKeywords: event.target.SEOKeywords.value,
                SEODescription: event.target.SEODescription.value,
                CurrentStatus: event.target.CurrentStatus.value,
                Tagline: event.target.Tagline.value,

            };

            console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Product/UpdateProduct',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {

                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoading(false);
                        navigate('/admin/product/manage-product');

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoading(false);
                    }
                })
                .catch(function (error) {
                });
        }

        setValidated(true);
    };



    const DetailRef = useRef(null);
    const [Detail, setDetail] = useState("");
    const handleDetailChange = (content) => {
        setDetail(content);
    };

    return (
        <>

            <ToastContainer />

            <div className="admin-content kyc-section">
                <div className="row gy-3 align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Update Product
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/product/manage-product">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <NavLink to={`/admin/product/manage-product`} className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                Manage Product
                                <i className="ri-add-line ms-3"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-3 mt-4">
                    <div className="card-inner">
                        {
                            Loading ?
                            <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                          :

                        
                        <div className="form_default">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                <div className="row p-3 gy-3">
                                    <div className="col-md-12">
                                        <div bordered={false}>
                                            <h4 className="text-primary">PRODUCT</h4>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Code</label>
                                                        <input type="text" name="Code" defaultValue={Product.Code} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="form-group mb-3">
                                                        <label>Product Title</label>
                                                        <input type="text" name="Name" defaultValue={Product.Name} className="form-control" required />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">

                                                <div className="col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label>Main Category</label>
                                                        <select
                                                            className="form-select"
                                                            name="CategoryID"
                                                            value={ParentCategoryID}
                                                            onChange={(e) => setParentCategoryID(e.target.value)}>
                                                            <option value="">Select Category</option>
                                                            {
                                                                Category.length > 0 ?
                                                                    Category.map((item, index) => (
                                                                        <option value={item.ID} key={index}>{item.CategoryName}</option>
                                                                    ))
                                                                    :
                                                                    ""
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                {console.log('SubCategoryID',SubCategoryID)}
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label>Sub Category</label>
                                                        <select 
                                                            className="form-select" 
                                                            name="SubCategoryID" 
                                                            value={SubCategoryID}
                                                            onChange={(e) => setSubCategoryID(e.target.value)}
                                                            >
                                                            <option value="">Select Sub Category</option>
                                                            {
                                                                SubCategory.length > 0 ?
                                                                    SubCategory.map((item, index) => (
                                                                        <option value={item.ID} key={index}>{item.CategoryName}</option>
                                                                    ))
                                                                    :
                                                                    ""
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div xs={24} md={24}>
                                                    <div className="form-group mb-3">
                                                        <label>Short Description</label>
                                                        <textarea className="form-control" name="ShortDescription" defaultValue={Product.ShortDescription}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group mb-4">
                                                        <label>Current Status</label>
                                                        <select 
                                                        name="CurrentStatus" 
                                                        className="form-select" 
                                                        value={CurrentStatus}
                                                        onChange={(e) => setCurrentStatus(e.target.value)}
                                                        >
                                                            <option value="">Select Status</option>
                                                            <option value="Sale">Sale</option>
                                                            <option value="Discounted Price">Discounted Price</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-4">
                                                        <label>Tagline</label>
                                                        <input type="text" name="Tagline" className="form-control" defaultValue={Product.Tagline} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="text-primary">PRICING</h4>
                                            <div className="row align-items-end">
                                                <div className="col-md-2" xs={24} md={5}>
                                                    <div className="form-group mb-3">
                                                        <label>Buying Price</label>
                                                        <input type="text" className="form-control" name="BuyingPrice" defaultValue={Product.BuyingPrice} />
                                                    </div>
                                                </div>
                                                <div className="col-md-2" xs={24} md={5}>
                                                    <div className="form-group mb-3">
                                                        <label>Selling Price</label>
                                                        <input type="text" className="form-control" name="SellingPrice" defaultValue={Product.SellingPrice} />
                                                    </div>
                                                </div>
                                                <div className="col-md-2" xs={24} md={5}>
                                                    <div className="form-group mb-3">
                                                        <label>Discount</label>
                                                        <input type="text" className="form-control" name="Discount" defaultValue={Product.Discount} />
                                                    </div>
                                                </div>
                                                <div className="col-md-2" xs={24} md={4}>
                                                    <div className="form-group mb-3">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="IsNew" id="IsNew" defaultChecked={Product.IsNew} />
                                                            <label class="form-check-label" for="IsNew">
                                                                Is New
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-2" xs={24} md={4}>
                                                    <div className="form-group mb-3">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="StockStatus" id="StockStatus" defaultChecked={Product.StockStatus} required />
                                                            <label class="form-check-label" for="StockStatus">
                                                                Available in Stock
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div xs={24} md={6}>
                                        <div bordered={false}>
                                            <h4 className="text-primary">SEO</h4>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        <label>SEO Title</label>
                                                        <input type="text" className="form-control" name="SEOTitle" defaultValue={Product.SEOTitle} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        <label>SEO Keywords</label>
                                                        <input type="text" className="form-control" name="SEOKeywords" defaultValue={Product.SEOKeywords} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        <label>SEO Description</label>
                                                        <textarea className="form-control" name="SEODescription" defaultValue={Product.SEODescription}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div xs={24} md={24}>
                                        <Editor
                                            apiKey="w81u1otpwt8qqd0odorwvyaol3vsrjipuhdua1mr6kjqnebw"
                                            init={{
                                                plugins:
                                                    "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                                toolbar:
                                                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                            }}
                                            // initialValue="Welcome to TinyMCE!"
                                            onInit={(editor) => DetailRef.current = editor}
                                            onEditorChange={handleDetailChange}
                                            initialValue={Product.Details}
                                        />
                                    </div>
                                    <div xs={24} md={24} align={'end'}>
                                        <div className="form-group mb-3">
                                            <button type="submit" disabled={Loading} className="button button-primary">
                                                Save Product {Loading ? '...' : ''}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddProduct;