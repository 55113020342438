import React from 'react';
import { NavLink } from 'react-router-dom';

function ActivationMenu(props) {
    return (
        <>
            <ul className="menu-option">
                <li>
                    <NavLink className="nav-link"  to="/martinpay/transactions/activation-history/history/Recharge" >Recharge</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/martinpay/transactions/activation-history/history/sent">Purchased for</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/martinpay/transactions/activation-history/history/received" >Purchased by</NavLink>
                </li>
            </ul>
        </>
    );
}

export default ActivationMenu;