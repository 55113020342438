import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import AdminRightsCheck from "../AdminRightsCheck";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { Spinner } from "react-bootstrap";

const Update_Profile = (props) => {



  //const ID = props.ID;

  // console.log('ID' + props.ID);
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [ID, setID] = useState(props.ID);

  const [showFilters, setShowFilters] = useState(true);


  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ProfileLoading, setProfileLoading] = useState(false);
  const [Member, setMember] = useState('');
  const [Referrer, setReferrer] = useState('');
  const [IsLeader, setIsLeader] = useState('');

  const [EmailVerified, setEmailVerified] = useState('');
  const [RestrictROItransfer, setRestrictROItransfer] = useState('');
  const [RestrictUplineProfit, setRestrictUplineProfit] = useState('');
  const [RestrictWithdrawl, setRestrictWithdrawl] = useState('');
  const [PackageRewardGiven, setPackageRewardGiven] = useState('');
  const [HideInUpLine, setHideInUpLine] = useState('');
  const [Bloack, setBloack] = useState('');
  const [FreeMember, setFreeMember] = useState('');
  const [Designation, setDesignation] = useState('');



  let navigate = useNavigate();


  useEffect(() => {
    document.title = "Martinpay Admin";

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: props.ID
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMemberByMemberID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setMember(response.data.Member);
          setLoading(false);
          setReferrer(response.data)
          setEmailVerified(response.data.Member.Email_Address_Verified);
          setRestrictUplineProfit(response.data.Member.RestrictUplineProfit);
          setHideInUpLine(response.data.Member.HideInUpLine);
          setIsLeader(response.data.Member.IsLeader);
          setPackageRewardGiven(response.data.Member.PackageRewardGiven);
          setRestrictWithdrawl(response.data.Member.RestrictWithdrawl);
          setRestrictROItransfer(response.data.Member.RestrictROItransfer);
          setBloack(response.data.Member.Is_Blocked);
          setFreeMember(response.data.Member.FreeMember);
          setDesignation(response.data.Member.Postal_Address);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          // navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [ID]);

  const [ProfileValidated, setProfileValidated] = useState(false);

  const UpdateProfile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setProfileLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: ID,
        Full_Name: event.target.Full_Name.value,
        Email_Address: event.target.Email_Address.value,
        MobileNo: event.target.Mobile_No.value,
        CNIC_No: "",
        RankName: event.target.RankName.value,
        Email_Address_Verified: EmailVerified,
        RankRewardGiven: "true",
        CommissionRate: event.target.Commission.value,
        RestrictUplineProfit: RestrictUplineProfit,
        RestrictWithdrawl: RestrictWithdrawl,
        HideInUpLine: HideInUpLine,
        RestrictROItransfer: RestrictROItransfer,
        Is_Blocked: Bloack,
        FreeMember: FreeMember,
        IsLeader: IsLeader,
        Postal_Address: Designation,
      };

      // console.log("data", data);

      var api_config = {
        method: "post",
        url: config.base_url + "Users/UpdateProfile",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.status_code == 1) {
            setProfileLoading(false);
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.location.reload();
            // props.handleCloseModal(false);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
    setProfileValidated(true);
  };

  return (
    <>
      <ToastContainer />
      <div className="admin-content">
        <Form
          noValidate
          validated={ProfileValidated}
          onSubmit={UpdateProfile}
        >
          <div className="card card-table admin-profile-input">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-3 gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Member Status</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.MemberStatus}
                          name="MemberStatus"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Member Rank</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.RankName}
                          name="RankName"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Name</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          defaultValue={Member.Full_Name}
                          name="Full_Name"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Username</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          readOnly
                          disabled
                          defaultValue={Member.User_Name}
                          name="User_Name"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Email</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.Email_Address}
                          name="Email_Address"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Cell</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          defaultValue={Member.Mobile_No}
                          name="Mobile_No"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Smart Pass</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          readOnly
                          disabled
                          defaultValue={Member.PINCode}
                          name="PINCode"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Password</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          readOnly
                          disabled
                          defaultValue={Member.Password}
                          name="Password"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Gender</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.Gender}
                          name="Gender"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Country</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.Country}
                          name="Country"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">State</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.State}
                          name="State"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Authentication code</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Referrer.OTP}
                          name="City"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-none">
                    <div className="form-group">
                      <label htmlFor="">Address</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.Postal_Address}
                          name="Postal_Address"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card card-table admin-profile-input mt-3">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-3 gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Sponsor Username</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Referrer.ReferrerUserName}
                          name="ReferrerUserName"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Sponsor Email</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Referrer.ReferrerEmail}
                          name="ReferrerEmail"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Sponsor Cell</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Referrer.ReferrerMobile}
                          name="ReferrerMobile"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card card-table admin-profile-input mt-3">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-3 gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Key Leader?</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          defaultValue={Member.IsLeader}
                          value={IsLeader ? true : false}
                          onChange={(e) =>
                            setIsLeader(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Designation Name</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={Designation}
                          onChange={(e) =>
                            setDesignation(e.target.value)
                          }
                        >
                          <option value="" disabled>Please Select</option>
                          <option value="Regional Sales Manager">Regional Sales Manager</option>
                          <option value="Sales Director">Sales Director</option>
                          <option value="Managing Director">Managing Director</option>
                          <option value="Country Head">Country Head</option>
                        </select>
                        {/* <input
                          type="text"
                          defaultValue={Member.Postal_Address}
                          name="postaladdress"
                          className="form-control"
                        /> */}
                      </div>

                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Leadership commission (%)</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          defaultValue={Member.Commission}
                          name="Commission"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Business Target</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.Target}
                          name="Target"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Achieved Target</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.TargetAmount}
                          name="TargetAmount"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Remaining Target</label>
                      <div className="inputwrap">
                        <input
                          type="text"
                          disabled
                          readOnly
                          defaultValue={Member.Target_Balance}
                          name="Target_Balance"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Start date</label>
                      <div className="inputwrap">
                        <input
                          disabled
                          type="date"
                          className="form-control"
                          readOnly
                          defaultValue={moment(Member.Created_Date).format(
                            "YYYY-MM-DD"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Leadership Commission Paid</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          disabled
                          value={PackageRewardGiven ? true : false}
                          onChange={(e) =>
                            setPackageRewardGiven(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="dropdown">Email Verified</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={EmailVerified}
                          onChange={(e) =>
                            setEmailVerified(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Restrict Upline Profit</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          disabled
                          value={RestrictUplineProfit ? true : false}
                          onChange={(e) =>
                            setRestrictUplineProfit(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Restrict Withdrawal</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={RestrictWithdrawl ? true : false}
                          onChange={(e) =>
                            setRestrictWithdrawl(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Restrict ROI Transfer</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={RestrictROItransfer ? true : false}
                          onChange={(e) =>
                            setRestrictROItransfer(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Hide In UpLine</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={HideInUpLine ? true : false}
                          onChange={(e) =>
                            setHideInUpLine(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Block</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={Bloack ? true : false}
                          onChange={(e) =>
                            setBloack(e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Free Member Status</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={FreeMember}
                          onChange={(e) => setFreeMember(e.target.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3  text-end">
                    <button
                      type="submit"
                      disabled={ProfileLoading}
                      className="button button-primary input-height input-radius"
                    >
                      Update{ProfileLoading ? "..." : ""}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Update_Profile