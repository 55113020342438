import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";

function TradingProfilt(props) {
    // const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    // const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    // const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [showFilters, setShowFilters] = useState(true);

    // useEffect(() => {

    //     document.title = "Martinpay Admin";

    //     setIsUpdated(false);
    //     setLoading(true);

    //     const data = {
    //         AccessKey: AccessKey,
    //         UserID: UserID,
    //         pageNo: pageNo,
    //         pageSize: pageSize,
    //     };

    //     console.log(data);

    //     var api_config = {
    //         method: 'post',
    //         url: config.base_url + 'Users/GetKYCList',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };

    //     axios(api_config)
    //         .then(function (response) {
    //             if (response.data.status_code === 1) {
    //             console.log("data", response.data);
    //             setLedger(response.data.ListofLedger);
    //             setLoading(false);
    //             setTotalRecords(response.data.totalRecords);
    //             console.log(totalRecords);

    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });

    // }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        // if (listOfLedger != null && listOfLedger.length > 0) {
        let count = 1;
        return listOfLedger.map((item, index) => {
            // console.log(item);
            return (
                <tr key={index}>
                    <td>{count++ + pageSize * (pageNo - 1)}</td>
                </tr>
            );
        });
        // }
        // else {
        // return (
        //     <tr>
        //         <td colSpan="9"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
        //     </tr>
        // )
        // }
    };
    return (
        <>
            <AdminRightsCheck />
                      <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Trading Return
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/commodity-panel-dashboard">
                                 <button type="button" className="button button-outline-bs">
                Back
              </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Filters Menu */}
                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row gy-3 p-3">

                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group">
                                                <label htmlFor="">Search by Category</label>
                                                <div className="inputwrap">
                                                    <select
                                                        id="dropdown"
                                                        className="form-select"
                                                    // value={packageName}
                                                    // onChange={(e) => setPackageName(e.target.value)}
                                                    >
                                                        <option value="" selected>All</option>
                                                        <option value="Paid">Paid Return</option>
                                                        <option value="Pending">Pending Return</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default me-5 position-relative">
                                            <div className="form-group search_box">
                                                <label htmlFor="">Search Member</label>
                                                <div className="inputwrap">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Find by Username/Email/Member ID"

                                                    // onChange={SearchMembers}
                                                    />
                                                    <span className="material-symbols-outlined">search</span>
                                                </div>
                                            </div>
                                            {
                                                // SearchdMembers.length > 0 &&
                                                <ul className="tree_search_wrap shadow-sm" id="searched-members">
                                                    {/* {renderSearchedMembers()} */}
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-5 mt-3">
                <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Membership Date</th>
                                    <th>Username</th>
                                    <th>Membership Id </th>
                                    <th>Membership Value</th>
                                    <th>Trade in</th>
                                    <th>Return Date</th>
                                    <th>Percentage</th>
                                    <th>Released</th>
                                    <th>Total Received</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="9">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}</>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default TradingProfilt;