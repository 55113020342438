import React, { useState, useEffect } from "react";
import logo from '../../assets/images/logo/mag.svg';
import background from '../../assets/images/martinpay/login-bg.png';
import config from "../../Config";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/outline";

// import ImgEmailFailed from "../../assets/images/emailfailed.svg";


function EmailNotVerified(props) {


    const [UserName1, setUserName1] = useState(localStorage.getItem("User_Name"));
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);




    const ResendEmail = () => {
        const data = {

            UserName: UserName1,

        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/SendSignUpEmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data);
        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    setIsButtonDisabled(true);

                    // Enable the button after 1 minute
                    setTimeout(() => {
                        setIsButtonDisabled(false);
                    }, 60000);
                }
                else {

                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }



    return (
        <>
            <ToastContainer />
            <section className='business-signin-form login-page'>
                <img src={background} />
                <div className='container'>
                    <div className="login_box">
                        <div className="login_box_head">
                            <img src={logo} />
                            <h4>Email Not Verified!</h4>
                        </div>
                        <div className="login_box_body">
                            <p>Your email was not verified. Please check your inbox for a verification email. <br /><br /> If you haven't received any,
                                click the button below to get a new verification link.</p>
                            <button type="submit" className="button button-bs w-100"
                                onClick={ResendEmail}
                                disabled={isButtonDisabled}
                            >
                                Resend Verify Link {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                            </button>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default EmailNotVerified;