import React, { useEffect, useState } from 'react';
import Pandants from '../../../assets/images/icons/Pendants.svg';
import Primary_Necklaces from '../../../assets/images/icons/Primary-Necklaces.svg';
import Necklaces from '../../../assets/images/icons/Necklaces.svg';
import Earings from '../../../assets/images/icons/Earings.svg';
import Rings from '../../../assets/images/icons/Ring.svg';
import facebook from '../../../assets/images/icons/facebook.svg';
import twitter from '../../../assets/images/icons/twitter.svg';
import instagram from '../../../assets/images/icons/instagram.svg';
import Primary_Rings from '../../../assets/images/icons/Primary-Ring.svg';
import thum from '../../../assets/images/icons/thum.svg';
import banner from '../../../assets/images/banner.jpg';
import Necklaces_user from '../../../assets/images/Necklaces_user.jpg';
import Rings_user from '../../../assets/images/Rings_user.jpg';
import Primary_Pendants from '../../../assets/images/icons/Primary-Pendants.svg';
import WeddingRing from '../../../assets/images/WeddingRing.jpg';
import GoldNeckless from '../../../assets/images/GoldNeckless.jpg';
import BarryGoldBangle from '../../../assets/images/BarryGoldBangle.jpg';
import GoldEarring from '../../../assets/images/GoldEarring.jpg';
import ArtificalEarings from '../../../assets/images/ArtificalEarings.png';
import BraceletCurb from '../../../assets/images/BraceletCurb.png';
import AzrouelVariable from '../../../assets/images/AzrouelVariable.png';
import AnkleBracelet from '../../../assets/images/AnkleBracelet.jpg';
import StudEarrings from '../../../assets/images/StudEarrings.jpg';
import CrumpledRing from '../../../assets/images/CrumpledRing.jpg';
import MoonNecklace from '../../../assets/images/MoonNecklace.jpg';

import { Link } from 'react-router-dom';
import TradingCard from '../website-component/TradingCard';
import ServiceCard from '../website-component/ServiceCard';
import ProductsSaleCard from '../website-component/ProductsSaleCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import SwiperCore from 'swiper/core';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import Config from '../../../Config';
import axios from 'axios';
SwiperCore.use([Navigation]);




const Landing = () => {

    const [Loading, setLoading] = useState(false);
    const [CategoriesData, setCategoriesData] = useState(false);
    
    useEffect(() => {

        setLoading(true);
        const data = {
            // AccessKey: AccessKey,
            // UserID: UserID,
            // ID: params.id,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: Config.base_url + 'ProductCategory/GetTopCategories',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data,'GetProductByID');
                if (response.data.status_code == 1) {
                    setLoading(false);

                    setCategoriesData(response.data.Product);

                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    }, []);


    return (
        <>
            <section className="hero-section bg-black">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className='tag-line'>JEWELRY DESIGN WITH LOVE</h3>
                            <h1 className='section-title mb-5'>High-End<br /> Jewelry Items</h1>
                            <div className="button-row">
                                <button type="button" className="button button-primary">Buy Now</button>
                                <button type="button" className="button button-outline-secondary">Explore Now</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={banner} className='img-fluid' alt='banner' />
                        </div>
                    </div>
                </div>
                <div className="social-media-list">
                    <li>
                        <a href="#/">
                            <img src={facebook} alt="facebook" />
                            <span>Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a href="#/">
                            <img src={instagram} alt="instagram" />
                            <span>Instagram</span>
                        </a>
                    </li>
                    <li>
                        <a href="#/">
                            <img src={twitter} alt="twitter" />
                            <span>Twitter</span>
                        </a>
                    </li>
                </div>
            </section>
            <section className="simple-section pt-md-0 categories-box bg-black">
                <div className="container">
                    <div className="card-row">
                        <div className="row gy-3">
                            <div className="col-md-3">
                                <div className="box">
                                    <div className="icon-box">
                                        <img src={Pandants} alt="Pandants" />
                                    </div>
                                    <div className="info">
                                        <h5>Golden Pandants</h5>
                                        <p>12 Products</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box">
                                    <div className="icon-box">
                                        <img src={Rings} alt="Rings" />
                                    </div>
                                    <div className="info">
                                        <h5>Diamond Rings</h5>
                                        <p>27 Products</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box">
                                    <div className="icon-box">
                                        <img src={Necklaces} alt="Necklaces" />
                                    </div>
                                    <div className="info">
                                        <h5>Gold Necklaces</h5>
                                        <p>18 Products</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="box">
                                    <div className="icon-box">
                                        <img src={Earings} alt="Earings" />
                                    </div>
                                    <div className="info">
                                        <h5>Designer Earings</h5>
                                        <p>23 Products</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="simple-section about-section bg-black">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <div className="icons-content text-center">
                                        <img src={Primary_Rings} alt="Primary_Rings" />
                                        <h4 className="mt-4">
                                            New Rings
                                        </h4>
                                        <p className='section-detail'>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-4">
                                    <img src={Rings_user} className='img-fluid' />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <img src={Necklaces_user} className='img-fluid' />
                                </div>
                                <div className="col-sm-6 mt-4">
                                    <div className="icons-content text-center">
                                        <img src={Primary_Necklaces} alt="Primary_Necklaces" />
                                        <h4 className="mt-4">
                                            Wedding<br /> Collection
                                        </h4>
                                        <p className='section-detail'>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ps-5 pt-5 mt-5">
                            <div className="col-info">
                                <span className="tag-line">about us</span>
                                <h2 className="section-title">Crafting<br /> Jewellery<br /> Since 1990.</h2>
                                <p className='section-detail'>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                                <button type="button" className="button button-primary">Buy Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='simple-section text-center'>
                <div className="container">
                    <div className="tag-line">
                        AVAIL OUR OFFER
                    </div>
                    <div className="section-title">
                        Trending Collection
                    </div>
                    <Link to='#/' className="button-link">
                        View more
                    </Link>
                    <div className="row gy-3">
                        <div className="col-md-3">
                            <TradingCard
                                img={WeddingRing}
                                heading={'Wedding Ring'}
                                detail={'Machine Design , 24 Carat'}
                                price={'$345/10gm'}
                            />
                        </div>
                        <div className="col-md-3">
                            <TradingCard
                                img={GoldNeckless}
                                heading={'Wedding Ring'}
                                detail={'Machine Design , 24 Carat'}
                                price={'$345/10gm'}
                            />
                        </div>
                        <div className="col-md-3">
                            <TradingCard
                                img={BarryGoldBangle}
                                heading={'Wedding Ring'}
                                detail={'Machine Design , 24 Carat'}
                                price={'$345/10gm'}
                            />
                        </div>
                        <div className="col-md-3">
                            <TradingCard
                                img={GoldEarring}
                                heading={'Wedding Ring'}
                                detail={'Machine Design , 24 Carat'}
                                price={'$345/10gm'}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className='simple-section text-center bg-black'>
                <div className="container">
                    <div className="tag-line">
                        FEATURED
                    </div>
                    <div className="section-title">
                        Our Products
                    </div>

                    <div className="row gy-3">
                        <div className="col-md-3">
                            <ProductsSaleCard
                                link={`/product-detail`}
                                img={AnkleBracelet}
                                heading={'Ankle Bracelet'}
                                sale={'Sale'}
                                detail={'$390'}
                                cut={'$59'}
                                discount={'-15%'}
                            />
                        </div>
                        <div className="col-md-3">
                            <ProductsSaleCard
                                link={`/product-detail`}
                                img={StudEarrings}
                                heading={'Stud Earrings'}
                                sale={'Sale'}
                                detail={'$390'}
                                cut={'$59'}
                                discount={'-15%'}
                            />
                        </div>
                        <div className="col-md-3">
                            <ProductsSaleCard
                                link={`/product-detail`}
                                img={CrumpledRing}
                                heading={'Crumpled Ring'}
                                sale={'Sale'}
                                detail={'$390'}
                                cut={'$59'}
                                discount={'-15%'}
                            />
                        </div>
                        <div className="col-md-3">
                            <ProductsSaleCard
                                link={`/product-detail`}
                                img={MoonNecklace}
                                heading={'Moon Necklace'}
                                sale={'Sale'}
                                detail={'$390'}
                                cut={'$59'}
                                discount={'-15%'}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className='simple-section text-center'>
                <div className="container">
                    <div className="tag-line">
                        NEW COLLECTION
                    </div>
                    <div className="section-title">
                        Handpicked Products
                    </div>
                    <div className="row mt-5 gy-3">
                        <div className="col-md-4">
                            <ServiceCard
                                img={ArtificalEarings}
                                subimg={Earings}
                                heading={'Artifical Earings'}
                                detail={<>
                                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet
                                </>}

                            />
                        </div>
                        <div className="col-md-4">
                            <ServiceCard
                                img={BraceletCurb}
                                subimg={Pandants}
                                heading={'Bracelet Curb'}
                                detail={<>
                                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet
                                </>}

                            />
                        </div>
                        <div className="col-md-4">
                            <ServiceCard
                                img={AzrouelVariable}
                                subimg={Necklaces}
                                heading={'Azrouel Variable'}
                                detail={<>
                                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet
                                </>}

                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className='simple-section buy-now'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="tag-line">
                                BUY NOW
                            </div>
                            <div className="section-title">
                                Rare Collections.
                            </div>
                            <div className="section-detail">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, aliquip ex ea commodo consequat you have to understand this.
                            </div>
                            <Link to='#/' className="button button-primary mt-3">
                                Shop Now
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='simple-section overflow-hidden'>
                <div className="row">
                    <div className="col-md-4 offset-md-2">
                        <div className="tag-line">
                            CATEGORIES
                        </div>
                        <div className="section-title">
                            Our Categories
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <ul className="categories-list">
                            <li>
                                <img src={Rings} alt="Rings" />
                                <span>Rings</span>
                            </li>
                            <li>
                                <img src={Earings} alt="Earings" />
                                <span>Earings</span>
                            </li>
                            <li>
                                <img src={Necklaces} alt="Necklaces" />
                                <span>Necklaces</span>
                            </li>
                            <li>
                                <img src={Pandants} alt="Pandants" />
                                <span>Pandants</span>
                            </li>
                            <li>
                                <img src={Rings} alt="Rings" />
                                <span>Rings</span>
                            </li>
                        </ul>
                    </div>
                </div>



            </section>
            <section className='simple-section overflow-hidden bg-black'>
                <div className="row">
                    <div className="col-md-3 offset-md-2">
                        <div className="tag-line">
                            BUY NOW
                        </div>
                        <div className="section-title">
                            Bestselling Products
                        </div>
                        <div className="section-detail">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, aliquip ex ea commodo consequat you have to understand this.
                        </div>
                        <div className="button button-primary mt-4">
                            Shop Now
                        </div>
                    </div>
                    <div className="col-md-7">
                        <Swiper
                            slidesPerView={4}
                            centeredSlides={true}
                            spaceBetween={30}
                            modules={[Pagination]}
                            loop={true}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={AnkleBracelet}
                                    heading={'Ankle Bracelet'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={StudEarrings}
                                    heading={'Stud Earrings'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={CrumpledRing}
                                    heading={'Crumpled Ring'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={MoonNecklace}
                                    heading={'Moon Necklace'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={AnkleBracelet}
                                    heading={'Ankle Bracelet'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={StudEarrings}
                                    heading={'Stud Earrings'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={CrumpledRing}
                                    heading={'Crumpled Ring'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProductsSaleCard
                                    img={MoonNecklace}
                                    heading={'Moon Necklace'}
                                    sale={'Sale'}
                                    detail={'$390'}
                                    cut={'$59'}
                                    discount={'-15%'}
                                />
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </section>
            <section className='simple-section'>
                <div className="container">
                    <div className="card-row">
                        <div className="row gy-3">
                            <div className="col-md-3">
                                <div className="rating-box">
                                    <div className="icon-box">
                                        <img src={Primary_Pendants} alt="Pandants" />
                                    </div>
                                    <div className="info">
                                        <h5>84k+</h5>
                                        <p>Jewelriess in Album</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="rating-box">
                                    <div className="icon-box">
                                        <img src={thum} alt="Rings" />
                                    </div>
                                    <div className="info">
                                        <h5>10M+</h5>
                                        <p>Happy Feedbacks</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="rating-box">
                                    <div className="icon-box">
                                        <img src={Primary_Rings} alt="Necklaces" />
                                    </div>
                                    <div className="info">
                                        <h5>2k+</h5>
                                        <p>Categories Served</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="rating-box">
                                    <div className="icon-box">
                                        <img src={Primary_Necklaces} alt="Earings" />
                                    </div>
                                    <div className="info">
                                        <h5>100M+</h5>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='simple-section products-section bg-black'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="tag-line">
                                Shop
                            </div>
                            <div className="section-title">
                                Latest Products
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-end gap-2">

                                <div className="custom-prev secondary-bg">
                                    <ArrowLeftIcon />
                                </div>
                                <div className="custom-next">
                                    <ArrowRightIcon />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Swiper
                                slidesPerView={4}
                                spaceBetween={30}
                                loop={true}
                                autoplay={true}
                                navigation={{
                                    nextEl: '.custom-next',
                                    prevEl: '.custom-prev',
                                }}
                                modules={[Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={AnkleBracelet}
                                        heading={'Ankle Bracelet'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={StudEarrings}
                                        heading={'Stud Earrings'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={CrumpledRing}
                                        heading={'Crumpled Ring'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={MoonNecklace}
                                        heading={'Moon Necklace'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={MoonNecklace}
                                        heading={'Moon Necklace'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={MoonNecklace}
                                        heading={'Moon Necklace'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={MoonNecklace}
                                        heading={'Moon Necklace'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProductsSaleCard
                                        img={MoonNecklace}
                                        heading={'Moon Necklace'}
                                        sale={'Sale'}
                                        detail={'$390'}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Landing