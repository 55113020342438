import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import LoginCheck from "../General/LoginCheck";

import Loader from "../General/Loader";
import OtpInput from "react-otp-input";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { ArrowUturnLeftIcon, BanknotesIcon, BuildingLibraryIcon, CurrencyEuroIcon, FaceFrownIcon, PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

function ManageWallets(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
    const [wallets, setWallets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [walletAdded, setWalletAdded] = useState(0);
    const [AddWalletType, setAddWalletType] = useState(null);
    const [validated, setValidated] = useState(false);
    const [OTP, setOTP] = useState('');
    const [Nick, setNick] = useState(null);
    const [BankName, setBankName] = useState(null);
    const [Currency, setCurrency] = useState(null);
    const [Network, setNetwork] = useState(null);
    const [Address, setAddress] = useState(null);
    const [AccountTitle, setAccountTitle] = useState(null);
    const [singleWallet, setSingleWallet] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [IsDefault, setIsDefault] = useState(false);
    const [ActiveClass, setActiveClass] = useState('Crypto');

    const [key, setKey] = useState('home');

    // canvas change AddWallet
    const [showCanvasAddWallet, setShowCanvasAddWallet] = useState(false);
    const handleCloseCanvasAddWallet = () => {
        setShowCanvasAddWallet(false);
        setWalletAdded(Math.random());
        setAddWalletType(null);
        setEditMode(false);
        setSingleWallet({});
        setValidated(false);
        setOTP('');
        setBankName('');
        setAddress('');
        setNick('');
        setNetwork('');

    }
    const handleShowCanvasAddWallet = (walletType) => {
        setAddWalletType(walletType);
        setShowCanvasAddWallet(true);

    }


    useEffect(() => {
        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberWallet/GetWallets',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setWallets(response.data.ListofWallets);
                    setLoading(false);
                }
                else if (response.data.status_message == "Invalid Access Key.") {
                    localStorage.clear();
                    window.location.href = "/login";
                }

            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT,
                    //onClose: () => window.alert('Called when I close')
                });
            });

    }, [walletAdded]);


    const MakeWalletDefault = (item, e) => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: item.ID,
            IsDefault: e.target.checked
        };

        // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberWallet/MakeWalletDefault',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    setWalletAdded(Math.random());

                }
                else if (response.data.status_message == "Invalid Access Key.") {
                    localStorage.clear();
                    window.location.href = "/login";
                }

            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT,
                    //onClose: () => window.alert('Called when I close')
                });
            });

        setLoading(false);
    }


    const renderCryptoWallets = () => {
        if (wallets != "") {
            let filtered = wallets.filter(item => item.WalletType == "Crypto");
            if (filtered != null && filtered.length > 0) {
                return (
                    filtered.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{moment(item.Created_Date).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{item.WalletName}</td>
                                <td>{item.Nick}</td>
                                <td>{item.Network}</td>
                                <td>{item.Address}</td>

                                <td className="text-center">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            disabled={item.IsDefault ? true : false}
                                            defaultChecked={item.IsDefault ? true : false}
                                            value={item.IsDefault ? true : false}
                                            id={item.WalletName}
                                            onChange={(e) => MakeWalletDefault(item, e)} />
                                    </div>
                                    {/* <div className="switch-box switch-box-bs is-primary pl-3">
                                        <input id={item.WalletName} className="switch-box-input"
                                            defaultChecked={item.IsDefault ? true : false}
                                            onChange={(e) => MakeWalletDefault(item, e)} type="checkbox" />
                                        <label For={item.WalletName} className="switch-box-slider"></label>
                                    </div> */}
                                </td>

                                <td>
                                    <TrashIcon className="action-icon me-3" onClick={() => deleteWallet(item.ID)} />
                                    <PencilSquareIcon className="action-icon" onClick={() => editWallet(item)} />
                                </td>
                            </tr>
                        )
                    })
                )
            }
            else {
                return (
                    <tr>
                        <td className="else" colSpan="10">
                            <FaceFrownIcon />
                            <h4>No Data Found</h4>
                        </td>
                    </tr>
                )
            }

        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="7">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }
    }

    const renderBankWallets = () => {
        if (wallets != "") {
            let filtered = wallets.filter(item => item.WalletType == "Bank Account");
            if (filtered != null && filtered.length > 0) {
                return (
                    filtered.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{moment(item.Created_Date).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{item.WalletName}</td>
                                <td>{item.Nick}</td>
                                <td>{item.AccountTitle}</td>
                                <td>{item.Address}</td>
                                <td>{item.AccountCurrency}</td>
                                <td>{item.BankName}</td>
                                <td className="text-center">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            disabled={item.IsDefault ? true : false}
                                            defaultChecked={item.IsDefault ? true : false}
                                            value={item.IsDefault ? true : false}
                                            id={item.WalletName}
                                            onChange={(e) => MakeWalletDefault(item, e)} />
                                    </div>
                                    {/* <div className="switch-box is-primary switch-box-bs pl-3">
                                        <input id={item.WalletName} className="switch-box-input"
                                            defaultChecked={item.IsDefault ? true : false}
                                            onChange={(e) => MakeWalletDefault(item, e)} type="checkbox" />
                                        <label For={item.WalletName} className="switch-box-slider"></label>
                                    </div> */}
                                </td>
                                <td>
                                    <TrashIcon className="action-icon me-3" onClick={() => deleteWallet(item.ID)} />
                                    <PencilSquareIcon className="action-icon" onClick={() => editWallet(item)} />
                                </td>
                            </tr>
                        )
                    })
                )
            }
            else {
                return (
                    <tr>
                        <td className="else" colSpan="9">
                            <FaceFrownIcon />
                            <h4>No Data Found</h4>
                        </td>
                    </tr>
                )
            }

        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="9">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }
    }
    const renderCashWallets = () => {
        if (wallets != "") {
            let filtered = wallets.filter(item => item.WalletType == "Cash");
            if (filtered != null && filtered.length > 0) {
                return (
                    filtered.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{moment(item.Created_Date).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{item.WalletName}</td>
                                <td>{item.Nick}</td>
                                <td>{item.Network}</td>
                                <td>{item.Address}</td>

                                <td className="text-center">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            disabled={item.IsDefault ? true : false}
                                            defaultChecked={item.IsDefault ? true : false}
                                            value={item.IsDefault ? true : false}
                                            id={item.WalletName}
                                            onChange={(e) => MakeWalletDefault(item, e)} />
                                        {/* <input className="form-check-input" type="checkbox"
                                            value={item.IsDefault ? true : false}
                                            id={item.WalletName}
                                            onChange={(e) => MakeWalletDefault(item, e)} /> */}
                                    </div>
                                </td>
                                <td>
                                    <TrashIcon className="action-icon me-3" onClick={() => deleteWallet(item.ID)} />
                                    <PencilSquareIcon className="action-icon" onClick={() => editWallet(item)} />
                                </td>
                            </tr>
                        )
                    })
                )
            }
            else {
                return (
                    <tr>
                        <td className="else" colSpan="7">
                            <FaceFrownIcon />
                            <h4>No Data Found</h4>
                        </td>
                    </tr>
                )
            }

        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="7">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }
    }


    const editWallet = (item) => {
        setSingleWallet(item);
        setEditMode(true);

        let type = "";



        setBankName(item.BankName);
        setCurrency(item.AccountCurrency);
        setAccountTitle(item.AccountTitle);
        setAddress(item.Address);
        setNick(item.Nick);


        if (item.WalletType == "Crypto") {
            type = "crypto";
        }
        else if (item.WalletType == "Bank Account") {
            type = "bank";
        }
        else if (item.WalletType == "Cash") {
            type = "Cash";
        }

        handleShowCanvasAddWallet(type);
    }


    const deleteWallet = (ID) => {


        if (window.confirm("Are you sure you want to delete this item?")) {
            setLoading(true);
            const dashboardParms = {
                AccessKey: AccessKey,
                UserID: UserID,
                RequestID: ID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'MemberWallet/DeleteWallet',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: dashboardParms
            };

            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {
                        setWalletAdded(Math.random());
                        setLoading(false);
                    }
                    else if (response.data.status_message == "Invalid Access Key.") {
                        localStorage.clear();
                        window.location.href = "/login";
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setLoading(false);
                    }

                })
                .catch(function (error) {
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT,
                        //onClose: () => window.alert('Called when I close')
                    });
                });
        }


    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            let type;


            if (editMode === true && singleWallet != "") {
                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    Nick: Nick,
                    BankName: BankName,
                    Address: Address,
                    AccountTitle: AccountTitle,
                    PINCode: OTP,
                    ID: singleWallet.ID
                };

                if (AddWalletType === "bank") {
                    data.WalletType = "Bank Account";
                    data.Network = Network;
                    data.AccountCurrency = 'PKR';
                }
                else if (AddWalletType === "crypto") {
                    data.WalletType = "Crypto";
                    data.Network = Network;
                    data.AccountCurrency = Currency;
                }
                else {
                    data.WalletType = "Cash";
                    data.Network = "Cash"
                    data.AccountCurrency = 'PKR';
                }

                var api_config = {
                    method: 'post',
                    url: config.base_url + 'MemberWallet/UpdateWallet',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
            }
            else {

                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    Nick: Nick,
                    BankName: BankName,
                    Address: Address,
                    AccountTitle: AccountTitle,
                    PINCode: OTP,
                    IsDefault: IsDefault,

                };

                if (AddWalletType === "bank") {
                    data.WalletType = "Bank Account";
                    data.Network = Network;
                    data.AccountCurrency = 'PKR';
                }
                else if (AddWalletType === "crypto") {
                    data.WalletType = "Crypto";
                    data.Network = Network;
                    data.AccountCurrency = Currency;
                }
                else {
                    data.WalletType = "Cash";
                    data.Network = "Cash"
                    data.AccountCurrency = 'PKR';
                }
                // console.log(data, "data")
                var api_config = {
                    method: 'post',
                    url: config.base_url + 'MemberWallet/AddWallet',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
            }



            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 1) {
                        setOTP('');
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        handleCloseCanvasAddWallet();

                        if (AddWalletType === "bank") {
                            setActiveClass("Bank");
                        }
                        else if (AddWalletType === "crypto") {
                            setActiveClass("Crypto");
                        }
                        else {
                            setActiveClass("Cash");
                        }
                    }
                    else {
                        toast.error("Invalid Transaction Pin", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    setLoading(false);

                })
                .catch(function (error) {

                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }

        setValidated(true);

    }




    const renderWallet = () => {
        if (AddWalletType == "bank") {
            return (
                <>

                    <div className="text-center mb-3">
                        <h4>Add Bank Account</h4>
                        <p>Please provide a brief description of your bank account.</p>
                    </div>
                    <div className="icon-input">
                        <input type="text"
                            className=""
                            maxLength={13}
                            defaultValue={singleWallet.Nick}
                            onChange={(e) => setNick(e.target.value)}
                            placeholder="Nickname (Optional)" />
                    </div>
                    <div className="icon-input">
                        <input type="text" className=""
                            defaultValue={singleWallet.AccountTitle}
                            onChange={(e) => setAccountTitle(e.target.value)} placeholder="Account Holder Name" required />
                    </div>
                    <div className="icon-input">

                        <input
                            type="text"
                            className=""
                            defaultValue={singleWallet.Address}
                            onChange={(e) => {
                                setAddress(e.target.value.toUpperCase().replace(/\s+/g, ''));
                            }}
                            value={Address}
                            placeholder="IBAN"
                            required
                        />


                    </div>
                    <div className="icon-input">
                        <input type="text" placeholder="PKR" readOnly />
                    </div>
                    <div className="icon-input">
                        <input type="text" className=""
                            defaultValue={singleWallet.BankName}
                            onChange={(e) => setBankName(e.target.value)} placeholder="Bank name" required />
                    </div>
                </>
            )
        }
        else if (AddWalletType == "crypto") {
            return (
                <>
                    <div className="mb-3 text-center">
                        <h4>Add Crypto Wallet</h4>
                        <p>Please make sure your wallet address aligns with the selected payment mode.</p>
                    </div>
                    <div className="icon-input">
                        <input type="text" className="form-control"
                            defaultValue={singleWallet.Nick}
                            maxLength={13}
                            onChange={(e) => setNick(e.target.value)} placeholder="Nickname (Optional)" />
                    </div>

                    <div className="icon-input">
                        <select className="form-select"
                            defaultValue={singleWallet.AccountCurrency}
                            onChange={(e) => setCurrency(e.target.value)} value={Currency} required>
                            <option value="">Select Payment Mode</option>
                            <option value="BTC">Bitcoin - BTC</option>
                            <option value="USDT">TRC20 - USDT</option>
                            {/* <option value="ETH">Ethereum (ERC-20) - ETH</option> */}
                            <option value="TRX">TRON - TRX</option>
                        </select>
                    </div>

                    <div className="icon-input">
                        <input type="text" className="form-control"
                            defaultValue={singleWallet.Address}
                            onChange={(e) => setAddress(e.target.value)} placeholder="Wallet address" required />
                    </div>


                </>
            )
        }

        else if (AddWalletType == "Cash") {
            return (
                <>
                    <div className="text-center mb-3">
                        <h4>Add Cash Collection Wallet</h4>
                    </div>
                    <div className="icon-input">
                        <input type="text" className="form-control"
                            defaultValue={singleWallet.Nick}
                            maxLength={13}
                            onChange={(e) => setNick(e.target.value)} placeholder="Nickname (Optional)" />
                    </div>
                    <div className="icon-input">
                        <select className="form-select"
                            defaultValue={singleWallet.Address}
                            onChange={(e) => setAddress(e.target.value)} required>
                            <option value="">Choose Office</option>
                            <option value="Islamabad Head Office">Islamabad Head Office</option>
                            {/* <option value="Lahore Head Office">Lahore Head Office</option> */}
                        </select>
                    </div>


                </>
            )
        }

    }

    const ValueNull = () => {
        setValidated(false);
        setOTP('');
        setBankName('');
        setAddress('');
        setNick('');
        setNetwork('');
        setCurrency('')

    }

    return (
        <>
            <LoginCheck />
            <ToastContainer />

            <Modal className="bs-modal" show={showCanvasAddWallet} onHide={handleCloseCanvasAddWallet} onExit={ValueNull}>
                <Modal.Header>
                    <Modal.Title>Add Wallet</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        {renderWallet()}
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input form-check-bs"
                                defaultChecked={singleWallet.IsDefault ? true : false}
                                onChange={(e) => {
                                    e.target.checked ? setIsDefault(true) : setIsDefault(false)
                                }}
                                id="default" />
                            <label className="form-check-label" for="default">Make Default</label>
                        </div>

                        <div className="icon-input">
                            <input
                                type="number"
                                value={OTP}
                                onChange={(e) => {
                                    if (e.target.value.length <= 6) {
                                        setOTP(e.target.value);
                                    }
                                }}
                                placeholder='xxxxxx'
                                className="text-center"
                                required
                            />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="button button-bs w-100" disabled={loading}>Save {loading ? '...' : ''}</button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <div className="row align-items-center mb-3">
                <div className="col-md-3">
                    <div className="section-title">Payment Modes</div>
                </div>
                <div className="col-md-9 text-md-end">
                    <div className="button-row justify-content-md-end justify-content-center">
                        <NavLink className="button button-outline-bs button-icon-bs" to="/martinpay/withdraw">
                            <span>Back to Withdraw</span>
                            <ArrowUturnLeftIcon />
                        </NavLink>
                        <button className="button button-bs button-icon-bs" type="button" onClick={(e) => handleShowCanvasAddWallet('Cash')}>
                            <span>Cash Collection</span>
                            <PlusIcon />
                        </button>
                        <button className="button button-bs button-icon-bs" type="button" onClick={(e) => handleShowCanvasAddWallet('crypto')}>
                            <span>Crypto Wallet</span>
                            <PlusIcon />
                        </button>
                        <button className="button button-bs button-icon-bs" type="button" onClick={(e) => handleShowCanvasAddWallet('bank')}>
                            <span>Bank Account</span>
                            <PlusIcon />
                        </button>
                    </div>
                </div>
            </div>
            {
                loading ?
                    <Loader />
                    :

                    <>
                        <div className="active-link-menu">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${ActiveClass == "Crypto" ? "active" : ""}`} onClick={(e) => setActiveClass("Crypto", e)}>
                                        <CurrencyEuroIcon />
                                        <span>Crypto</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${ActiveClass == "Bank" ? "active" : ""}`} onClick={(e) => setActiveClass("Bank", e)}>
                                        <BuildingLibraryIcon />
                                        <span>Bank Wire</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${ActiveClass == "Cash" ? "active" : ""}`} onClick={(e) => setActiveClass("Cash", e)}>
                                        <BanknotesIcon />
                                        <span>Cash</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {ActiveClass == 'Crypto' ?
                            <div className="partner-table">
                                <div className="table-wrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Created Date</th>
                                                <th>Wallet Name</th>
                                                <th>Nick</th>
                                                <th>Mode</th>
                                                <th>Address</th>
                                                <th>Default</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading ?
                                                    <tr>
                                                        <td className="text-center" colSpan="9">
                                                            <Spinner animation="border" role="status"></Spinner>
                                                        </td>
                                                    </tr>
                                                    :
                                                    renderCryptoWallets()
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            ActiveClass == 'Bank' ?
                                <div className="partner-table">
                                    <div className="table-wrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <th>Account Name</th>
                                                    <th>Nick</th>
                                                    <th>Account Holder Name</th>
                                                    <th>IBAN</th>
                                                    <th>Currency</th>
                                                    <th>Bank Name</th>
                                                    <th>Default</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading ?
                                                        <tr>
                                                            <td className="text-center" colSpan="10">
                                                                <Spinner animation="border" role="status"></Spinner>
                                                            </td>
                                                        </tr>
                                                        :
                                                        renderBankWallets()
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                <div className="partner-table">
                                    <div className="table-wrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <th>Wallet Name</th>
                                                    <th>Nick</th>
                                                    <th>Mode</th>
                                                    <th>Address</th>
                                                    <th>Default</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading ?
                                                        <tr>
                                                            <td className="text-center" colSpan="9">
                                                                <Spinner animation="border" role="status"></Spinner>
                                                            </td>
                                                        </tr>
                                                        :
                                                        renderCashWallets()
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        }

                    </>
            }
            <div className="pb-5"></div>

        </>
    );
}

export default ManageWallets;