import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Heart from '../../../assets/images/heart.png';
import Orangnalring from '../../../assets/images/orangnal-ring.png';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import ProductsSaleCard from '../website-component/ProductsSaleCard';

import AnkleBracelet from '../../../assets/images/AnkleBracelet.jpg';
import StudEarrings from '../../../assets/images/StudEarrings.jpg';
import CrumpledRing from '../../../assets/images/CrumpledRing.jpg';
import MoonNecklace from '../../../assets/images/MoonNecklace.jpg';


import axios from 'axios';
import Config from '../../../Config';
import { Placeholder } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';


function ProductListingByCategory(props) {

    let params = useParams();

    const [loading, setLoading] = useState(false);
    const [ListOfProducts, setListOfProducts] = useState([]);


    useEffect(() => {

        setLoading(true);

        const data = {
            CategoryID: params.id,
            pageNo: 1,
            pageSize: 20,
        }

        console.log(data);

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Product/GetProductsByCategoryID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setListOfProducts(response.data.listofRecords);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    }, []);


    return (
        <>
            <section className="page-head">
                <p>Our Products</p>
                <h3>Shop</h3>
            </section>

            <section className='simple-section'>
                <div className='container'>
                    <div className="row gy-3">
                        {
                            ListOfProducts.map((item, index) => (
                                <div className="col-md-3">
                                    <ProductsSaleCard
                                        link={`/product/${item.ProductURL}/${item.ID}`}
                                        img={`${Config.path}Images/Products/${item.MediumImage}`}
                                        heading={item.Name}
                                        sale={'Sale'}
                                        detail={item.SellingPrice}
                                        cut={'$59'}
                                        discount={'-15%'}
                                    />
                                </div>
                            ))
                        }


                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductListingByCategory