import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Heart from '../../../assets/images/heart.png';
import Orangnalring from '../../../assets/images/orangnal-ring.png';
import { MinusIcon, PlusIcon, StarIcon } from '@heroicons/react/24/outline';

import axios from 'axios';
import Config from '../../../Config';
import { Placeholder } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { RenderHTMLString } from '../../Common/Util';


function ProductDetail(props) {

    let params = useParams();
    const [quantity, setQuantity] = useState(0);
    const [Loading, setLoading] = useState('');
    const [Product, setProduct] = useState({});
    const [ListofImages, setListofImages] = useState([]);

    useEffect(() => {

        setLoading(true);

        const data = {
            MemberType: "Website",
            ID: params.id,
        }

        console.log(data);

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Product/GetProductByIDWithImage',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setProduct(response.data.Product);
                    setListofImages(response.data.Product.ListofImages);
                    document.title = response.data.Product.Name;
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    }, []);


    const handleDecrement = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        }
    };

    const handleIncrement = () => {
        setQuantity(quantity + 1);
    };

    return (
        <>
            {/* <section className="simple-section page-head product-head">
                <div className="top-text">DESIGNER JEWELRY</div>
                <div className="section-title">Shop Detail</div>
            </section> */}
            <section className='product-purchase bg-black simple-section'>
                <div className="container">
                    {
                        Loading ?
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Placeholder as="div" animation="glow">
                                        <Placeholder xs={12} style={{ height: 500 }} />
                                    </Placeholder>
                                </div>
                                <div className='col-md-6'>
                                    <div className='ps-md-5'>
                                        <Placeholder as="h3" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                        <Placeholder as="h5" animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as="p" animation="glow">
                                            <Placeholder xs={8} />
                                            <Placeholder xs={8} />
                                            <Placeholder xs={8} />
                                            <Placeholder xs={8} />
                                        </Placeholder>
                                        <Placeholder as="p" animation="glow">
                                            <Placeholder xs={8} />
                                            <Placeholder xs={8} />
                                            <Placeholder xs={8} />
                                            <Placeholder xs={8} />
                                        </Placeholder>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className="row">
                                <div className="col-md-6">
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true}
                                        navigation={{
                                            nextEl: '.custom-next',
                                            prevEl: '.custom-prev',
                                        }}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Navigation]}
                                        className="mySwiper"
                                    >
                                        {
                                            ListofImages.map((item, index) => (
                                                <SwiperSlide>
                                                    <img src={`${Config.path}Images/Products/${item.LargeImage}`} className='img-fluid' />
                                                </SwiperSlide>
                                            ))
                                        }


                                    </Swiper>
                                </div>
                                <div className="col-md-6">
                                    <h1 className="product-title text-white">
                                        {Product.Name}
                                    </h1>
                                    <h3>${Product.SellingPrice}</h3>
                                    <ul className='star-rating'>
                                        <li><StarIcon className='active' /></li>
                                        <li><StarIcon className='active' /></li>
                                        <li><StarIcon className='active' /></li>
                                        <li><StarIcon /></li>
                                        <li><StarIcon /></li>

                                    </ul>


                                    <div className="section-detail mt-3">
                                        {
                                            RenderHTMLString(Product.Details)
                                        }

                                        <br />
                                        <p>{Product.Tagline}</p>
                                    </div>




                                    <div className="button-row mt-5">
                                        <div className="quantity-box">
                                            <button type="button" className="minus-btn" onClick={handleDecrement}>
                                                <MinusIcon />
                                            </button>
                                            <input
                                                type="text"
                                                className="input-qty"
                                                value={quantity}
                                                readOnly
                                            />
                                            <button type="button" className="plus-btn" onClick={handleIncrement}>
                                                <PlusIcon />
                                            </button>
                                        </div>
                                        <div className="button button-primary">
                                            Add to Cart
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                </div>

            </section>
        </>
    )
}

export default ProductDetail