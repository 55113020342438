import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";
import AdminRightsCheck from "../AdminRightsCheck";
import Form from 'react-bootstrap/Form';
import { JsonToExcel } from "react-json-to-excel";
import ReactTooltip from "react-tooltip";

function Rejected(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [ledgerOld, setLedgerOld] = useState([]);

  const [status, setStatus] = useState("Rejected");

  const [statusChangedSuccess, setStatusChangedSuccess] = useState(false);

  const [showFilters, setShowFilters] = useState(true);


  const [ExcelPageSize, setExcelPageSize] = useState(10000);
  const [ExcelArray, setExcelArray] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilteredByDate, setisFilteredByDate] = useState(false);

  const [paymentMode, setpaymentMode] = useState("All");

  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [Full_Name, setFull_Name] = useState('');
  const [GrandTotal, setGrandTotal] = useState('');


  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Martinpay Admin";
    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: status,
      startDate: StartDate,
      endDate: EndDate,
      UserName: Full_Name,
      PaymentMode: paymentMode,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/users/GetWithdrawalByStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code === 1) {
          //to remove all null values
          const filtered = response.data.ListofRecords.map((obj) => {
            const convertobj = { ...obj };
            for (let key in convertobj) {
              if (convertobj[key] === null) {
                convertobj[key] = "";
              }
            }
            return convertobj;
          });
          setLedger(filtered);
          setLedgerOld(filtered);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          setGrandTotal(response.data);

        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
    setIsUpdated(false);
  }, [pageNo, pageSize, isUpdated, status, statusChangedSuccess, EndDate, StartDate, paymentMode]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  // filter by date ...................

  const filterByDate = (e) => {
    let date = moment(e.target.value).format("MM DD YYYY");

    if (date != "") {
      let member = ledgerOld.filter(
        //console.log(moment(member.Transaction_Date).format('MM DD YYYY, h:mm:ss a'))
        (member) => moment(member.Created_Date).format("MM DD YYYY") == date
      );

      setLedger(member);
    } else {
      setLedger(ledgerOld);
    }

    setisFilteredByDate(true);
  };
  // filter by inputs ...................
  const filterAlls = (e) => {
    let term = e.target.value;
    if (term !== "") {
      let filteredArray = listOfLedger.filter((object) =>
        Object.values(object).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      // console.log("filtered ", filteredArray);

      setLedger(filteredArray);
    } else {
      setLedger(ledgerOld);
    }
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        // console.log(item);
        return (
          <tr key={index}>
            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
            <td>
              {moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}
              <br />
              {moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}
            </td>
            <td>{item.User_Name}</td>
            <td>{item.PaymentID}</td>
            <td>{item.PaymentMode}</td>
            <td>{item.BankName}</td>
            <td>{item.AccountTitle}</td>
            <td className="">
              <div className="d-inline-flex justify-content-between align-items-center w-100">
                <p className="m-0">
                  {item.Address}
                </p>
                <button
                  className="button "
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(item.Address);
                    toast.success("Address Copied !", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }}
                >
                  <span>
                    <i
                      style={{ fontSize: "18px" }}
                      className="material-symbols-outlined"
                    >
                      content_copy
                    </i>
                  </span>
                </button>
              </div>
            </td>
            <td>{item.SelectedCurrencyRate}</td>
            <td>{item.NetReturnableInCurrency}</td>
            <td>PKR {item.FeeAmount}</td>
            <td>PKR {item.Amount}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4 className="else">
                            No Data Found
                        </h4>
          </td>
        </tr>
      );
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log(isUpdated);
    setIsUpdated(true);
  };

  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: ExcelPageSize,
      Status: status,
      startDate: StartDate,
      endDate: EndDate,
      UserName: Full_Name,
      PaymentMode: paymentMode,
    };
    // console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "/users/GetWithdrawalByStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log("response",response.data.ListofRecords)
        let data = response.data.ListofRecords;
        let Records = response.data.totalRecords;
        let count = 1;
        const listOfLedgerNew = data.map(({ ...item }) => ({
          Serial_No: Records - count++ + 1,
          CreatedDate: moment(item.Created_Date).format("DD/MM/YYYY HH:mm"),
          UpdatedDate: moment(item.Updated_Date).format("DD/MM/YYYY HH:mm"),
          UserName: item.User_Name,
          Transaction_ID: item.PaymentID,
          Payment_Mode: item.PaymentMode,
          Crypto_Address: item.Address,
          Exchange_Rate: item.SelectedCurrencyRate,
          Receivable_Amount: item.NetReturnableInCurrency,
          Refunded_Fee: `PKR ${(item.FeeAmount)}`,
          Refunded_Amount: `PKR ${(item.Amount)}`,
        }));
        setExcelArray(listOfLedgerNew)
      })
      .catch(function (error) {
      });
  }, [pageNo, ExcelPageSize, isUpdated, status, statusChangedSuccess, StartDate, EndDate, paymentMode]);



  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <ReactTooltip />
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Rejected Withdrawal List
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/withdrawal-dashboard">
                 <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Filters Menu */}
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row p-3 align-items-end gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Select Payment Mode</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={paymentMode}
                          onChange={(e) => setpaymentMode(e.target.value)}
                        >
                          <option value="All"> All </option>
                          <option value="USDT">TRC20 - USDT</option>
                          <option value="TRX">TRON - TRX</option>
                          <option value="BTC">Bitcoin - BTC</option>
                          <option value="Bank Account">Bank Account</option>
                          <option value="Cash">Cash</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={StartDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <ul className="downloads">
                      <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                        <Link target="_blank" to={`/admin/withdrawal-dashboard/rejected-List-pdf/?StartDate=${StartDate}&EndDate=${EndDate}&paymentMode=${paymentMode}&Full_Name=${Full_Name}&status=${status}`}>PDF</Link>
                      </li>
                      <li className="round-button" data-tip={'Download as Excel'} data-place="bottom">
                        <JsonToExcel
                          title="EXCEL"
                          data={ExcelArray}
                          fileName="MAG REJECTED WITHDRAWAL LIST"
                          btnClassName="excel-button"
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="form_default position-relative">
                      <Form onSubmit={handleSearch}>
                        <div className="row align-items-end gy-3 gx-1">
                          <div className="col-md-7">
                            <div className="form-group search_box">
                              <label htmlFor="">Search Member</label>
                              <div className="inputwrap">
                                <input type="text" className="form-control"
                                  placeholder="Find by Username/Member ID/Email"
                                  value={Full_Name}
                                  onChange={(e) => setFull_Name(e.target.value)}
                                />
                                <span className="material-symbols-outlined">search</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Created Date
                    <br />
                    Updated Date
                  </th>
                  <th>Username</th>
                  <th>Transaction ID</th>
                  <th style={{ minWidth: "140px" }}>Payment Mode</th>
                  <th>Bank Name</th>
                  <th>Account Title</th>
                  <th>Crypto Address / IBAN</th>
                  <th style={{ minWidth: "140px" }}>Exchange Rate</th>
                  <th className="text-nowrap">Receivable amount</th>
                  <th style={{ minWidth: "120px" }}>Refunded Fee</th>
                  <th>Refunded Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className="filter-row">
                  <td></td>


                  <td>
                    <div className="d-flex">
                      <div className="inputwrap">
                        <input
                          type="date"
                          style={{ width: "180px" }}
                          onChange={filterByDate}
                          className="form-control"
                        />
                      </div>
                      {isFilteredByDate === true && (
                        <button
                          className="button p-0 bg-transparent"
                          onClick={() => {
                            setisFilteredByDate(false);
                            setLedger(ledgerOld);
                          }}
                        >
                          <span
                            style={{ lineHeight: "unset" }}
                            className="material-symbols-outlined"
                          >
                            close
                          </span>
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        style={{ width: "120px" }}
                        placeholder="Username"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Transaction ID"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        style={{ width: "120px" }}
                        placeholder="Payment Mode"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Bank Name"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Account Title"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Crypto Address / IBAN"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Exchange Rate"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Receivable amount"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Refunded Fee"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Refunded Amount"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                </tr>

                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="12">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}

                    {
                      listOfLedger.length > 0 &&
                      <>
                        <tr style={{ borderButtom: "none !important" }}>
                          <th colSpan={9} className="border-bottom-0 pt-3"></th>
                          <th className="text-end border-bottom-0 pt-3">Subtotal:</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'FeeAmount'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Amount'))}</th>
                        </tr>
                        <tr className="">
                          <th className="border-bottom-0" colSpan={9}></th>
                          <th className="border-bottom-0 text-end">Grand Total: </th>
                          <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.adminFee)}</th>
                          <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.grandTotal)}</th>
                        </tr>
                      </>
                    }
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>


        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Rejected;
