import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import config from "../../../Config";
import { Editor } from "@tinymce/tinymce-react";
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from "react-toastify";
import { Cropper } from "react-cropper";
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/outline";

function UpdateCategory(props) {
    let params = useParams();
    let navigate = useNavigate();
    const [validated, setValidated] = useState(false);

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [Loading, setLoading] = useState('')
    const [isNew, setisNew] = useState('');


    const [Category, setCategory] = useState([]);
    const [GetCategory, setGetCategory] = useState([]);

    const [CategoryImage, setCategoryImage] = useState("");

    const [fileList, setFileList] = useState([]);



    const onRadioChange = (e) => {
        setisNew(e.target.value);
    };

    // images



    // front side
    const [CategoryPhoto, setCategoryPhoto] = useState("");
    const [CategoryPhotoSelected, setCategoryPhotoSelected] = useState(false);
    const [CategoryCropper, setCategoryCropper] = useState();


    // back side
    const [BannerImage, setBannerImage] = useState("");
    const [BannerImageSelected, setBannerImageSelected] = useState(false);
    const [BannerCropper, setBannerCropper] = useState();



    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState([]);
    const [previewTitle, setPreviewTitle] = useState('');
    const [CategoryImageURL, setCategoryImageURL] = useState("");

    const DetailRef = useRef(null);
    const [Detail, setDetail] = useState("");

    const [IsUpdated, setIsUpdated] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const handleDetailChange = (content) => {
        setDetail(content);
    };


    useEffect(() => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'ProductCategory/GetMainCategories',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setCategory(response.data.listofRecords)
                }
            })
            .catch(function (error) {

            });
    }, []);




    useEffect(() => {
        setIsUpdated(false);
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
        };
        // console.log("id",params.id)
        var api_config = {
            method: 'post',
            url: config.base_url + 'ProductCategory/GetCategoryByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    setGetCategory(response.data.Category);
                    setisNew(response.data.Category.IsNew ? "true" : 'false');
                    setBannerImage(response.data.Category.BannerImage);
                    setCategoryImage(response.data.Category.CategoryPhoto);

                    setCategoryPhotoSelected(true);
                    const CategoryPhoto = response.data.Category.CategoryPhoto;
                    const category_img_url = config.root + '/Images/Products/Categories/' + CategoryPhoto;
                    setCategoryPhoto(category_img_url);

                    console.log(category_img_url)
                    setBannerImageSelected(true);
                    const BannerImage = response.data.Category.BannerImage;
                    const banner_image_url = config.root + '/Images/Products/Categories/' + BannerImage;
                    setBannerImage(banner_image_url);

                    setLoading(false);

                }
            })
            .catch(function (error) {

            });
    }, [IsUpdated]);



    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: params.id,
                IsNew: isNew,
                CategoryDetails: Detail,
                Short_Description: event.target.Short_Description.value,
                SEOTitle: event.target.SEOTitle.value,
                SEOKeywords: event.target.SEOKeywords.value,
                SEODescription: event.target.SEODescription.value,
                SortOrder: event.target.SortOrder.value,
                CategoryName: event.target.CategoryName.value,
                ParentCategoryID: event.target.ParentCategoryID.value,
                CategoryPhoto: CategoryPhoto,
                BannerImage: BannerImage
            };

            if (!CategoryPhoto.startsWith("data:image/png;base64")) {
                toast.error('Please select PNG Category Photo', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            } else if (!BannerImage.startsWith("data:image/png;base64")) {
                toast.error('Please select PNG Banner Image', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
                return;
            }

            else {

                var api_config = {
                    method: 'post',
                    url: config.base_url + 'ProductCategory/UpdateCategory',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(api_config)
                    .then(function (response) {
                        console.log(response.data, 'response')

                        if (response.data.status_code == 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setLoading(false);
                            setLoadingUpdate(false);
                            setIsUpdated(true);
                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setLoadingUpdate(false);
                            setLoading(false);
                        }
                    })
                    .catch(function (error) {
                    });
            }
        }

        setValidated(true);
    };

    const DeleteCategoryPhoto = () => {
        setCategoryPhoto("");
        setCategoryPhotoSelected(false);
        setCategoryCropper();
    }

    const DeleteBannerImage = () => {
        setBannerImage("");
        setBannerImageSelected(false);
        setBannerCropper();
    }


    //const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const MAX_IMAGE_SIZE = 4096 * 1024; // 2MB in bytes


    const [dragging, setDragging] = useState(false);
    const [dragFront, setdragFront] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };
    const handleDragfirst = (event) => {
        event.preventDefault();
        setdragFront(true);
    };

    const handleDropfirst = (event) => {

        event.preventDefault();
        setCategoryPhotoSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {


            if (file.size > MAX_IMAGE_SIZE) {
                setCategoryPhotoSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                console.log(e.target.result);
                setCategoryPhoto(e.target.result);
                if (BannerCropper) {
                    BannerCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const handleDrop = (event) => {

        event.preventDefault();
        setBannerImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setBannerImageSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            // console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setBannerImage(e.target.result);
                if (BannerCropper) {
                    BannerCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const onChangeFront = (e) => {
        e.preventDefault();

        setCategoryPhotoSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];

        console.log('file size', file.size);
        console.log('allowed size', MAX_IMAGE_SIZE);


        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setCategoryPhotoSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2), { position: toast.POSITION.TOP_RIGHT, });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            console.log('result', reader.result);
            setCategoryPhoto(reader.result);



        };
        reader.readAsDataURL(files[0]);
    }

    const onChangeBack = (e) => {
        e.preventDefault();
        setBannerImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setBannerImageSelected(false);
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }


        const reader = new FileReader();
        reader.onload = () => {
            setBannerImage(reader.result);


        };
        reader.readAsDataURL(files[0]);
    }

    return (
        <>

            <ToastContainer />

            <div className="admin-content">
                <div className="row gy-3 align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Update Category
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/categories/manage-categories">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <NavLink to={`/admin/categories/manage-categories`} className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                Manage Category
                                <i className="ri-add-line ms-3"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-3 mt-4">
                    <div className="card-inner">
                        <div className="form_default">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row p-3 gy-3">
                                    <div className="col-md-8">
                                        <div className="row gy-3">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Main Category</label>
                                                    <div className="inputwrap">
                                                        <select className="form-select" name="ParentCategoryID">
                                                            {
                                                                Category.length > 0 ?
                                                                    Category.map((item, index) => (
                                                                        <option value={item.ID} key={index}>{item.CategoryName}</option>
                                                                    ))
                                                                    :
                                                                    ""
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Category Name</label>
                                                    <div className="inputwrap">
                                                        <input type="text" className="form-control" defaultValue={GetCategory.CategoryName} name="CategoryName" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Sort Order</label>
                                                    <div className="inputwrap">
                                                        <input minLength={0} type="number" className="form-control" defaultValue={GetCategory.SortOrder} name="SortOrder" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Short Description</label>
                                                    <div className="inputwrap">
                                                        <textarea className="form-control" defaultValue={GetCategory.Short_Description} name="Short_Description"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Category Details</label>
                                                    <div className="inputwrap">
                                                        <Editor
                                                            apiKey="w81u1otpwt8qqd0odorwvyaol3vsrjipuhdua1mr6kjqnebw"
                                                            init={{
                                                                plugins:
                                                                    "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                                                toolbar:
                                                                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                                            }}
                                                            onInit={(editor) => DetailRef.current = editor}
                                                            onEditorChange={handleDetailChange}
                                                            initialValue={GetCategory.CategoryDetails}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4" >
                                        <div className="row gy-3">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Is New</label>
                                                    <div className="d-flex gap-4">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="is_new_yes"
                                                                value="true"
                                                                checked={isNew === "true"}
                                                                onChange={onRadioChange}
                                                            />
                                                            <label className="form-check-label" htmlFor="is_new_yes">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="is_new_no"
                                                                value="false"
                                                                checked={isNew === "false"}
                                                                onChange={onRadioChange}
                                                            />
                                                            <label className="form-check-label" htmlFor="is_new_no">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="inputwrap">
                                                        <label>SEO Title</label>
                                                        <input type="text" name="SEOTitle" className="form-control" defaultValue={GetCategory.SEOTitle} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="inputwrap">
                                                        <label>SEO Keywords</label>
                                                        <input type="text" name="SEOKeywords" className="form-control" defaultValue={GetCategory.SEOKeywords} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="inputwrap">
                                                        <label>SEO Description</label>
                                                        <textarea name="SEODescription" rows={3} className="form-control" defaultValue={GetCategory.SEODescription}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="kyc-upload">
                                                    <div className={`kyc-img-card white mb-3`} style={{ width: "100%" }}>
                                                        <div className="card-inner">
                                                            <div className={`upload-box`}
                                                                id="drop-area"
                                                                onDragOver={handleDragfirst}
                                                                onDrop={handleDropfirst}
                                                            >
                                                                <input id="ChoosePhotoFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFront} />
                                                                {
                                                                    CategoryPhotoSelected ?
                                                                        <>
                                                                            <Cropper
                                                                                // style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                                zoomTo={0}
                                                                                preview=".img-preview"
                                                                                src={CategoryPhoto}
                                                                                dragMode="move"
                                                                                viewMode={2}
                                                                                minCropBoxHeight={10}
                                                                                minCropBoxWidth={10}
                                                                                background={false}
                                                                                responsive={true}
                                                                                autoCropArea={1}
                                                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                                onInitialized={(instance) => {
                                                                                    setCategoryCropper(instance);
                                                                                }}
                                                                                guides={true}
                                                                            />
                                                                            <div className="delete" onClick={DeleteCategoryPhoto}>
                                                                                <TrashIcon />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <label htmlFor="ChoosePhotoFront" className='thumnail'>
                                                                            <ArrowUpTrayIcon />
                                                                            <p>Upload Category Photo</p>
                                                                        </label>

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="kyc-upload">
                                            <div className={`kyc-img-card white`} style={{ width: "100%" }}>
                                                <div className="card-inner">
                                                    <div className={`upload-box`}
                                                        id="drop-area"
                                                        onDragOver={handleDragOver}
                                                        onDrop={handleDrop}
                                                    >

                                                        <input id="ChoosePhotoBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBack} />

                                                        {
                                                            BannerImageSelected ?
                                                                <>
                                                                    <Cropper
                                                                        // style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                        zoomTo={0}
                                                                        preview=".img-preview"
                                                                        src={BannerImage}
                                                                        dragMode="move"
                                                                        viewMode={2}
                                                                        minCropBoxHeight={10}
                                                                        minCropBoxWidth={10}
                                                                        background={false}
                                                                        responsive={true}
                                                                        autoCropArea={1}
                                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                        onInitialized={(instance) => {
                                                                            setBannerCropper(instance);
                                                                        }}
                                                                        guides={true}
                                                                    />
                                                                    <div className="delete" onClick={DeleteBannerImage}>
                                                                        <TrashIcon />
                                                                    </div>
                                                                </>
                                                                :
                                                                <label htmlFor="ChoosePhotoBack" className='thumnail'>
                                                                    <ArrowUpTrayIcon />
                                                                    <p>Upload Banner Image</p>
                                                                </label>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-end">
                                        <div className="form-group">
                                            <button className="button button-primary" disabled={Loading} type="submit">
                                                Save Category {Loading ? '...' : ''}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default UpdateCategory;