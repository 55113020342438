import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Logo from "../../assets/images/logo/mag.svg";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/images/logo/mag.svg';
import { NavLink, Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import AdminProfileUpdate from "./AdminProfileUpdate";
import UsaTimeComponentb from "../Dashboard/UserControls/UsaTimeComponent";
import Dropdown from 'react-bootstrap/Dropdown';


function AdminHeader({ opened, closed, openSide }) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [AdminFull_Name, setAdminFull_Name] = useState(localStorage.getItem("AdminFull_Name"));

    let navigate = useNavigate();
    const handleSignOut = () => {
        localStorage.clear();
        navigate("/admin/login");
    }


    const [active, setActive] = useState(false);

    const handleClick = () => {

        var admin_side_bar = document.getElementById("admin_side_bar");
        var overlay = document.getElementById("overlaymenu");
        var menubutton = document.getElementById("menubutton");
        var body = document.getElementById("body");


        if (overlay.classList.length === 0 && !menubutton.classList.contains('active')) {
            setActive(true);
            admin_side_bar.classList.add('active');
            overlay.classList.add('active');
            body.classList.add('overflow-hidden');
        } else {
            setActive(false);
            admin_side_bar.classList.remove('active');
            overlay.classList.remove('active');
            body.classList.remove('overflow-hidden');

        }

        document.getElementById("menubutton").addEventListener("click", closeMenu);

    };

    function closeMenu() {
        setActive(!true);
    }

    return (
        <>
            <Navbar className="header-admin d-md-block d-none" expand="lg" >
                <div className="container-fluid">
                    <div className="d-flex align-items-center w-100" style={{ paddingLeft: "2%" }}>
                        <NavLink className="navbar-brand" to="/admin/dashboard">
                            <img className="d-md-block d-none" src={Logo} />
                        </NavLink>
                      
                        <div className="server-time">
                            <UsaTimeComponentb />
                        </div>
                    </div>

                    <Dropdown>
                        <Dropdown.Toggle variant="user-dropdown text-start" id="dropdownUserLoggedIn">
                            <div id="basic-navbar-nav">
                                <div className="admin_logged_in ms-auto">
                                    <ul className="admin_header_right">
                                        <li>
                                            <div className="admin_logged_in_user">
                                                <span>
                                                    <img src={logo} alt="logo" />
                                                </span>
                                                <div>
                                                    <h4>{AdminFull_Name}</h4>
                                                    <p>Admin</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <button className="dropdown-item admin-menu" onClick={() => navigate('/admin/admin-update-profile')} >
                                <i className="material-symbols-outlined">lock</i>
                                <span>Change Password</span>
                            </button>
                            <button onClick={handleSignOut} className="dropdown-item admin-menu">
                                <i className="material-symbols-outlined">logout</i>
                                <span> Sign Out</span>
                            </button>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            </Navbar>
            <Navbar className="admin-navbar">
                <div className="menu">
                    <svg
                        id="menubutton"
                        className={`ham hamRotate ham4 ${active ? 'active' : ''}`}
                        onClick={handleClick}
                        viewBox="0 0 100 100" width="80">
                        <path
                            className="line top"
                            d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
                        <path
                            className="line middle"
                            d="m 70,50 h -40" />
                        <path
                            className="line bottom"
                            d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
                    </svg>
                </div>
                <div className="user_id">
                    <div className="admin_logged_in_user">
                        <span>
                            <img src={logo} alt="logo" />
                        </span>
                        <div>
                            <h4>{AdminFull_Name}</h4>
                            <p>Admin</p>
                        </div>
                    </div>
                </div>
            </Navbar>
        </>
    );
}

export default AdminHeader;