import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";

function RightAssignment(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [UserGroup, setUserGroup] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [loadingItem, setLoadingItem] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});
  let params = useParams();

  const [GroupID, setGroupID] = useState(params.id);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Martinpay Admin";

    setIsUpdated(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      ID: GroupID,
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/GetUserGroupByID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {
          setUserGroup(response.data.UserGroup);

        }
        else if (response.data.status_code == 2 && response.data.status_message == "Invalid Request Parameters") {
          toast.error('Invalid Request Perameters', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if (response.data.status_code == 0 && response.data.status_message == "No Group exists") {
          toast.error('No Group exists', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT
        });
      });



    // setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      ID: GroupID
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/GetAllPagesByGroupID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dashboardParms
    };


    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });



  }, [isUpdated]);



  const renderLedger = () => {

    if (listOfLedger.length > 0) {
      return (
        listOfLedger.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.ID}</td>
              <td>{item.ControllerName}</td>
              <td>{item.FunctionName}</td>
              <td>{item.FullURL}</td>
              <td>{item.Details}</td>
              <td className="">
                {
                  loadingItem === item.ID ?
                    <Spinner animation="grow" size="sm" />
                    :
                    <div className="form-check ms-3">
                      <input className="form-check-input"
                        checked={item.hasRights}
                        type="checkbox" onChange={(e) => UpdateRights(e, item.ID)} />
                    </div>
                }
              </td>
            </tr>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="6"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
        </tr>
      )
    }

  }

  const UpdateRights = (e, ID) => {

    setLoadingItem(ID);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      ControllerID: ID,
      GroupID: GroupID,
      hasRights: e.target.checked
    };

    //console.log(data);
    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/UpdateGroupRight',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1 && response.data.status_message == "Rights Added Successfully.") {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsUpdated(true);
          setLoadingItem(null);
        }
        else if (response.data.status_code == 1 && response.data.status_message == "Rights Deleted Successfully.") {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsUpdated(true);
          setLoadingItem(null);
        }


      })
      .catch(function (error) {
        console.log(error);
      });
  }



  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-8">
            <div className="d-title m-0">
              Rights Assignment for Administrator
            </div>
          </div>
          <div className="col-md-4 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/security-dashboard/manage-groups">
                 <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
        <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Sr#
                  </th>
                  <th>
                    Controller
                  </th>
                  <th>
                    Action
                  </th>
                  <th>
                    URL
                  </th>
                  <th>
                    Details
                  </th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="10">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )} */}
      </div>
    </>
  );
}

export default RightAssignment;
