import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Loader from "./Loader";
import { Spinner } from "react-bootstrap";

function LoginCheck(props) {

    let navigate = useNavigate();
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        if (AccessKey == null) {
            // navigate("/login");
        }
        else {
            const dashboardParms = {
                AccessKey: AccessKey,
                UserID: UserID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/ValidateAccessKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: dashboardParms
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 0) {
                        // localStorage.clear();
                        // navigate("/login");
                    }

                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, []);

    return (
        <></>

    )
}

export default LoginCheck;