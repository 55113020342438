import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import { NavLink, useParams } from 'react-router-dom';

function ManageAttributesValues(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    

    const [Attributes, setAttributes] = useState([]);
    const [Attribute, setAttribute] = useState({});
    const [AttributeID, setAttributeID] = useState(0);
    const [IsAttributeSaved, setIsAttributeSaved] = useState(false);


    let params = useParams();


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingSave, setloadingSave] = useState(false);
    const [loadingUpdate, setloadingUpdate] = useState(false);
    const [loadingGetAttribute, setloadingGetAttribute] = useState(false);


    // modal

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (ID) => {
        setIsModalOpen(true);
        setAttributeID(ID);
        setloadingGetAttribute(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'AttributeValue/GetAttributeValueByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {

                
                if (response.data.status_code == 1) {
                    setAttribute(response.data.AttributeValue);
                    setloadingGetAttribute(false);
                }
                else {
                    setloadingGetAttribute(false);
                }
            })
            .catch(function (error) {
                

            });
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setAttribute({});
        setAttributeID(0);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setAttribute({});
        setAttributeID(0);
    };


    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
        };
        // console.log("id",params.id)
        var api_config = {
            method: 'post',
            url: config.base_url + 'Attribute/GetAttributeByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        // console.log(data)
        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    setAttribute(response.data.Attribute);
                }
            })
            .catch(function (error) {

            });
    }, []);


    useEffect(() => {
        document.title = "Manage Attributes";
        setIsAttributeSaved(false);
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            AttributeID: params.id
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'AttributeValue/GetValuesByAttributeID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                
                if (response.data.status_code == 1) {
                    setAttributes(response.data.listofRecords);
                    setLoading(false);
                }
                else {

                    setLoading(false);
                }
            })
            .catch(function (error) {

            });


    }, [pageNo, pageSize, IsAttributeSaved]);


    const onFinish = (formData) => {


        setloadingSave(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            AttributeID: params.id,
            ...formData
        };



        var api_config = {
            method: 'post',
            url: config.base_url + 'AttributeValue/AddAttributeValue',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                   
                    setIsAttributeSaved(true);
                    setloadingSave(false);
                  
                }
                else {
                   
                    setloadingSave(false);
                }
            })
            .catch(function (error) {

            });
    };


    // const columns = [
    //     {
    //         title: 'ID',
    //         dataIndex: 'ID',
    //         key: 'ID',
    //     },
    //     {
    //         title: 'Attribute Name',
    //         dataIndex: 'AttributeName',
    //         key: 'AttributeName',
    //     },

    //     {
    //         title: 'Value',
    //         dataIndex: 'Value',
    //         key: 'Value',
    //     },
    //     {
    //         title: 'Action',
    //         dataIndex: 'ID',
    //         key: 'ID',
    //         render: (_, record) => (
    //             <>
    //                 <Flex gap={10}>
    //                     <Popconfirm
    //                         placement="topRight"
    //                         title="Delete attribute?"
    //                         description="Are you sure to delete?"
    //                         onConfirm={(e) => DeleteItem(record.ID)}
    //                         okText="Yes"
    //                         cancelText="No"
    //                     >
    //                         <span className='circle-icon red' align={'middle'}>
    //                             <DeleteOutlined />
    //                         </span>
    //                     </Popconfirm>
    //                     <NavLink
    //                         onClick={() => showModal(record.ID)}
    //                         className='circle-icon blue'
    //                         align={'middle'}
    //                     >
    //                         <EditOutlined />
    //                     </NavLink>
    //                 </Flex>

    //             </>
    //         )
    //     },

    // ];

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };

    const DeleteItem = (ID) => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'AttributeValue/DeleteValue',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {

                // console.log(response.data);
                if (response.data.status_code == 1) {
                  
                    setIsAttributeSaved(true);
                    setLoading(false);
                }
                else {
                   
                    setLoading(false);
                }
            })
            .catch(function (error) {
               

            });
    }

    const onFinishUpdate = (formData) => {


        setloadingUpdate(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: AttributeID,
            AttributeID: params.id,
            ...formData
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'AttributeValue/UpdateAttributeValue',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                
                if (response.data.status_code == 1) {
                   
                    
                    handleCancel();
                    setAttributeID(0);
                    setAttribute({});
                    setIsAttributeSaved(true);
                    setloadingUpdate(false);
                    
                }
                else {
                   
                    setloadingUpdate(false);
                }
            })
            .catch(function (error) {

            });
    }


    return (
        <>
            
           

            <div>
                <h1>{Attribute.AttributeName}</h1>
                <div gutter={[24, 24]}>
                    <div xs={24} md={6}>
                        <div title="Add Value" bordered={false}>
                            <form
                            >

                                <div className="form-group">
                                    <input type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <button type="primary" htmlType="submit" block loading={loadingSave}>
                                        Save
                                    </button>
                                </div>


                            </form>
                        </div>
                    </div>
                    <div xs={24} md={18}>
                       
                    </div>
                </div>
            </div>

        </>
    );
}

export default ManageAttributesValues;