import React, { useEffect, useState, useRef } from 'react';
import QRCode from "react-qr-code";
import { Link, NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";
import moment from "moment";
import Config from '../../../Config';
import { NumberFormat } from '../../Dashboard/General/Functions';
import BsPagination from '../BsPagination';
import { ArrowDownTrayIcon, FaceFrownIcon } from '@heroicons/react/24/outline';
import HistoryMenu from './HistoryMenu';
import TransactionMenu from './TransactionMenu';
import LoginCheck from '../General/LoginCheck';
import Logo from "../../../assets/images/logo/mag.svg";
import stamp from "../../../assets/images/martinpay/stamp.svg";
import html2canvas from "html2canvas";
import { Dropdown } from 'react-bootstrap';

import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";


function TransferHistory(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    //const [transactions, setTransactions] = useState({});



    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [ledgerOld, setLedgerOld] = useState([]);
    const [isFilteredByDate, setisFilteredByDate] = useState(false);
    const [reciept, setReciept] = useState({});
    const [loadingRecordId, setLoadingRecordId] = useState(null);
    const [loadinginvoice, setloadinginvoice] = useState(false);
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [transfer, settransfer] = useState({});

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const HostUrl = window.location.origin + `/martinpay/transactions/funds-history/sent/img?id=${transfer.Payment_ID}`;

    useEffect(() => {

        setLoading(true);

        const Parms = {
            UserID: UserID,
            AccessKey: AccessKey,
            RequestType: "0",
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/BalanceTrasnferDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };



        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    setLedger(response.data.listofTransfers);
                    setLedgerOld(response.data.listofTransfers);
                    setTotalRecords(response.data.totalRecords);
                    setLoading(false);
                }


            })
            .catch(function (error) {
                console.log(error);
            });


    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    let amount = 0;
                    if (item.Debit != 0) {
                        amount = item.Debit;
                    }
                    else {
                        amount = item.Credit;
                    }

                    return (
                        <tr key={index}>
                            <td>{item.Payment_ID}</td>
                            <td>
                                {moment(item.Created_Date).format('DD/MM/YYYY')}
                            </td>
                            <td>{item.User_Name}</td>
                            <td>
                                {item.Beneficiary_Name}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                                PKR {NumberFormat(item.Transaction_Amount)}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                                PKR {NumberFormat(item.Commission_Amount)}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>PKR {NumberFormat(item.Receiveable_Amount)} </td>
                            <td>{item.Notes}</td>
                            <td className='text-center'>
                                {
                                    loadingRecordId == item.Payment_ID ?
                                        <Spinner animation="border" size="sm" />
                                        :
                                        <ArrowDownTrayIcon className='download' onClick={(e) => handleViewInvoice(item.ID)} />
                                }
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="9">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }


    }


    // const handleDownload = (PaymentID) => {
    //     // Use the fetch API to retrieve the PDF file
    //     setLoadingRecordId(PaymentID);
    //     let pdfUrl = Config.admin_path + "PDFReports/reportlister.aspx?reportname=transferinvoice&Transaction_ID=" + PaymentID;
    //     fetch(pdfUrl)
    //         .then((response) => response.blob()) // Convert the response to a Blob
    //         .then((blob) => {
    //             // Create a URL for the Blob data
    //             const url = window.URL.createObjectURL(blob);

    //             // Create a temporary <a> element to trigger the download
    //             const a = document.createElement('a');
    //             a.href = url;
    //             const currentDateTime = moment().format('YYYY-MM-DD-HH-mm-ss');
    //             a.download = `Transfer-${currentDateTime}.pdf`;
    //             document.body.appendChild(a);
    //             a.click();

    //             // Clean up the temporary elements and URL
    //             window.URL.revokeObjectURL(url);
    //             document.body.removeChild(a);
    //             setLoadingRecordId(null);
    //         })
    //         .catch((error) => {
    //             console.error('Error downloading PDF:', error);
    //         });
    // };


    const handleViewInvoice = (ID) => {
        setShow(true);
        setloadinginvoice(true);
        // console.log(ID);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            ID: ID,
        }

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/BalanceTrasnferByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    settransfer(response.data.BalanceTrasnfer);

                    setloadinginvoice(false);

                }


            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const InvoiceRef = useRef();

    const handleCapture = () => {
        if (InvoiceRef.current !== null) {
            html2canvas(InvoiceRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");

                // Create a link element
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "Transactions Invoice.jpg";

                // Append the link to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            });
        }
    };


    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <HistoryMenu />
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Transaction ID</th>
                                <th>Date</th>
                                <th>Sender</th>
                                <th>Beneficiary</th>
                                <th>Amount</th>
                                <th>Fee</th>
                                <th>Receivable Amount</th>
                                <th>Message</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&

                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Body className='p-0'>

                    {
                        loadinginvoice ?
                            <Spinner animation="border" size="sm" />
                            :
                            <div className="invoice-page" ref={InvoiceRef}>
                                <div className="subpage">
                                    <div className="invoice-top">
                                        <img src={Logo} />
                                        <div className="title">Transfer Invoice</div>
                                        <span>{moment(transfer.Created_Date).format('DD/MM/YYYY - HH:mma')}</span>
                                        <div className="qr-code-wrap">
                                            <QRCode
                                                size={256}
                                                value={HostUrl}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>
                                    <div className="invoice-content">
                                        <ul>
                                            <li>
                                                <label htmlFor="">Sender</label>
                                                <span>{transfer.User_Name}</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">Beneficiary</label>
                                                <span>{transfer.Beneficiary_Name}</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">Wallet Type</label>
                                                <span>MARTIN Wallet</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">Payment Mode</label>
                                                <span>Internal Transfer</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">TID</label>
                                                <span>{transfer.Payment_ID}</span>
                                            </li>
                                            <li>
                                                <label>Amount</label>
                                                <span>{transfer.Transaction_Amount} PKR</span>
                                            </li>
                                            <li>
                                                <label>Fee</label>
                                                <span>{transfer.Commission_Amount} PKR</span>
                                            </li>
                                            <li>
                                                <label>Receivable Amount</label>
                                                <span>{transfer.Receiveable_Amount} PKR</span>
                                            </li>
                                            <li>
                                                <img src={stamp} alt="mag_stamp" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <div className="button-row">

                        <Dropdown>
                            <Dropdown.Toggle variant="success" className='button button-primary' id="dropdown-basic">
                                Share
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/">
                                    <EmailShareButton url={HostUrl}>
                                        Email
                                    </EmailShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <FacebookShareButton url={HostUrl}>
                                        Facebook
                                    </FacebookShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <TwitterShareButton url={HostUrl}>
                                        Twitter
                                    </TwitterShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <WhatsappShareButton url={HostUrl}>
                                        Whatsapp
                                    </WhatsappShareButton>
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                       
                        <button className='button button-bs' onClick={handleCapture}>
                            Save to Gallery
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>


        </>
    );
}

export default TransferHistory;