import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowUpTrayIcon, ChatBubbleBottomCenterTextIcon, EnvelopeIcon, MapPinIcon, SquaresPlusIcon, PaperAirplaneIcon, PhoneIcon, TrashIcon, UserCircleIcon, ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import Config from '../../../Config';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import LoginCheck from '../General/LoginCheck';
import Flag from "../../../assets/images/flag-thumb.png";
import NIC_img from "../../../assets/images/martinpay/Nic-card.png";
import Passport_img from "../../../assets/images/martinpay/passport.png";
import Licence_img from "../../../assets/images/martinpay/License.png";
import Codes from './Codes';

const Kyc = () => {



    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
    const [country, setCountry] = useState({});
    const [countries, setCountries] = useState([]);
    const [countriesOld, setCountriesOld] = useState([]);
    const [countryName, setCountryName] = useState(null);
    const [InitialCountryFlag, setInitialCountryFlag] = useState("");
    const [loadingKyc, setLoadingKyc] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // kyc data
    const [FirstName, setFirstName] = useState(null);
    const [LastName, setLastName] = useState(null);
    const [FatherName, setFatherName] = useState(null);
    const [DateOfBirth, setDateOfBirth] = useState(null);
    const [CountryID, setCountryID] = useState(0);
    const [HasKyc, setHasKyc] = useState(false);
    const [CurrentStatus, setCurrentStatus] = useState("");
    const [finalCong, setFinalCong] = useState("");
    const [documentType, setDocumentType] = useState(null);
    const [MobileNo, setMobileNo] = useState("");
    const [CountyCode, setCountyCode] = useState("92");
    const [MaritalStatus, setMaritalStatus] = useState("Married");
    const [Gender, setGender] = useState("Male");
    const [Occupation, setOccupation] = useState("");
    const [Address, setAddress] = useState("");
    const [MemberInfo, setMemberInfo] = useState({});
    const [loadingUser, setLoadingUser] = useState(false);


    const [ShowIDExample, setShowIDExample] = useState(false);
    const [ShowPassportExample, setShowPassportExample] = useState(false);
    const [ShowLicenceExample, setShowLicenceExample] = useState(false);


    const handleMobileNo = (e) => {
        const value = e.target.value;
        const formattedValue = value.startsWith('0') ? value.substring(1) : value;
        const slicedValue = formattedValue.substring(0, 13);
        setMobileNo(slicedValue);
    };

    useEffect(() => {

        setLoadingKyc(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'MemberKYC/GetMemberKYC',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {


                    if (response.data.CurrentStatus === "Under Review") {
                        setLoadingKyc(false);
                        navigate("/martinpay/kyc/submitted");
                    }

                    else if (response.data.CurrentStatus === "Verified") {
                        setLoadingKyc(false);
                        navigate("/martinpay/kyc/congratulations");
                    }
                    else {
                        setLoadingKyc(false);
                    }
                }

                else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Request Parameters")
                ) {
                    navigate("/login");
                } else {
                    setLoadingKyc(false);
                    // toast.error(response.data.status_message, {
                    //   position: toast.POSITION.TOP_RIGHT,
                    // });
                }
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                    //onClose: () => window.alert('Called when I close')
                });
            });
    }, []);


    useEffect(() => {

        setLoadingUser(true);
        const Parms = {
            ID: UserID
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetMemberInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };


        axios(api_config)
            .then(function (response) {
                //console.log('GetMemberInfo',response.data);
                if (response.data.status_code == 1) {
                    setMemberInfo(response.data);
                    setLoadingUser(false);

                }
            })
            .catch(function (error) {
                console.log(error);
            });



    }, []);

    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    let navigate = useNavigate();



    //const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const MAX_IMAGE_SIZE = 4096 * 1024; // 2MB in bytes


    const [dragging, setDragging] = useState(false);
    const [dragFront, setdragFront] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };
    const handleDragfirst = (event) => {
        event.preventDefault();
        setdragFront(true);
    };

    const handleDropfirst = (event) => {

        event.preventDefault();
        setfrontSideSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {


            if (file.size > MAX_IMAGE_SIZE) {
                setfrontSideSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                setImgFront(e.target.result);
                if (backCropper) {
                    backCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const handleDrop = (event) => {

        event.preventDefault();
        setbackSideSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setbackSideSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            // console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImgBack(e.target.result);
                if (backCropper) {
                    backCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };


    // front side
    const [ImgFront, setImgFront] = useState("");
    const [frontSideSelected, setfrontSideSelected] = useState(false);
    const [frontCropper, setFrontCropper] = useState();

    const DeleteImgFront = () => {
        setImgFront("");
        setfrontSideSelected(false);
        setFrontCropper();
    }
    // back side
    const [ImgBack, setImgBack] = useState("");
    const [backSideSelected, setbackSideSelected] = useState(false);
    const [backCropper, setBackCropper] = useState();

    const DeleteImgBack = () => {
        setImgBack("");
        setbackSideSelected(false);
        setBackCropper();
    }

    // Passport
    const [ImgPassport, setImgPassport] = useState("");
    const [PassportSideSelected, setPassportSideSelected] = useState(false);
    const [PassportCropper, setPassportCropper] = useState();

    const DeleteImgPassport = () => {
        setImgPassport("");
        setPassportSideSelected(false);
        setPassportCropper();
    }

    // Licence Front
    const [ImgLicenceFront, setImgLicenceFront] = useState("");
    const [LicenceFrontSideSelected, setLicenceFrontSideSelected] = useState(false);
    const [LicenceFrontCropper, setLicenceFrontCropper] = useState();

    const DeleteLicenceFrontSide = () => {
        setLicenceFrontSideSelected(false);
        setLicenceFrontCropper();
        setImgLicenceFront("")
    }

    // back side
    const [ImgLicenceBack, setImgLicenceBack] = useState("");
    const [LicenceBackSideSelected, setLicenceBackSideSelected] = useState(false);
    const [LicenceBackCropper, setLicenceBackCropper] = useState();

    const DeleteImgLicenceBack = () => {
        setImgLicenceBack("");
        setLicenceBackSideSelected(false);
        setLicenceBackCropper();
    }

    const onChangeFront = (e) => {
        e.preventDefault();

        setfrontSideSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];

        console.log('file size', file.size);
        console.log('allowed size', MAX_IMAGE_SIZE);


        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setfrontSideSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2), { position: toast.POSITION.TOP_RIGHT, });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {

            setImgFront(reader.result);



        };
        reader.readAsDataURL(files[0]);
    }

    const onChangeBack = (e) => {
        e.preventDefault();
        setbackSideSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setbackSideSelected(false);
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }


        const reader = new FileReader();
        reader.onload = () => {
            setImgBack(reader.result);


        };
        reader.readAsDataURL(files[0]);
    }


    const onChangePassport = (e) => {
        e.preventDefault();

        setPassportSideSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setPassportSideSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2
            ), {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImgPassport(reader.result);
        };
        reader.readAsDataURL(files[0]);
    }
    const onChangeFronLicence = (e) => {
        e.preventDefault();

        setLicenceFrontSideSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setLicenceFrontSideSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2
            ), {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImgLicenceFront(reader.result);
        };
        reader.readAsDataURL(files[0]);
    }
    const onChangeBackLicence = (e) => {
        e.preventDefault();

        setLicenceBackSideSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setLicenceBackSideSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2
            ), {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImgLicenceBack(reader.result);
        };
        reader.readAsDataURL(files[0]);
    }




    // function getImageTypeFromBase64(base64String) {
    //     // Extract image type from base64 string
    //     const typeRegex = /^data:image\/(jpeg|png);base64,/;
    //     const match = base64String.match(typeRegex);

    //     if (match && match[1]) {
    //         return match[1];
    //     } else {
    //         return null;
    //     }
    // }

    // function getSubstringIndex(imageType) {
    //     // Adjust substring index based on image type
    //     return (imageType === 'jpeg') ? 23 : 22;
    // }


    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                KYCType: 'Standard',
                BirthCountry: countryName,
                BirthCountryID: country.ID,
                DocumentType: documentType,
                FirstName: FirstName,
                LastName: LastName,
                DateOfBirth: DateOfBirth,
                Gender: Gender,
                MaritalStatus: MaritalStatus,
                Occupation: Occupation,
                Address: Address,
                MobileNo: '+' + CountyCode + MobileNo,
                FatherName: FatherName
            };



            if (documentType == null) {
                toast.error('Please choose document type and upload documents.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }


            if (documentType === 'ID') {

                if (ImgFront == "" || ImgFront == null) {
                    toast.error('Please select Front side of CNIC', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                    return;
                }
                else if (ImgBack == "" || ImgBack == null) {
                    toast.error('Please select Back side of CNIC', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                    return;
                }
                else {

                    var frontCropper1 = frontCropper.getCroppedCanvas().toDataURL();
                    var backCropper2 = backCropper.getCroppedCanvas().toDataURL();
                    data.IDFront = frontCropper1.substring(22);
                    data.IDBack = backCropper2.substring(22);


                }

            }

            if (documentType === 'Passport') {


                if (ImgPassport == "" || ImgPassport == null) {
                    toast.error('Please select Passport to Upload', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                    return;
                }
                else {

                    var passportCropper = PassportCropper.getCroppedCanvas().toDataURL();
                    data.Passport = passportCropper.substring(22);
                }
            }
            if (documentType === 'License') {


                if (ImgLicenceFront == "" || ImgLicenceFront == null) {
                    toast.error('Please select Front side of License', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                    return;
                }
                else if (ImgLicenceFront == "" || ImgLicenceFront == null) {
                    toast.error('Please select Back side of License', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                    return;
                }
                else {
                    var LicenseFrontCropper = LicenceFrontCropper.getCroppedCanvas().toDataURL();
                    var LicenseBackCropper = LicenceBackCropper.getCroppedCanvas().toDataURL();

                    data.LicenseFront = LicenseFrontCropper.substring(22);
                    data.LicenseBack = LicenseBackCropper.substring(22);
                }

            }
            // console.log(data);
            var api_config = {
                method: "post",
                url: Config.base_url + "MemberKYC/AddMemberKYC",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(api_config)
                .then(function (response) {
                    // console.log('response', response.data);

                    if (response.data.CurrentStatus === "Under Review") {

                        toast.success('KYC Already In Process', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate('/martinpay/kyc/submitted');

                    }
                    else if (response.data.CurrentStatus === "Approved") {
                        toast.error('KYC Already In Process', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate('/martinpay/kyc/congratulations');
                    }
                    else if (response.data.status_code === 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate('/martinpay/kyc/submitted');
                    }

                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    // console.log(error);
                    toast.error("Network Error..", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
        setValidated(true);
    };
    useEffect(() => {


        const data = {
            Email_Address: Email
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Country/GetCountryList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setCountries(response.data.listofCountries);
                setCountriesOld(response.data.listofCountries);


            })
            .catch(function (error) {
                console.log(error);
            });


    }, []);



    const renderCountries = () => {
        if (countries != "") {
            return (
                countries.map((item) => {
                    return (
                        <li key={item.ID}
                            onClick={(e) => handleCountry(e, item)}>
                            <img src={`${Config.root}images/flags/${item.Flage_Image_Name}`} className="flag" /> {item.Country_Name}
                        </li>
                    )
                })
            )
        }
    }

    const handleCountry = (e, item) => {

        // console.log(item);
        setCountry(item);
        setCountryName(item.Country_Name);
        setShow(false);
        setCountries(countriesOld);


    }
    const getFlagImageByName = (countryName) => {
        const country = countries.find(
            (country) => country.Country_Name === countryName
        );
        if (country && country.Flage_Image_Name) {
            return `${Config.root}images/flags/${country.Flage_Image_Name}`;
        } else {
            return Flag; // Replace with your default image path
        }
    };
    const filterCountries = (e) => {
        setCountryName(e.target.value);
        let value = e.target.value;
        const filteredCountryList = countries.filter((country) =>
            country.Country_Name.toLowerCase().includes(value.toLowerCase())
        );

        if (value == "") {
            setCountries(countriesOld);
            setCountryName(null);
        }
        else {
            setCountries(filteredCountryList);
        }

    }

    useEffect(() => {
        document.addEventListener("click", ClickOutsideOfSearchbox);
    }, []);


    const ClickOutsideOfSearchbox = (event) => {

        const innerDiv = document.getElementById("countries-box");

        if(innerDiv) {
            if (!innerDiv.contains(event.target)) {
                setShow(false);
            }
        }
       


       
    }


    const handleDocumentType = (e) => {
        let type = e.target.value;

        setDocumentType(e.target.value);
        if (type == "ID") {
            DeleteImgPassport();
            DeleteImgLicenceBack();
            DeleteLicenceFrontSide();

        }
        else if (type == "Passport") {
            DeleteImgFront();
            DeleteImgBack();
            DeleteImgLicenceBack();
            DeleteLicenceFrontSide();
        }
        else if (type == "License") {
            DeleteImgFront();
            DeleteImgBack();
            DeleteImgPassport();
        }
    }



    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <Modal className='bs-modal example-model' show={ShowIDExample} onHide={() => setShowIDExample(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>NIC Example</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={NIC_img} alt="" />
                </Modal.Body>
            </Modal>
            <Modal className='bs-modal example-model' show={ShowPassportExample} onHide={() => setShowPassportExample(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Passport Example</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={Passport_img} alt="" />
                </Modal.Body>
            </Modal>
            <Modal className='bs-modal example-model' show={ShowLicenceExample} onHide={() => setShowLicenceExample(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Licence Example</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={Licence_img} alt="" />
                </Modal.Body>
            </Modal>
            <div className="section-title mb-3">KYC (Know your client)</div>
            <div className="dash-bg-card kyc-section">
                <div className="heading">
                    KYC Details
                </div>
                {
                    loadingKyc ?
                        <div className="d-flex justify-content-center" style={{ marginTop: "20%" }}>
                            <Spinner className="Spinner" animation="border" variant="primary" />
                        </div>
                        :

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className='row align-items-center'>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">choose Document Type</label>
                                        <div className="icon-input">
                                            <select
                                                id="dropdown"
                                                className="form-select"
                                                value={documentType}
                                                onChange={handleDocumentType}
                                            >
                                                <option value="">Select Document</option>
                                                <option value="ID">ID</option>
                                                <option value="Passport">Passport</option>
                                                <option value="License">License</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">KYC Type</label>
                                        <div className="icon-input">
                                            <input type="text" disabled name="" readOnly value={'Standard'} id="" />
                                            <div className="icon">
                                                <ChatBubbleBottomCenterTextIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Username</label>
                                        <div className="icon-input">
                                            <input
                                                name='Username'
                                                type="text"
                                                placeholder='newyork718'
                                                className="select-item"
                                                value={loadingUser ? 'Loading...' : MemberInfo.User_Name}
                                                disabled
                                                required
                                            />
                                            <div className="icon">
                                                <UserCircleIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Email</label>
                                        <div className="icon-input">
                                            <input
                                                type="email"
                                                name='email'
                                                placeholder='Enter Here'
                                                className="select-item"
                                                value={Email}
                                                disabled
                                                required
                                            />
                                            <div className="icon">
                                                <EnvelopeIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ul className='kyc-info-list mt-3'>
                                    <li><b>User’s Documents</b></li>
                                    <li><span>NIC or Passport is Mandatory</span></li>
                                    <li><p>File format JPEG or PNG, Maximum file size is 5MB</p></li>
                                </ul>

                                <div className="col-md-12">
                                    <ul className="kyc-upload">
                                        <li className="">
                                            <div className="examle">
                                                <label htmlFor="">Upload NIC</label>
                                                <span onClick={() => setShowIDExample(true)}>Show Example</span>
                                            </div>
                                            <div className={`kyc-img-card white mb-3 ${documentType != "ID" && 'opacity-50 cursor_none'}`}>
                                                <div className="card-inner">
                                                    <div className={`upload-box ${dragFront ? 'dragFront' : ''}`}
                                                        id="drop-area"
                                                        onDragOver={handleDragfirst}
                                                        onDrop={handleDropfirst}
                                                    >
                                                        {
                                                            documentType == "ID" &&
                                                            <input id="ChoosePhotoFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFront} />
                                                        }

                                                        {
                                                            frontSideSelected ?
                                                                <>
                                                                    <Cropper
                                                                        style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                        zoomTo={0}
                                                                        preview=".img-preview"
                                                                        src={ImgFront}
                                                                        dragMode="move"
                                                                        viewMode={2}
                                                                        minCropBoxHeight={10}
                                                                        minCropBoxWidth={10}
                                                                        background={false}
                                                                        responsive={true}
                                                                        autoCropArea={1}
                                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                        onInitialized={(instance) => {
                                                                            setFrontCropper(instance);
                                                                        }}
                                                                        guides={true}
                                                                    />
                                                                    <div className="delete" onClick={DeleteImgFront}>
                                                                        <TrashIcon />
                                                                    </div>
                                                                </>
                                                                :
                                                                <label htmlFor="ChoosePhotoFront" className='thumnail'>
                                                                    <ArrowUpTrayIcon />
                                                                    <p>Upload Front Side</p>
                                                                </label>

                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`kyc-img-card white ${documentType != "ID" && 'opacity-50 cursor_none'}`}>
                                                <div className="card-inner">
                                                    <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                                        id="drop-area"
                                                        onDragOver={handleDragOver}
                                                        onDrop={handleDrop}
                                                    >
                                                        {
                                                            documentType == "ID" &&
                                                            <input id="ChoosePhotoBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBack} />
                                                        }

                                                        {
                                                            backSideSelected ?
                                                                <>
                                                                    <Cropper
                                                                        style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                        zoomTo={0}
                                                                        preview=".img-preview"
                                                                        src={ImgBack}
                                                                        dragMode="move"
                                                                        viewMode={2}
                                                                        minCropBoxHeight={10}
                                                                        minCropBoxWidth={10}
                                                                        background={false}
                                                                        responsive={true}
                                                                        autoCropArea={1}
                                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                        onInitialized={(instance) => {
                                                                            setBackCropper(instance);
                                                                        }}
                                                                        guides={true}
                                                                    />
                                                                    <div className="delete" onClick={DeleteImgBack}>
                                                                        <TrashIcon />
                                                                    </div>
                                                                </>
                                                                :
                                                                <label htmlFor="ChoosePhotoBack" className='thumnail'>
                                                                    <ArrowUpTrayIcon />
                                                                    <p>Upload Back Side</p>
                                                                </label>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="examle">
                                                <label htmlFor="">Upload Passport</label>
                                                <span onClick={() => setShowPassportExample(true)}>Show Example</span>
                                            </div>
                                            <div className={`kyc-img-card pink ${documentType != "Passport" && 'opacity-50 cursor_none'}`}>
                                                <div className="card-inner">
                                                    <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                                        id="drop-area"
                                                        onDragOver={handleDragOver}
                                                        onDrop={handleDrop}
                                                    >
                                                        {
                                                            documentType == "Passport" &&
                                                            <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChangePassport} />
                                                        }


                                                        {
                                                            PassportSideSelected ?
                                                                <>
                                                                    <Cropper
                                                                        style={{ width: '402px', minHeight: '456px;', marginTop: '-10px' }}
                                                                        zoomTo={0}
                                                                        preview=".img-preview"
                                                                        src={ImgPassport}
                                                                        dragMode="move"
                                                                        viewMode={2}
                                                                        minCropBoxHeight={10}
                                                                        minCropBoxWidth={10}
                                                                        background={false}
                                                                        responsive={true}
                                                                        autoCropArea={1}
                                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                        onInitialized={(instance) => {
                                                                            setPassportCropper(instance);
                                                                        }}
                                                                        guides={true}
                                                                    />
                                                                    <div className="delete" onClick={DeleteImgPassport}>
                                                                        <TrashIcon />
                                                                    </div>
                                                                </>
                                                                :
                                                                <label htmlFor="ChoosePassportimage" className='thumnail'>
                                                                    <ViewfinderCircleIcon />
                                                                    <p>Upload Front Side</p>
                                                                </label>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="">
                                            <div className="examle">
                                                <label htmlFor="">Upload License</label>
                                                <span onClick={() => setShowLicenceExample(true)}>Show Example</span>
                                            </div>
                                            <div className={`kyc-img-card blue mb-3 ${documentType != "License" && 'opacity-50 cursor_none'}`}>
                                                <div className="card-inner">
                                                    <div className={`upload-box ${dragFront ? 'dragFront' : ''} ${documentType != "License" && 'bg-opacity-50'} `}
                                                        id="drop-area"
                                                        onDragOver={handleDragfirst}
                                                        onDrop={handleDropfirst}
                                                    >
                                                        {
                                                            documentType == "License" &&
                                                            <input id="ChooseLicenceFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFronLicence} />
                                                        }


                                                        {
                                                            LicenceFrontSideSelected ?
                                                                <>
                                                                    <Cropper
                                                                        style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                        zoomTo={0}
                                                                        preview=".img-preview"
                                                                        src={ImgLicenceFront}
                                                                        dragMode="move"
                                                                        viewMode={2}
                                                                        minCropBoxHeight={10}
                                                                        minCropBoxWidth={10}
                                                                        background={false}
                                                                        responsive={true}
                                                                        autoCropArea={1}
                                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                        onInitialized={(instance) => {
                                                                            setLicenceFrontCropper(instance);
                                                                        }}
                                                                        guides={true}
                                                                    />
                                                                    <div className="delete" onClick={DeleteLicenceFrontSide}>
                                                                        <TrashIcon />
                                                                    </div>
                                                                </>
                                                                :
                                                                <label htmlFor="ChooseLicenceFront" className='thumnail'>
                                                                    <ArrowUpTrayIcon />
                                                                    <p>Upload Front Side</p>
                                                                </label>

                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`kyc-img-card blue  ${documentType != "License" && 'opacity-50 cursor_none'}`}>
                                                <div className="card-inner">
                                                    <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                                        id="drop-area"
                                                        onDragOver={handleDragOver}
                                                        onDrop={handleDrop}
                                                    >
                                                        {
                                                            documentType == "License" &&
                                                            <input id="ChooseLicenceBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBackLicence} />
                                                        }


                                                        {
                                                            LicenceBackSideSelected ?
                                                                <>
                                                                    <Cropper
                                                                        style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                        zoomTo={0}
                                                                        preview=".img-preview"
                                                                        src={ImgLicenceBack}
                                                                        dragMode="move"
                                                                        viewMode={2}
                                                                        minCropBoxHeight={10}
                                                                        minCropBoxWidth={10}
                                                                        background={false}
                                                                        responsive={true}
                                                                        autoCropArea={1}
                                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                        onInitialized={(instance) => {
                                                                            setLicenceBackCropper(instance);
                                                                        }}
                                                                        guides={true}
                                                                    />
                                                                    <div className="delete" onClick={DeleteImgLicenceBack}>
                                                                        <TrashIcon />
                                                                    </div>
                                                                </>
                                                                :
                                                                <label htmlFor="ChooseLicenceBack" className='thumnail'>
                                                                    <ArrowUpTrayIcon />
                                                                    <p>Upload Back Side</p>
                                                                </label>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="heading my-3">
                                    User Profile Details
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">First name</label>
                                        <div className="icon-input">
                                            <input
                                                value={FirstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                type="text"
                                                placeholder='Enter Here'
                                                className=""
                                                required
                                            />
                                            <div className="icon">
                                                <UserCircleIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Last name</label>
                                        <div className="icon-input">
                                            <input
                                                value={LastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                type="text"
                                                placeholder='Enter Here'
                                                className=""
                                                required
                                            />
                                            <div className="icon">
                                                <UserCircleIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Father name</label>
                                        <div className="icon-input">
                                            <input
                                                value={FatherName}
                                                onChange={(e) => setFatherName(e.target.value)}
                                                type="text"
                                                placeholder='Enter Here'
                                                className=""
                                                required
                                            />
                                            <div className="icon">
                                                <UserCircleIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Country</label>
                                        <div className="icon-input">
                                            <input type="text" id="countries-box"
                                                className="flag-input"
                                                onFocus={handleShow}
                                                value={countryName}
                                                onChange={(e) => filterCountries(e)}
                                                placeholder="Select Country"
                                            />

                                            {
                                                countryName != null && (
                                                    <img
                                                        src={getFlagImageByName(countryName)}
                                                        onError={(e) => {
                                                            e.target.src = Flag;
                                                        }}
                                                    />
                                                )
                                            }

                                            <div className="icon">
                                                {
                                                    show == true ?
                                                        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 -3.98606e-07L0 7.63251L1.43333 9L8 2.70318L14.5667 8.9682L16 7.60071L8 -3.98606e-07Z" fill="#727272" />
                                                        </svg>

                                                        :
                                                        <svg width="16" height="9" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 14.15L0 2.15L2.15 0L12 9.9L21.85 0.05L24 2.2L12 14.15Z" fill="#727272" />
                                                        </svg>
                                                }

                                            </div>

                                            {
                                                show == true &&
                                                <div className="card-gradient card_list_of_countries" style={{ top: "51px" }}>
                                                    <div className="card-inner p-3">
                                                        <ul className="countries_list_offcanvas">
                                                            {renderCountries()}
                                                        </ul>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Date of Birth</label>
                                        <div className="icon-input">
                                            <input
                                                type="date"
                                                required
                                                max="9999-12-31"
                                                value={DateOfBirth}
                                                onChange={(e) => setDateOfBirth(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Gender</label>
                                        <div className="icon-input">
                                            <select
                                                id="dropdown"
                                                className="form-select"
                                                value={Gender}
                                                onChange={(e) => setGender(e.target.value)}
                                            >
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Marital Status </label>
                                        <div className="icon-input">
                                            <select
                                                id="dropdown"
                                                className="form-select"
                                                value={MaritalStatus}
                                                onChange={(e) => setMaritalStatus(e.target.value)}
                                            >
                                                <option value="Married">Married</option>
                                                <option value="Unmarried">Unmarried</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Occupation</label>
                                        <div className="icon-input">
                                            <input
                                                value={Occupation}
                                                onChange={(e) => setOccupation(e.target.value)}
                                                type="text"
                                                placeholder='Enter Here'
                                                className=""
                                                required
                                            />
                                            <div className="icon">
                                                <SquaresPlusIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Address</label>
                                        <div className="icon-input">
                                            <input
                                                value={Address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                type="text"
                                                placeholder='Enter Here'
                                                className=""
                                                required
                                            />
                                            <div className="icon">
                                                <MapPinIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="icon-form-group">
                                        <label>Phone</label>
                                        <div className="d-flex">
                                            <div className="icon-input" style={{ width: "176px" }}>
                                                <Codes
                                                    handleCodeChange={(e) => setCountyCode(e.target.value)} />
                                            </div>
                                            <div className="icon-input">
                                                <input type="number"
                                                    placeholder="xxx xxx xxxx"
                                                    className=""
                                                    onChange={handleMobileNo}
                                                    value={MobileNo}
                                                    required
                                                    maxLength={"13"}
                                                />
                                                <div className="icon">
                                                    <PhoneIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <button type="submit"
                                        disabled={loading}
                                        className="button button-bs button-icon-bs justify-content-center w-100 mt-2 input-height">
                                        Submit {loading ? '...' : ''}<span><PaperAirplaneIcon /></span>
                                    </button>
                                </div>
                            </div>
                        </Form >
                }
            </div >
        </>
    )
}

export default Kyc