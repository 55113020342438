import React, { useEffect, useState } from 'react'
import config from '../../../Config';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { RenderHTMLStringWithoutDiv } from '../../Common/Util';
import Spinner from "react-bootstrap/Spinner";
import { Modal } from 'react-bootstrap';



const ManageProduct = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));



    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [loading, setLoading] = useState(false);
    const [IsDelect, setIsDelect] = useState(false);
    const [listOfTransactions, setlistOfTransactions] = useState([]);


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };
        // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Product/GetAllProducts',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setlistOfTransactions(response.data.ListofLedger);
                    setTotalRecords(response.data.totalRecords);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }


            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize, IsDelect]);



    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };


    const DelectItem = (ID) => {

        setIsDelect(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Product/DeleteProduct',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {

                    setIsDelect(false);
                }
                else {

                    setIsDelect(false);
                }
            })
            .catch(function (error) {


            });
    };


    const [showModalAttachment, setShowModalAttachment] = useState(false);
    const [attachmentImg, setAttachmentImg] = useState(null);
    const [CategoryName, setCategoryName] = useState(false);
    const handleShowModalAttachment = (item, name) => {
        setAttachmentImg(item);
        setCategoryName(name);
        setShowModalAttachment(true);
    }

    const handleCloseModalAttachment = () => {
        setShowModalAttachment(false);

    }


    return (
        <>

            <Modal
                className="modal-theme modal-xs"
                show={showModalAttachment}
                onHide={handleCloseModalAttachment}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{CategoryName}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <img
                        src={`${config.path}Images/Products/${attachmentImg}`}
                        className="img-fluid"
                        alt="Attachment"
                    />
                </Modal.Body>
            </Modal>

            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Manage Products
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to={`/admin/product/add`} className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                Add Product
                                <i className="ri-add-line ms-3"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>Category Name</th>

                                    <th>Buying Price</th>
                                    <th>Selling Price</th>
                                    <th>Discount</th>
                                    <th>IsNew</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ?
                                    (
                                        <tr>
                                            <td className="text-center" colSpan="9">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                listOfTransactions.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.ID}</td>
                                                        <td>
                                                            <img className='list-img' onClick={(e, name) => handleShowModalAttachment(item.SmallImage, item.Name)} src={`${config.path}Images/Products/${item.SmallImage}`} alt={item.SmallImage} />
                                                        </td>
                                                        <td>{item.Name}</td>
                                                        <td>{item.CategoryName}</td>
                                                        <td>{item.BuyingPrice}</td>
                                                        <td>{(item.SellingPrice)}</td>
                                                        <td>{(item.Discount)}</td>
                                                        <td>
                                                            <input class="form-check-input" defaultChecked={(item.IsNew)} type="checkbox" disabled />
                                                        </td>
                                                        <td>
                                                            <ul
                                                                className="inline-action"
                                                                aria-labelledby="dropdownMenuButton1"
                                                            >
                                                                <li>
                                                                    <NavLink
                                                                        to={"/admin/product/update-product/" + item.ID}
                                                                        className="primary "
                                                                    >
                                                                        <i className="ri-edit-line"></i>
                                                                    </NavLink>
                                                                </li>
                                                                <li><button className="red" onClick={(e) => DelectItem(item.ID)} type="button"><i className="ri-delete-bin-line"></i></button></li>
                                                                <li>
                                                                    <NavLink
                                                                        to={"/admin/product/add-images/" + item.ID}
                                                                        className="primary "
                                                                    >
                                                                        <i className="ri-image-line"></i>
                                                                    </NavLink>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ManageProduct;