import { FingerPrintIcon, LockClosedIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { NavLink } from 'react-router-dom';

function ProfileMenu(props) {
    return (
        <>
            <div className="section-title mb-3">
                Settings
            </div>
            <div className='active-link-menu'>
                <ul className="nav">
                    <li className='nav-item'>
                        <NavLink className="nav-link" to="/martinpay/profile/account">
                            <UserCircleIcon />
                            <span>
                                Profile
                            </span>
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink className="nav-link" to="/martinpay/profile/security">
                            <LockClosedIcon />
                            <span>
                                Security
                            </span>
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink className="nav-link" to="/martinpay/profile-kyc">
                            <FingerPrintIcon />
                            <span>
                                KYC
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default ProfileMenu;