import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../../Config';
import LoginCheck from '../General/LoginCheck';

function CoinCancel(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));


    useEffect(() => {

        document.title = "Coin Payment Cancel";
    }, []);


    return (
        <>
            <LoginCheck />
            <div className='row'>
                <div className='col-md-4 offset-md-4'>
                    <div className='card card_lg'>
                    <h4>Payment has been cancelled.</h4>
                    <p>
                        We received your cancellation notice. If you have any issues or concerns, please contact our support team for assistance.
                    </p>
                        <NavLink to="/martinpay/transactions/deposits">View all requests</NavLink>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CoinCancel;