import React, { useState, useEffect } from "react";
import logo from '../../assets/images/logo/mag.svg';
import background from '../../assets/images/martinpay/login-bg.png';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, useLocation } from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import ImgEmailVerified from "../../assets/images/email-verified.svg";
import ImgEmailFailed from "../../assets/images/emailfailed.svg";
import { ArrowLeftEndOnRectangleIcon, LinkIcon } from "@heroicons/react/24/outline";


function VerifyEmail(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [UserName1, setUserName1] = useState(localStorage.getItem("User_Name"));
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const SecurityCode = searchParams.get("SecurityCode");
    const UserName = searchParams.get("UserName");
    const ExpiryDate = searchParams.get("ExpiryDate");
    const email = searchParams.get(null);
    const [IsVerified, setIsVerified] = useState(false);


    useEffect(() => {

        if (email) {

            setIsVerified(false);
        }
        else {
            setLoading(true);
            const data = {
                // UserID: UserID,
                // AccessKey: AccessKey,
                SecurityCode: SecurityCode,
                UserName: UserName,
                ExpiryDate: ExpiryDate
            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/VerifyEmailAddress',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    //console.log(response.data);

                    if (response.data.status_code == 1) {
                        setIsVerified(true);
                        setLoading(false);
                        //navigate('/login');
                    }
                    else {

                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        setIsVerified(false);
                        setLoading(false);
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }

    }, []);

    const ResendEmail = () => {
        const data = {

            UserName: UserName1,

        };

        //   console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/SendSignUpEmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data);
        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else {

                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }



    return (
        <>
            <ToastContainer />
            <section className='business-signin-form login-page'>
                <img src={background} />
                <div className='container'>
                    {
                        loading ?

                            <Spinner animation="border" role="status"></Spinner>

                            :
                            <>
                                {
                                    IsVerified ?
                                        <div className="login_box">
                                            <div className="login_box_head">
                                                <img src={logo} />
                                            </div>
                                            <div className="login_box_body text-center">
                                                <img src={ImgEmailVerified} style={{ maxWidth: "100px" }} />
                                                <h4>Email Verified!</h4>
                                                <p>Congratulations!<br />Your email verification is complete! Welcome to the MAG Ventures.</p>
                                                <button type="submit" className="button button-bs w-100"
                                                    onClick={ResendEmail}
                                                >
                                                    Login Now {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="login_box">
                                            <div className="login_box_head">
                                                <img src={logo} />
                                            </div>
                                            <div className="login_box_body text-center">
                                                <img src={ImgEmailFailed} style={{ maxWidth: "110px" }} />
                                                <h4>Email Verification Failed!</h4>
                                                <p>Unfortunately, your email verification is not complete. Please try again now.</p>
                                                <button type="submit" className="button button-bs w-100"
                                                    onClick={ResendEmail}
                                                >
                                                    Resend Verify Link {loading ? '...' : ''}<span><LinkIcon /></span>
                                                </button>
                                            </div>
                                        </div>
                                }
                            </>

                    }
                </div>
            </section>
        </>
    );
}

export default VerifyEmail;