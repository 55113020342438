import React from 'react'
import ProfileMenu from './ProfileMenu'
import { NavLink } from 'react-router-dom'
import LoginCheck from '../General/LoginCheck'

const ProfileKyc = () => {
    return (
        <>
            <LoginCheck />
            <ProfileMenu />
            <div className="profile-bg-card">
                <div className="row pt-3">
                    <div className="col-md-5 offset-md-3">
                        <div className="kyc-box">
                            <div className="ribbon">
                                <span className="ribbon-text">KYC-Approved</span>
                                <div className="ribbon-corner"></div>
                            </div>
                            <p>
                                Congratulations! You are a verified member of Martinpay. You can now use MAG’s resources to reach your financial goals.
                            </p>
                            <NavLink to={`/martinpay/kyc`}>VIEW DETAILS</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileKyc