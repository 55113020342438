import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import config from "../../../Config";
import UserGroupDropDown from "./UserGroupDropDown";
import AdminRightsCheck from "../AdminRightsCheck";

import {
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";


const AddUser = () => {

  let navigate = useNavigate()

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState('');
  const [fullName, setfullName] = useState();
  const [password, setpassword] = useState();
  const [retype_password, setretype_password] = useState();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [disabled, setDisabled] = useState(true);

  const [PasswordShow, setPasswordShow] = useState(true);
  const [RetypePasswordShow, setRetypePasswordShow] = useState(true);
  const [PinCode, setPinCode] = useState('');
  const [PinCodeShow, setPinCodeShow] = useState(true);

  const [group, setgroup] = useState();
  document.title = "Martinpay Admin";
  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Email_Address: email,
      Full_Name: fullName,
      Password: password,
      Group_ID: group,
      PinCode: PinCode,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Security/AddUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    if (!isValidEmail || !passwordsMatch) {

      toast.error("Wrong email pattren entered!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      axios(api_config)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setTimeout(() => {
              navigate("/admin/security-dashboard/manage-users")
            }, 2000)
          }
          else if (response.data.status_code == 2 && response.data.status_message == "Invalid Request Parameters") {

            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          else if (response.data.status_code == 0 && response.data.status_message == "User Name Already Exists.") {

            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }


    // setValidated(true);
  };


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setpassword(newPassword);
    setPasswordsMatch(newPassword === retype_password);
    setDisabled(newPassword !== retype_password);

  };

  const handleRetypePasswordChange = (e) => {
    const newRetypedPassword = e.target.value;
    setretype_password(newRetypedPassword);
    setPasswordsMatch(password === newRetypedPassword);
    setDisabled(newRetypedPassword !== password);
  };

  const validateEmail = (email) => {
    // You can implement your own email validation logic here
    // For simplicity, let's use a basic regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };
  const checkEmail = () => {
    setIsValidEmail(validateEmail(email));
  }

  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">
        <div className="row align-items-center mb-3 gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Add New User
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <NavLink to="/admin/security-dashboard/manage-users">
              <button type="button" className="button button-outline-bs">
                Back
              </button>
            </NavLink>
          </div>
        </div>

        <div className="card card-table form_default">

          <form onSubmit={handleSubmit} >
            <div className="card-inner form-group p-4">
              <div className="row align-items-center gy-3">
                <div className="col-md-3">
                  <h6 className="">Full Name</h6>
                  <div className="inputwrap">
                    <input
                      type="text"
                      // style={{ width: "120px" }}
                      className="form-control w-100"
                      // value={fullName}
                      onChange={(e) => setfullName(e.target.value)}
                    />
                  </div>
                </div>


                <div className="col-md-3">
                  <h6 className="">Email Address</h6>
                  <div className="inputwrap">
                    <input
                      type="email"
                      className="form-control w-100"
                      // defaultValue={User.Email_Address}
                      onChange={handleEmailChange}
                      onBlur={checkEmail}
                      required
                      autocomplete="off"

                    />

                  </div>
                  {!isValidEmail && <p>Invalid email address</p>}
                </div>
                <div className="col-md-3">
                  <div className="icon-form-group">
                    <label className="">Password</label>
                    <div className="icon-input">
                      <input
                        type={PasswordShow ? "text" : "password"}
                        className="form-control w-100"
                        defaultValue={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      <div className="icon" onClick={() => setPasswordShow(!PasswordShow)}>
                        {PasswordShow ? <EyeSlashIcon /> : <EyeIcon />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-form-group">
                    <label className="">Retype Password</label>
                    <div className="icon-input">

                      <input
                        type={RetypePasswordShow ? "text" : "password"}
                        className="form-control w-100"
                        defaultValue={retype_password}
                        onChange={handleRetypePasswordChange}
                        required
                      />
                      <div className="icon" onClick={() => setRetypePasswordShow(!RetypePasswordShow)}>
                        {RetypePasswordShow ? <EyeSlashIcon /> : <EyeIcon />}
                      </div>
                    </div>
                    {!passwordsMatch && <div className="mt-1">Passwords do not match</div>}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="icon-form-group">
                    <label className="">Pin Code</label>
                    <div className="icon-input">
                      <input
                        type={PinCodeShow ? "text" : "password"}
                        className="form-control w-100"
                        // defaultValue={password}
                        onChange={(e) => setPinCode(e.target.value)}
                        value={PinCode}
                        required
                      />
                      <div className="icon" onClick={() => setPinCodeShow(!PinCodeShow)}>
                        {PinCodeShow ? <EyeSlashIcon /> : <EyeIcon />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                <div className="icon-form-group">
                    <label className="">Select User Group</label>
                    <div className="icon-input">
                    <select
                      id="dropdown"
                      className="form-select w-100"
                      required
                      onChange={(e) => setgroup(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <UserGroupDropDown />
                    </select>
                  </div>
                  </div>
                </div>
                <div className="col-md-3 text-end offset-md-3">
                  <button
                    type="submit"
                    className="button button-primary w-100 input-height input-radius mt-md-3"
                    disabled={disabled || loading}
                  >
                    Add {loading ? '...' : ''}
                  </button>
                </div>
              </div>
            </div>
          </form >

        </div >
      </div >
    </>
  );
};

export default AddUser;
