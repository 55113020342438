import React from 'react'
import facebook from '../../../assets/images/icons/facebook.svg';
import twitter from '../../../assets/images/icons/twitter.svg';
import instagram from '../../../assets/images/icons/instagram.svg';
import { ChevronUpIcon, EnvelopeIcon, MagnifyingGlassIcon, MapIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline';
import logo from "../../../assets/images/logo/logo.svg";
import { NavLink } from 'react-router-dom';



const Footer = () => {
    return (
        <>
            <footer className='footer'>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="icon-box"  onClick={() => window.scrollTo(0, 0)}>
                            <div className="icon">
                                <ChevronUpIcon />
                            </div>
                            <span>BACK TO TOP</span>
                        </div>

                    </div>
                    <div className="col-md-6"></div>
                </div>
                <div className="col-md-8">
                    <div className="menu-footer-box">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={logo} />
                            </div>
                            <div className="col-md-6">
                                <ul className="footer-social">
                                    <li>
                                        <a href="#/">
                                            <img src={facebook} alt="facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#/">
                                            <img src={instagram} alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#/">
                                            <img src={twitter} alt="twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul className="footer-navbar-nav">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Categories</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Daimond</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Blog</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Pages</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Shop</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12">
                    <ul className="content-list">
                        <li>
                            <div className="icon-info-detail">
                                <div className="icon">
                                    <PhoneIcon />
                                </div>
                                <div className="content">
                                    <h3>Phone Number</h3>
                                    <p>+92 300 123 4567</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="icon-info-detail">
                                <div className="icon">
                                    <EnvelopeIcon />
                                </div>
                                <div className="content">
                                    <h3>Email Address</h3>
                                    <p>info@martinpay.com</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="icon-info-detail">
                                <div className="icon">
                                    <MapPinIcon />
                                </div>
                                <div className="content">
                                    <h3>Store Address</h3>
                                    <p>H Z Road, Main Bouleward, United Kingdom</p>
                                </div>
                            </div>
                        </li>
                        <li className='flex-grow-2'>
                            <div className="form_default">
                                <div className="form-group">
                                    <div className="inputwrap">
                                        <input type="text" className='form-control' placeholder='Search ...' />
                                        <div className="icon">
                                            <MagnifyingGlassIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-md-12">
                    <div className="copyright">
                        <div className="section-detail">
                            Copyright <a href="#/">info@martinpay.com</a> - 2024
                        </div>
                        <ul className="term">
                            <li>
                                <a href="#/">Terms of use</a>
                            </li>
                            <li>
                                <a href="#/">Privacy Environmental Policy</a>
                            </li>
                        </ul>
                    </div>
                </div>


            </footer>
        </>
    )
}

export default Footer