import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';

function Funds_Transfer(Prop) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  //   console.log(status);


  const [Member, setMember] = useState('');

  const [TransferType, setTransferType] = useState("Free Funds Transfer");
  const [Action, setAction] = useState("1");


  const [showFilters, setShowFilters] = useState(true);

  let navigate = useNavigate();


  useEffect(() => {
    document.title = "Martinpay Admin";
    // const ID = (urlParams.get('ID'));

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: Prop.ID
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMemberByMemberID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setMember(response.data.Member);
          setLoading(false);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {

          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          //navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [validated, setValidated] = useState(false);

  const UpdateProfile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberID: Prop.ID,
        Amount: event.target.Amount.value,
        TypeID: Action,
        Description: event.target.Description.value,
        PINCode: event.target.PINCode.value,
        TransactionType: TransferType,
      };

      //  console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Users/BalanceTransfer",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(api_config)
        .then(function (response) {
          //  console.log("my data", response.data)
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            window.location.reload();
          } else if (
            (response.data.status_code === 0 &&
              response.data.status_message === "Invalid Access Key.") ||
            response.data.status_code === 2
          ) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            navigate("/admin/login");
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setValidated(true);
  };
  return (
    <>
      <ToastContainer />
      <div className="admin-content">
        <div className="card card-table admin-profile-input">
          <div className="card-inner">
            <div className="form_default">
              <div className="row p-3 gy-3">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Member Name</label>
                    <div className="inputwrap">
                      <input type="text" readOnly disabled defaultValue={Member.Full_Name} name="Full_Name" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Username</label>
                    <div className="inputwrap">
                      <input type="text" readOnly disabled defaultValue={Member.User_Name} name="User_Name" className="form-control" />
                    </div>
                  </div>
                </div>


                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Email</label>
                    <div className="inputwrap">
                      <input type="text" readOnly disabled defaultValue={Member.Email_Address} name="Email_Address" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Pay ID</label>
                    <div className="inputwrap">
                      <input type="text" readOnly disabled defaultValue={Member.AccountKey} name="Email_Address" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="card card-table mb-3 mt-4 admin-profile-input">
          <div className="card-inner">
            <div className="form_default">
              <Form noValidate validated={validated} onSubmit={UpdateProfile}>
                <div className="row p-3 gy-3 align-items-end">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Transfer Type</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={TransferType}
                          onChange={(e) => setTransferType(e.target.value)}
                        >
                          <option value="Free Funds Transfer">Free Funds Transfer</option>
                          <option value="Paid Funds Transfer">Paid Funds Transfer</option>
                          <option value="Reduced Funds">Reduced Funds</option>
                          <option value="Advance Funds Transfer">Advance Funds Transfer</option>
                          <option value="Recovered Advance Funds">Recovered Advance Funds</option>
                          <option value="RSM Leader Commission">RSM Leader Commission</option>

                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Amount</label>
                      <div className="inputwrap">
                        <input type="text" name="Amount" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Enter Pin Code</label>
                      <div className="inputwrap">
                        <input type="text" name="PINCode" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Action</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={Action}
                          onChange={(e) => setAction(e.target.value)}
                        >
                          <option value="1">Transfer Funds</option>
                          <option value="2">Reduce Funds</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="form-group">
                      <label htmlFor="">Comments</label>
                      <div className="inputwrap">
                        <input type="text" name="Description" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group text-end">
                      {
                        loading ?
                          <Spinner animation="border" role="status"></Spinner>
                          :
                          <button type="submit" className="button button-primary">
                           {Action === "1" ? "Transfer Funds" : (Action === "2" ? "Reduce Funds" : null)}
                          </button>
                      }
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funds_Transfer;
