import React from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

const PaymentStatistics = (props) => {

  const theme = localStorage.getItem("theme");
  // console.log(theme);
  const Data = props.GraphData;
  // const labels = Data.ListofLabels;
  // const values = Data.ListofValues;
  const labels = ['a','b','c','d','e'];
  const values = [1,2,3,4,5];
  const loading = Data.loading;
  
  // console.log("Data",Data)
  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          drawTicks: false,
          drawBorder: false,
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 30,
      },

    },
    categorySpacing: 10,
  };

  return (
    <div>
      <Bar
        type="Bar"
        data={{
          labels: labels,
          datasets: [
            {
              label: false,
              data: values,
              backgroundColor: '#FBD36B',
              borderColor: '#FBD36B',
              borderWidth: 1,
              barPercentage: 0.25,
              borderRadius: 50,
              borderSkipped: false,
            },

          ],
        }}
        options={options}
      />
    </div>
  );
}

export default PaymentStatistics;
