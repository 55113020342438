import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import Form from 'react-bootstrap/Form';
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";

function Downline_Activation(props) {

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );

    const [showFilters, setShowFilters] = useState(true);

    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfRecord, setListOfRecord] = useState([]);
    const [packageName, setPackageName] = useState("");

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    let navigate = useNavigate();


    useEffect(() => {
        document.title = "Martinpay Admin";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            Category: packageName,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetDownlineActivationDetails",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        console.log(data);

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {

                    //  console.log("data", response.data);
                    setListOfRecord(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                }
                else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {

                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, packageName, StartDate, EndDate]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        if (listOfRecord != null && listOfRecord.length > 0) {
            let count = 1;
            return listOfRecord.map((item, index) => {
                //console.log(item);
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.Created_Date).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{item.SecretID}</td>
                        <td>{item.Paid_By}</td>
                        <td>{item.User_ID}</td>
                        <td>{item.PackageName}</td>
                        <td>PKR {NumberFormat(item.PackageAmount)}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="6">
                        <h4 className="else">
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };
    return (
        <>
            <AdminRightsCheck />
            <div className="admin-content">

                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Total Downline Package Activation
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/total-transfer-dashboard">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row gy-3 align-items-end p-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select by Category</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={packageName}
                                                    onChange={(e) => setPackageName(e.target.value)}
                                                >
                                                    <option value="" selected>All</option>
                                                    {/* <option value="Binary">Binary</option>
                                                    <option value="Realesto">Realesto</option>
                                                    <option value="Commodity">Commodity</option>
                                                    <option value="Bronze">Bronze</option>
                                                    <option value="Silver">Silver</option> */}
                                                    <option value="Gold">Gold</option>
                                                    <option value="Platinum">Platinum</option>
                                                    <option value="Titanium">Titanium</option>
                                                    <option value="Diamond">Diamond</option>
                                                    {/* <option value="Crystal">Crystal</option>
                                                    <option value="Diamond">Diamond</option>
                                                    <option value="Crown">Crown</option>
                                                    <option value="Investus">Investus</option> */}

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/total-transfer-dashboard/Downline-Activation/pdf/?StartDate=${StartDate}&EndDate=${EndDate}&Full_Name=${Full_Name}&packageName=${packageName}`}>PDF</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Date</th>
                                    <th>Package ID</th>
                                    <th>Purchased By</th>
                                    <th>Purchased For</th>
                                    <th>Package Name</th>
                                    <th>Package Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}
                                        {
                                            listOfRecord != null && listOfRecord.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th colSpan={6} className="border-bottom-0 text-end pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfRecord, 'PackageAmount'))}</th>
                                                </tr>
                                                <tr className="">
                                                    <th colSpan={6} className="text-end border-bottom-0">Grand Total: </th>
                                                    <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.totalActivations)}</th>
                                                </tr>
                                            </>
                                        }


                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}

export default Downline_Activation;
