import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import logo from "../../assets/images/logo/mag.svg";
import background from "../../assets/images/martinpay/login-bg.png";
import { toast, ToastContainer } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import {
  ArrowLeftStartOnRectangleIcon,
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  UserCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
const PartnerSignUp = () => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Username, setUsername] = useState("");
  const [UserNameExist, setUserNameExist] = useState(null);
  const [ReferrralExist, setReferrralExist] = useState(null);
  const [Referrral, setReferrral] = useState("");
  const [RefEmail, setRefEmail] = useState(null);
  const [ParentID, setParentID] = useState(0);

  let navigate = useNavigate();
  let params = useParams();

  const [Password, setPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (params.id) {
      setParentID(params.id);

      const data = {
        UserName: params.id,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/GetReferralUserByUserName",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            setRefEmail(response.data.User_Name);
            setReferrralExist(true);
          } else if (response.data.status_code == 0) {
            setReferrralExist(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          toast.error("Something went wrong..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    CheckReferrral();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);

      const data = {
        Full_Name: event.target.Full_Name.value,
        Email_Address: event.target.Email_Address.value,
        User_Name: Username,
        Referrer_Email: event.target.Referrer_Email.value,
        Password: Password,
      };

      // console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Members/SignUp",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log(data);

      axios(api_config)
        .then(function (response) {
          // console.log(response.data);

          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setTimeout(() => {
              navigate("/login");
            }, 4000);
          } else if (response.data.status_code == 0) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Something went wrong..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };
  const CheckReferrral = (e) => {
    if (Referrral.length > 0) {
      const data = {
        ReferralName: Referrral,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/CheckValidReferral",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_message == "Valid Referral") {
            setReferrralExist(true);
          } else {
            setReferrralExist(false);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else if (Referrral.length == 0) {
      setReferrralExist(null);
    }
  };

  const checkUsername = () => {
    if (Username == " " || Username == "") {
      setUserNameExist(true);
      return false;
    }

    const data = {
      UserName: Username,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/CheckUserNameExists",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    console.log(data);
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // "User Does Not Exists"
          setUserNameExist(false);
        } else {
          // "User Name Exists"
          setUserNameExist(true);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleUsernameChange = (event) => {
    const value = event.target.value.slice(0, 13);
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setUsername(sanitizedValue);

    const formattedUsername =
      sanitizedValue.charAt(0).toUpperCase() +
      sanitizedValue.slice(1).toLowerCase();
    setUsername(formattedUsername);
  };
  const handleReferralChange = (event) => {
    const value = event.target.value.slice(0, 13);
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove spaces
    setReferrral(sanitizedValue);
  };
  return (
    <>
      <ToastContainer />
      <section className="business-signin-form signup-page">
        <img src={background} />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="container">
            <div className="login_box">
              <div className="login_box_head">
                <img src={logo} />
                <h4>Create New Customer Account</h4>
              </div>
              <div className="login_box_body">
                <div className="icon-input">
                  <input
                    name="Full_Name"
                    placeholder="Full Name"
                    className=""
                    required
                  />
                </div>
                <div className="icon-input">
                  <input
                    name="User_Name"
                    type="text"
                    placeholder="Username"
                    className=""
                    required
                    onChange={(e) => handleUsernameChange(e)}
                    onBlur={checkUsername}
                    value={Username}
                  />
                  <div className="icon">
                    {UserNameExist == true ? (
                      <>
                        <XCircleIcon />
                      </>
                    ) : UserNameExist == false ? (
                      <>
                        <CheckCircleIcon />
                      </>
                    ) : (
                      <UserCircleIcon />
                    )}
                  </div>
                </div>
                {Username.length < 8 && Username.length > 0 && (
                  <div className="">
                    <p
                      style={{
                        fontSize: "12px",
                        margin: "10px 0",
                        color: "red",
                      }}
                    >
                      Minimum 8 to 13 characters
                    </p>
                  </div>
                )}
                <div className="icon-input">
                  <input
                    type="email"
                    placeholder="Email"
                    name="Email_Address"
                    className=""
                    required
                  />
                  <div className="icon">
                    <EnvelopeIcon />
                  </div>
                </div>
                {RefEmail == null ? (
                  <div className="icon-input">
                    <input
                      type="text"
                      name="Referrer_Email"
                      value={Referrral}
                      onBlur={CheckReferrral}
                      onChange={(e) => handleReferralChange(e)}
                      placeholder="Referral"
                    />
                    <div className="icon">
                      {ReferrralExist == true ? (
                        <CheckCircleIcon />
                      ) : ReferrralExist == false ? (
                        <XCircleIcon />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="icon-input">
                    <input
                      type="text"
                      className="disabled"
                      name="Referrer_Email"
                      value={RefEmail}
                      placeholder="Referral"
                      required
                      disabled
                    />
                    <div className="icon">
                      {ReferrralExist == true ? (
                        <CheckCircleIcon />
                      ) : ReferrralExist == false ? (
                        <XCircleIcon />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
                <div className="icon-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    name={Password}
                    placeholder="Password"
                    className=""
                    required
                  />
                  <div className="icon" onClick={togglePasswordVisibility}>
                    {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                  </div>
                </div>
                <p className="terms">
                  I agree to the
                  <a
                    className="link"
                    target="_blank"
                    href="https://www.martinpay.com/en/terms-of-service/"
                  >
                    {" "}
                    Terms & Condition
                  </a>
                  <a
                    className="link"
                    target="_blank"
                    href="https://www.martinpay.com/en/privacy-policy"
                  >
                    {" "}
                    Privacy Policy
                  </a>
                </p>
                <button
                  type="submit"
                  className="button button-bs w-100"
                  disabled={loading}
                >
                  Register {loading ? "..." : ""}{" "}
                  <span>
                    <ArrowLeftStartOnRectangleIcon />
                  </span>
                </button>
                <div className="mt-3 d-flex m-auto justify-content-center">
                  <p className="m-0 fs-6" style={{ color: '#a4a4a3' }}>
                    I already have an account
                    <NavLink to={"/login"} className="fs-6">
                      {" "}
                      SIGN IN
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
};

export default PartnerSignUp;
