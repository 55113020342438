import React from 'react';
import { NavLink } from 'react-router-dom';

function RewardMenu(props) {
    return (
        <>
            <ul className="menu-option">
                <li>
                    <NavLink className="nav-link"  to="/martinpay/transactions/treasure-wallet/ranks-reward">Ranks Reward</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/martinpay/transactions/treasure-wallet/pool-reward">Pool Reward</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/martinpay/transactions/treasure-wallet/promotion">Promotion</NavLink>
                </li>
            </ul>
        </>
    );
}

export default RewardMenu;