import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import Modal from 'react-bootstrap/Modal';
import KycView from "./KycView";
import Form from 'react-bootstrap/Form';
import { Badge } from "react-bootstrap";

function MembersList(props) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;


  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [status, setStatus] = useState("");

  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [Full_Name, setFull_Name] = useState('');

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => {
    document.title = "Martinpay Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: userstatusVal ? userstatusVal : status,
      startDate: StartDate,
      endDate: EndDate,
      UserName: Full_Name,
    };

    // console.log("data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMembersList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          //console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, status, userstatusVal, StartDate, EndDate]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const [ShowdKYCModal, setShowdKYCModal] = useState(false);
  const [User_ID, setUser_ID] = useState(null);
  const handleShowKYCModal = (item) => {
    setUser_ID(item);
    setShowdKYCModal(true);
  }

  const handleCloseKYCModal = () => {
    setShowdKYCModal(false);
    setUser_ID('');
  }

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        // console.log(item)
        return (
          <tr key={index}>
            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
            <td>{moment(item.Created_Date).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Updated_Date).format("DD/MM/YYYY")}</td>
            <td>{item.User_Name}</td>
            <td>{item.Full_Name}</td>
            <td>{item.Mobile_No}</td>
            <td>{item.Email_Address}</td>
            {/* <td className="text-center">
              {item.Investment_Balance > 0 ? (
                <span
                  style={{ fontSize: "20px" }}
                  className="material-symbols-outlined tick yes ms-2"
                >
                  done
                </span>
              ) : (
                <span>-----</span>
              )}
            </td> */}
            <td className="text-center">
              {item.Member_Type == 'Standard' ? <Badge bg="dark" className="ms-1">Free</Badge> : <Badge bg="success" style={{ backgroundColor: "#B69541" }} className="ms-1">Paid</Badge>}
              {item.FreeMember == 'Yes' ? <Badge bg="dark" className="ms-1">Free</Badge> : ''}

            </td>
            <td>
              {item.KYCStatus}
            </td>
            <td>
              {item.Is_Blocked ? (
                <span
                  style={{ fontSize: "20px" }}
                  className="material-symbols-outlined tick yes ms-2"
                >
                  done
                </span>
              ) : (
                <span></span>
              )}
            </td>
            <td className="text-center">
              <button
                className="button small-button" id={`${index}`} onClick={(e) => handleShowKYCModal(item.Nodes_IN_PK_Code)}>View KYC</button>
            </td>
          </tr >
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4 className="else">
                            No Data Found
                        </h4>
          </td>
        </tr>
      );
    }
  };


  const goBack = () => {
    window.history.back();
  };


  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUpdated(true);
  };


  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <Modal
        className="admin-support-modal modal-theme  p-0"
        show={ShowdKYCModal}
        onHide={handleCloseKYCModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>View KYC</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start">
          <KycView ID={User_ID} />
        </Modal.Body>
      </Modal>

      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Total Members
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <button type="button" className="button button-outline-bs" onClick={goBack}>
                Back
              </button>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-3 gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search by category</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={userstatusVal ? userstatusVal : status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="" selected>
                            All
                          </option>
                          <option value="Active"> Active</option>
                          <option value="Inactive"> Inactive</option>
                          <option value="Paid"> Paid</option>
                          <option value="Free"> Free</option>
                          <option value="Ambassador"> Ambassador</option>
                          <option value="Blocked"> Blocked</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={StartDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form_default position-relative">
                      <Form onSubmit={handleSearch}>
                        <div className="row align-items-end gy-3 gx-1">
                          <div className="col-md-7">
                            <div className="form-group search_box">
                              <label htmlFor="">Search Member</label>
                              <div className="inputwrap">
                                <input type="text" className="form-control"
                                  placeholder="Find by Username/Member ID/Email"
                                  value={Full_Name}
                                  onChange={(e) => setFull_Name(e.target.value)}
                                />
                                <span className="material-symbols-outlined">search</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="button w-100 button-primary input-height input-radius">
                              search
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <ul className="downloads">
                      <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                        <Link target="_blank" to={`/admin/userstatistics-dashboard/Total-user/pdf/?StartDate=${StartDate}&EndDate=${EndDate}&Full_Name=${Full_Name}&status=${userstatusVal ? userstatusVal : status}`}>PDF</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Created Date</th>
                  <th>Updated Date</th>
                  <th>Username</th>
                  <th>Name</th>
                  <th>Mobile No</th>
                  <th>Email</th>
                  <th>Member Status</th>
                  <th>KYC Status</th>
                  <th>Blocked</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="10">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MembersList;
