import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import AdminRightsCheck from "../AdminRightsCheck";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { Spinner } from "react-bootstrap";

const KycView = (Prop) => {


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [showFilters, setShowFilters] = useState(true);


    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Member, setMember] = useState('');
    const [Referrer, setReferrer] = useState('');
    const [IsLeader, setIsLeader] = useState('');

    const [EmailVerified, setEmailVerified] = useState('');
    const [RestrictROItransfer, setRestrictROItransfer] = useState('');
    const [RestrictUplineProfit, setRestrictUplineProfit] = useState('');
    const [RestrictWithdrawl, setRestrictWithdrawl] = useState('');
    const [PackageRewardGiven, setPackageRewardGiven] = useState('');
    const [HideInUpLine, setHideInUpLine] = useState('');

    const [user, setUser] = useState("");

    let navigate = useNavigate();


    useEffect(() => {

        document.title = "Martinpay Admin";

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: Prop.ID
        };

        // console.log("data data", data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetMemberKYC",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setUser(response.data);
                    setLoading(false);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    navigate("/admin/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    return (
        <>
            <div className="form_admin">
                {
                    loading ?
                        <Spinner animation="border" role="status"></Spinner>
                        :
                        <div className="row gy-3">
                            <div className="col-md-4">
                                <div className="card card-table">
                                    <div className="section-title">Personal details.</div>
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <div className="inputwrap">
                                            <div className="form-control disabled">
                                                {user.FirstName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <div className="inputwrap">

                                            <div className="form-control disabled">
                                                {user.LastName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Birth Country</label>
                                        <div className="inputwrap">

                                            <div className="form-control disabled">
                                                {user.BirthCountry}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Username</label>
                                        <div className="inputwrap">

                                            <div className="form-control disabled">
                                                {user.User_Name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <div className="inputwrap">
                                            <div className="form-control disabled">
                                                {user.Email_Address}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile No</label>
                                        <div className="inputwrap">
                                            <div className="form-control disabled">
                                                {user.MobileNo}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Pay ID</label>
                                        <div className="inputwrap">
                                            <div className="form-control disabled">
                                                {user.AccountKey}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="card card-table h-100">
                                    <div className="card-inner">
                                        <div className="row gy-2">
                                            <div className="admin-card-title" style={{ color: "black" }}>
                                                Document Item (
                                                {user.DocumentType == "License" ? (
                                                    <>
                                                        <>Driving license</>
                                                    </>
                                                ) : user.DocumentType === "ID" ? (
                                                    <>
                                                        <>ID</>
                                                    </>
                                                ) : user.DocumentType === "Passport" ? (
                                                    <>
                                                        <>Passport</>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                )
                                            </div>
                                            <div className="col-md-12">
                                                {user.DocumentType === "License" && (
                                                    <>
                                                        <br />
                                                        <div className="user-card">
                                                            <div className="front">
                                                                <img
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    src={`${config.imge}/${user.LicenseFront}`}
                                                                    alt={user.DocumentType}
                                                                />
                                                            </div>
                                                            <div className="back">
                                                                <img
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    src={`${config.imge}/${user.LicenseBack}`}
                                                                    alt={user.DocumentType}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br />

                                                    </>
                                                )}

                                                {user.DocumentType === "ID" && (
                                                    <>
                                                        <br />
                                                        <div className="user-card">
                                                            <div className="front">
                                                                <img
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    src={`${config.imge}/${user.IDFront}`}
                                                                    alt={user.DocumentType}
                                                                />
                                                            </div>
                                                            <div className="back">
                                                                <img
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    src={`${config.imge}/${user.IDBack}`}
                                                                    alt={user.DocumentType}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br />

                                                    </>
                                                )}

                                                {user.DocumentType === "Passport" && (
                                                    <>
                                                        <br />
                                                        <div className="user-card">
                                                            <div className="front">
                                                                <img
                                                                    //    onClick={(e) => getimgsource(e)}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    src={`${config.imge}/${user.Passport}`}
                                                                    alt={user.DocumentType}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div >
        </>
    )
}

export default KycView