import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Heart from '../../../assets/images/heart.png';
import Orangnalring from '../../../assets/images/orangnal-ring.png';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import ProductsSaleCard from '../website-component/ProductsSaleCard';

import Rings from '../../../assets/images/icons/Ring.svg';
import Pandants from '../../../assets/images/icons/Pendants.svg';
import Primary_Necklaces from '../../../assets/images/icons/Primary-Necklaces.svg';
import Necklaces from '../../../assets/images/icons/Necklaces.svg';
import Earings from '../../../assets/images/icons/Earings.svg';


import axios from 'axios';
import Config from '../../../Config';
import { Placeholder } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';



function Categories(props) {

    const [loading, setLoading] = useState(false);
    const [ListOfCategories, setListOfCategories] = useState([]);

    useEffect(() => {

        setLoading(true);

        var api_config = {
            method: 'post',
            url: Config.base_url + 'ProductCategory/GetMainCategories',
            headers: {
                'Content-Type': 'application/json'
            },
            // data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setListOfCategories(response.data.listofRecords);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {

            });
    }, []);


    const [quantity, setQuantity] = useState(0);

    const handleDecrement = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        }
    };

    const handleIncrement = () => {
        setQuantity(quantity + 1);
    };

    return (
        <>
            <section className="page-head">
                <p>Shop by</p>
                <h3>Categories</h3>
            </section>

            <section className='simple-section'>
                <div className='container'>
                    <ul className="categories-list">
                        {
                            loading ?
                                <>
                                    <li>
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                    </li>
                                    <li>
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                    </li>
                                    <li>
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                    </li>
                                    <li>
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                    </li>
                                    <li>
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                    </li>
                                    <li>
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                    </li>
                                    <li>
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder xs={12} />
                                        </Placeholder>
                                    </li>
                                </>
                                :

                                ListOfCategories.map((item, index) => (
                                    <li key={index}>
                                        <NavLink to={`/products/category/${item.ID}`}>
                                            <img src={`${Config.path}Images/Products/Categories/${item.CategoryPhoto}`} alt={item.CategoryName} />
                                            <span>{item.CategoryName}</span>
                                        </NavLink>
                                    </li>
                                ))
                        }
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Categories