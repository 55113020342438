import React, { useEffect, useState } from 'react'
import config from '../../../Config';
import axios from 'axios';
import { NavLink, Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "../../Dashboard/General/Pagination";


const ManageCategory = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));



    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);

    const [loading, setLoading] = useState(false);
    const [IsDelect, setIsDelect] = useState(false);
    const [listOfCategories, setListOfCategories] = useState([]);


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };
        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'ProductCategory/GetAllCategories',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setListOfCategories(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });



    }, [pageNo, pageSize, IsDelect]);


    // const columns = [
    //     {
    //         title: 'ID',
    //         dataIndex: 'ID',
    //         key: 'ID',
    //     },
    //     {
    //         title: 'Main Category',
    //         dataIndex: 'ParentCategoryName',
    //         key: 'ParentCategoryName',
    //     },
    //     {
    //         title: 'Sub Category',
    //         dataIndex: 'CategoryName',
    //         key: 'CategoryName',
    //     },
    //     {
    //         title: 'URL',
    //         dataIndex: 'CategoryURL',
    //         key: 'CategoryURL',
    //     },
    //     {
    //         title: 'Sort Order',
    //         dataIndex: 'SortOrder',
    //         key: 'SortOrder',
    //     },

    //     {
    //         title: 'Short Description',
    //         dataIndex: 'Short_Description',
    //         key: 'Short_Description',
    //     },
    //     {
    //         title: 'Action',
    //         dataIndex: 'ID',
    //         key: 'ID',
    //         render: (_, record) => (
    //             <>
    //                 <Flex gap={10}>
    //                     <Popconfirm
    //                         placement="topRight"
    //                         title="Delete the Category"
    //                         description="Are you sure to delete this Category?"
    //                         onConfirm={(e) => DelectItem(record.ID)}
    //                         okText="Yes"
    //                         cancelText="No"
    //                     >
    //                         <span className='circle-icon red' align={'middle'}>
    //                             <DeleteOutlined />
    //                         </span>
    //                     </Popconfirm>
    //                     <NavLink
    //                         to={`/admin/categories/update-categories/${record.ID}`}
    //                         className='circle-icon blue'
    //                         align={'middle'}
    //                     >
    //                         <EditOutlined />
    //                     </NavLink>
    //                 </Flex>

    //             </>
    //         )
    //     },

    // ];

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };


    const DelectItem = (ID) => {

        setIsDelect(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'ProductCategory/DeleteCategory',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {

                console.log(response.data);
                if (response.data.status_code == 1) {

                    setIsDelect(false);
                }
                else {

                    setIsDelect(false);
                }
            })
            .catch(function (error) {


            });
    };

    const handlePageChange = (page) => {
        setPageNo(page);
      };
    

    return (
        <>
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Manage Categories
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to={`/admin/categories/add-categories`} className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                Add Category
                                <i className="ri-add-line ms-3"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Category Name</th>
                                    <th>Parent Category</th>
                                    <th>Sort Order</th>
                                    <th>Short Des</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ?
                                    (
                                        <tr>
                                            <td className="text-center" colSpan="9">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                listOfCategories.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.ID}</td>
                                                        <td>{item.CategoryName}</td>
                                                        <td>{item.ParentCategoryName}</td>

                                                        <td>{item.SortOrder}</td>
                                                        <td>{(item.Short_Description)}</td>
                                                        <td>
                                                            <ul
                                                                className="inline-action"
                                                                aria-labelledby="dropdownMenuButton1"
                                                            >
                                                                <li>
                                                                    <NavLink
                                                                        to={"/admin/categories/update-categories/" + item.ID}
                                                                        className="primary "
                                                                    >
                                                                        <i className="ri-edit-line"></i>
                                                                    </NavLink>
                                                                </li>
                                                                <li><button className="red" onClick={(e) => DelectItem(item.ID)} type="button"><i className="ri-delete-bin-line"></i></button></li>

                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ManageCategory