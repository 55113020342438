const host = window.location.hostname;
const isLive = window.location.hostname === 'app.investus.world';

let base_url;
let path;
let img_path;
let imge;
let assets;
let root;
let admin_path;
let path_realestate;


if (host == 'localhost' || host == 'practice.martinpay.com') {

  //   martinpay.com
  // api.martinpay.com

  // practice.martinpay.com
  // practiceapi.martinpay.com 


  // base_url = "https://api.martinpay.com/api/";
  // path = "https://api.martinpay.com/";
  // img_path = "https://api.martinpay.com/Personal/MemberImages/";
  // imge = "https://api.martinpay.com/personal/memberimages/kyc/";
  // assets = "https://api.martinpay.com/assets/";
  // root = "https://api.martinpay.com/";


  base_url = "https://practiceapi.martinpay.com/api/";
  path = "https://practiceapi.martinpay.com/";
  img_path = "https://practiceapi.martinpay.com/Personal/MemberImages/";
  imge = "https://practiceapi.martinpay.com/personal/memberimages/kyc/";
  assets = "https://practiceapi.martinpay.com/assets/";
  root = "https://practiceapi.martinpay.com/";
  path_realestate = "https://practiceapi.martinpay.com/images/RealEstate/";

  // base_url = "http://localhost:52476/api/";
  // path = "http://localhost:52476/";
  // img_path = "http://localhost:52476/Personal/MemberImages/";
  // imge = "http://localhost:52476/personal/memberimages/kyc/";
  // assets = "http://localhost:52476/assets/";
  // root = "http://localhost:52476/";
  // path_realestate = "http://localhost:52476/images/RealEstate/";

}
else {
  base_url = "https://martinpay.com/api/";
  path = "https://martinpay.com/api/";
  img_path = "https://martinpay.com/api/Personal/MemberImages/";
  imge = "https://martinpay.com/api/personal/memberimages/kyc/";
  assets = "https://martinpay.com/api/assets/";
  root = "https://martinpay.com/api/";
}


const Config = {
  base_url,
  path,
  img_path,
  imge,
  assets,
  root,
  admin_path,
  path_realestate,
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
};


export default Config;