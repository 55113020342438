import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from "axios";
import config from "../../../Config";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Editor } from "@tinymce/tinymce-react";

import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from "react-toastify";
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/outline";



function AddCategory(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [Loading, setLoading] = useState('')
    const [value, setValue] = useState("true");


    // form
    const [validated, setValidated] = useState(false);


    const [Category, setCategory] = useState([]);
    let navigate = useNavigate();

    const DetailRef = useRef(null);
    const [Detail, setDetail] = useState("");
    const handleDetailChange = (content) => {
        setDetail(content);
    };


    const onRadioChange = (e) => {
        setValue(e.target.value);
    };


    useEffect(() => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'ProductCategory/GetMainCategories',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setCategory(response.data.listofRecords)
                }
            })
            .catch(function (error) {

            });
    }, []);

    const options = Category.map((item) => ({
        value: item.ID,
        label: item.CategoryName,
    }));



    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                IsNew: value,
                CategoryDetails: Detail,
                Short_Description: event.target.Short_Description.value,
                SEOTitle: event.target.SEOTitle.value,
                SEODescription: event.target.SEODescription.value,
                SEOKeywords: event.target.SEOKeywords.value,
                SortOrder: event.target.SortOrder.value,
                CategoryName: event.target.CategoryName.value,
                ParentCategoryID: event.target.ParentCategoryID.value,
                CategoryPhoto: CategoryPhoto,
                BannerImage: BannerImage

            };


            // if (!CategoryPhoto.startsWith("data:image/png;base64")) {
            //     toast.error('Please select PNG Category Photo', {
            //         position: toast.POSITION.TOP_RIGHT,
            //     });
            //     setLoading(false);
            //     return;
            // } else if (!BannerImage.startsWith("data:image/png;base64")) {
            //     toast.error('Please select PNG Banner Image', {
            //         position: toast.POSITION.TOP_RIGHT,
            //     });
            //     setLoading(false);
            //     return;
            // }

            // else {
            // console.log(data, 'data');

            var api_config = {
                method: 'post',
                url: config.base_url + 'ProductCategory/AddCategory',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {

                    // console.log(response.data, 'response');
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoading(false);
                        navigate('/admin/categories/manage-categories');
                        setValue('true')

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoading(false);
                    }
                })
                .catch(function (error) {
                });
            // }

        }

        setValidated(true);
    };

    // front side
    const [CategoryPhoto, setCategoryPhoto] = useState("");
    const [CategoryPhotoSelected, setCategoryPhotoSelected] = useState(false);
    const [CategoryCropper, setCategoryCropper] = useState();

    const DeleteCategoryPhoto = () => {
        setCategoryPhoto("");
        setCategoryPhotoSelected(false);
        setCategoryCropper();
    }
    // back side
    const [BannerImage, setBannerImage] = useState("");
    const [BannerImageSelected, setBannerImageSelected] = useState(false);
    const [BannerCropper, setBannerCropper] = useState();

    const DeleteBannerImage = () => {
        setBannerImage("");
        setBannerImageSelected(false);
        setBannerCropper();
    }


    //const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const MAX_IMAGE_SIZE = 4096 * 1024; // 2MB in bytes


    const [dragging, setDragging] = useState(false);
    const [dragFront, setdragFront] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };
    const handleDragfirst = (event) => {
        event.preventDefault();
        setdragFront(true);
    };

    const handleDropfirst = (event) => {

        event.preventDefault();
        setCategoryPhotoSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {


            if (file.size > MAX_IMAGE_SIZE) {
                setCategoryPhotoSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                console.log(e.target.result);
                setCategoryPhoto(e.target.result);
                if (BannerCropper) {
                    BannerCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const handleDrop = (event) => {

        event.preventDefault();
        setBannerImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setBannerImageSelected(false)
                toast.error("Image size must be less than 2 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            // console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setBannerImage(e.target.result);
                if (BannerCropper) {
                    BannerCropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const onChangeFront = (e) => {
        e.preventDefault();

        setCategoryPhotoSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];

        console.log('file size', file.size);
        console.log('allowed size', MAX_IMAGE_SIZE);


        if (file.size > MAX_IMAGE_SIZE) {
            let sizeinkb = file.size / 1024;
            let sizeinmb = sizeinkb / 2024;
            setCategoryPhotoSelected(false);
            toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2), { position: toast.POSITION.TOP_RIGHT, });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            console.log('result', reader.result);
            setCategoryPhoto(reader.result);



        };
        reader.readAsDataURL(files[0]);
    }

    const onChangeBack = (e) => {
        e.preventDefault();
        setBannerImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setBannerImageSelected(false);
            toast.error("Image size must be less than 2 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }


        const reader = new FileReader();
        reader.onload = () => {
            setBannerImage(reader.result);


        };
        reader.readAsDataURL(files[0]);
    }


    return (
        <>
            <ToastContainer />
            <div className="admin-content kyc-section">
                <div className="row gy-3 align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Add Category
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/categories/manage-categories">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <NavLink to={`/admin/categories/manage-categories`} className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                Manage Categories
                                <i className="ri-add-line ms-3"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-3 mt-4">
                    <div className="card-inner">
                        <div className="form_default">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div>
                                    <div className="row p-3 gy-3">
                                        <div className="col-md-8">
                                            <div className="row gy-3">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Main Category</label>
                                                        <div className="inputwrap">
                                                            <select className="form-select" name="ParentCategoryID">
                                                                <option value="0">Select Main Category</option>
                                                                {
                                                                    Category.length > 0 ?
                                                                        Category.map((item, index) => (
                                                                            <option value={item.ID} key={index}>{item.CategoryName}</option>
                                                                        ))
                                                                        :
                                                                        ""
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Category Name</label>
                                                        <div className="inputwrap">
                                                            <input type="text" className="form-control" name="CategoryName" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Sort Order</label>
                                                        <div className="inputwrap">
                                                            <input minLength={0} type="number" className="form-control" name="SortOrder" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Short Description</label>
                                                        <div className="inputwrap">
                                                            <textarea className="form-control" name="Short_Description"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Category Details</label>
                                                        <div className="inputwrap">
                                                            <Editor
                                                                apiKey="w81u1otpwt8qqd0odorwvyaol3vsrjipuhdua1mr6kjqnebw"
                                                                init={{
                                                                    plugins:
                                                                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                                                    toolbar:
                                                                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                                                }}
                                                                onInit={(editor) => DetailRef.current = editor}
                                                                onEditorChange={handleDetailChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" >
                                            <div className="row gy-3">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Is New</label>
                                                        <div className="d-flex gap-4">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                <label class="form-check-label" for="flexRadioDefault1">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                                <label class="form-check-label" for="flexRadioDefault2">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="inputwrap">
                                                            <label>SEOTitle</label>
                                                            <input type="text" name="SEOTitle" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="inputwrap">
                                                            <label>SEO Keywords</label>
                                                            <input type="text" name="SEOKeywords" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="inputwrap">
                                                            <label>SEO Description</label>
                                                            <textarea name="SEODescription" rows={3} className="form-control"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="kyc-upload">
                                                        <div className={`kyc-img-card white mb-3`} style={{ width: "100%" }}>
                                                            <div className="card-inner">
                                                                <div className={`upload-box`}
                                                                    id="drop-area"
                                                                    onDragOver={handleDragfirst}
                                                                    onDrop={handleDropfirst}
                                                                >
                                                                    <input id="ChoosePhotoFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFront} />
                                                                    {
                                                                        CategoryPhotoSelected ?
                                                                            <>
                                                                                <Cropper
                                                                                    // style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                                    zoomTo={0}
                                                                                    preview=".img-preview"
                                                                                    src={CategoryPhoto}
                                                                                    dragMode="move"
                                                                                    viewMode={2}
                                                                                    minCropBoxHeight={10}
                                                                                    minCropBoxWidth={10}
                                                                                    background={false}
                                                                                    responsive={true}
                                                                                    autoCropArea={1}
                                                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                                    onInitialized={(instance) => {
                                                                                        setCategoryCropper(instance);
                                                                                    }}
                                                                                    guides={true}
                                                                                />
                                                                                <div className="delete" onClick={DeleteCategoryPhoto}>
                                                                                    <TrashIcon />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <label htmlFor="ChoosePhotoFront" className='thumnail'>
                                                                                <ArrowUpTrayIcon />
                                                                                <p>Upload Category Photo</p>
                                                                            </label>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="kyc-upload">
                                                <div className={`kyc-img-card white`} style={{ width: "100%" }}>
                                                    <div className="card-inner">
                                                        <div className={`upload-box`}
                                                            id="drop-area"
                                                            onDragOver={handleDragOver}
                                                            onDrop={handleDrop}
                                                        >

                                                            <input id="ChoosePhotoBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBack} />

                                                            {
                                                                BannerImageSelected ?
                                                                    <>
                                                                        <Cropper
                                                                            // style={{ width: '402px', minHeight: '220px', marginTop: '-10px' }}
                                                                            zoomTo={0}
                                                                            preview=".img-preview"
                                                                            src={BannerImage}
                                                                            dragMode="move"
                                                                            viewMode={2}
                                                                            minCropBoxHeight={10}
                                                                            minCropBoxWidth={10}
                                                                            background={false}
                                                                            responsive={true}
                                                                            autoCropArea={1}
                                                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                            onInitialized={(instance) => {
                                                                                setBannerCropper(instance);
                                                                            }}
                                                                            guides={true}
                                                                        />
                                                                        <div className="delete" onClick={DeleteBannerImage}>
                                                                            <TrashIcon />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <label htmlFor="ChoosePhotoBack" className='thumnail'>
                                                                        <ArrowUpTrayIcon />
                                                                        <p>Upload Banner Image</p>
                                                                    </label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-end">
                                            <div className="form-group">
                                                <button className="button button-primary" disabled={Loading} type="submit">
                                                    Save Category {Loading ? '...' : ''}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div >
                </div >
            </div >
        </>
    );

}

export default AddCategory;