import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import config from "../../Config";
import AdminRightsCheck from "./AdminRightsCheck";
import Modal from 'react-bootstrap/Modal';
import profile from "../../assets/images/logo/Logo_notext.svg";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

function AdminProfileUpdate(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    // passwords
    const [typeOldPassword, setTypeOldPassword] = useState(false);
    const [typeNewPassword, setTypeNewPassword] = useState(false);
    const [typeConfPassword, setTypeConfPassword] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {


            let password1 = event.target.NewPassword.value;
            let password2 = event.target.ConfirmPassword.value;

            if (password1 != password2) {
                toast.error('New password and Confirm password should be same', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setLoading(true);
                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    OldPassword: event.target.OldPassword.value,
                    NewPassword: event.target.NewPassword.value,

                };

                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Users/ChangePassword',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };



                axios(api_config)
                    .then(function (response) {

                        // console.log(response.data);
                        if (response.data.status_code == 1) {
                            toast.success('Password updated successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {

                        toast.error('Network Error..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
        }

        setValidated(true);
    };

    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }




    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        setLoading(true);
        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);


            var cleanerBase64 = cropData1.substring(22);


            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Img_File: cleanerBase64
            };



            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/UpdatePhoto',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {


                    if (response.data.status_code == 1) {
                        setIsUpdated(true);
                        setImageSelected(false);
                        localStorage.removeItem("Img_File");
                        localStorage.setItem("Img_File", UserID + ".JPG");
                        window.location.reload();
                    }
                    else {
                        setIsUpdated(false);
                    }

                    setLoading(false);

                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    };

    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-black me-2" onClick={handleCloseModal}>Close</button>
                        {loading ? <Spinner animation="border" role="status"></Spinner> : <button className="button button-black" onClick={getCropData}>Save Photo</button>}
                    </div>
                </>
            )
        }
        else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-black me-2" onClick={handleCloseModal}>Close</button>
                    </div>
                </>
            )
        }
    }



    return (
        <>
            <AdminRightsCheck />
            <ToastContainer />

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdated ?
                            <>
                                <div className="text-center">
                                    <img src={profile} style={{ maxWidth: "300px" }} />
                                    <p>Photo Updated Successfully</p>
                                </div>

                            </>
                            :
                            <>
                                <div className="upload-photo-wrap">
                                    {
                                        imageSelected ?
                                            <Cropper

                                                zoomTo={0.5}
                                                aspectRatio={8 / 8}
                                                preview=".img-preview"
                                                src={image}
                                                dragMode="move"
                                                viewMode={2}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                guides={true}
                                            />
                                            :
                                            <div className="choose-photo">
                                                <label htmlFor="ChoosePhoto">
                                                    <i className="material-symbols-outlined">upload</i>
                                                    <div className="icon">Browse Photo</div>
                                                    <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                </label>

                                            </div>

                                    }


                                </div>
                            </>

                    }

                </Modal.Body>

                {renderButtons()}

            </Modal>
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Update Profile
                        </div>
                    </div>
                </div>


                <div className="card card-table mt-4">
                    <div className="card-inner">
                        <div className="form_default p-4">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row align-items-end gy-3">
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>Current Password</label>
                                            <div className="icon-input">
                                                <input placeholder="Enter Password"
                                                    type={typeOldPassword ? "text" : "password"} name="OldPassword" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeOldPassword(!typeOldPassword);
                                                    }}
                                                >

                                                    {
                                                        typeOldPassword ?
                                                            <EyeSlashIcon />
                                                            :
                                                            <EyeIcon />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>New Password</label>
                                            <div className="icon-input">
                                                <input placeholder="Enter New Password"
                                                    type={typeNewPassword ? "text" : "password"} name="NewPassword" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeNewPassword(!typeNewPassword);
                                                    }}
                                                >

                                                    {
                                                        typeNewPassword ?
                                                            <EyeSlashIcon />
                                                            :
                                                            <EyeIcon />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="icon-form-group">
                                            <label>Confirm Password</label>
                                            <div className="icon-input">
                                                <input placeholder="Confirm Password"
                                                    type={typeConfPassword ? "text" : "password"} name="ConfirmPassword" required />
                                                <div className="icon"
                                                    onClick={(e) => {
                                                        setTypeConfPassword(!typeConfPassword);
                                                    }}
                                                >

                                                    {
                                                        typeConfPassword ?
                                                            <EyeSlashIcon />
                                                            :
                                                            <EyeIcon />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button disabled={loading} className="button mb-3 w-100 button-primary input-height input-radius">Update {loading ? '...' : ''}</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div >
                <div className="card card-table mt-4 d-none">
                    <div className="card-inner">
                        <div className="form_default p-4">
                            <div className="row align-items-center gy-3">
                                <div className="col-md-3">
                                    <div className="account_header">
                                        <div className="profile_photo">
                                            <img src={profile} style={{ width: "70px", height: "70px", borderRadius: "50%" }} />
                                            <button onClick={handleShowModal} className="button">
                                                <span className="material-symbols-outlined">edit</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label>Name</label>
                                        <div className="icon-input">
                                            <input placeholder="Enter Name"
                                                name="" required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

        </>
    );
}

export default AdminProfileUpdate