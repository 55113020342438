import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";


const TreeBonusTable = () => {

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetRecentTreeBonus",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    // navigate("/admin/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{count++ + pageSize * (pageNo - 1)}</td>
                        <td>{moment(item.Date).format('DD/MM/YYYY HH:mm')}</td>
                        <td>PKR {NumberFormat(item.PackageAmount)}</td>
                        <td>PKR {NumberFormat(item.ROI)}</td>
                        <td>PKR {NumberFormat(item.Released)}</td>
                        <td>PKR {NumberFormat(item.TotalReceived)}</td>

                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="6">
                        <h4 className="else">
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>Sr. No</th>
                        <th>Date</th>
                        <th>Royalty Amount</th>
                        <th>Total Packages</th>
                        <th>Total Profit Return</th>
                        <th>Total Royalty Released</th>

                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td className="text-center" colSpan="10">
                                <Spinner animation="border" role="status"></Spinner>
                            </td>
                        </tr>
                    ) : (
                        <>{renderLedger()}</>
                    )}
                </tbody>
            </table>
        </>
    )
}

export default TreeBonusTable