import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { Modal } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";

function RejectedDeposit(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  //   const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [companyName, setcompanyName] = useState("All");
  const [status, setStatus] = useState("Failed");
  const [paymentMode, setpaymentMode] = useState("All");
  const [SearchUser, setSearchUser] = useState("");
  const [SearchReference, setSearchReference] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");


  const [ExcelArray, setExcelArray] = useState([]);

  const [GrandTotal, setGrandTotal] = useState('');

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paymode, setPaymode] = useState("BTC");

  const [showFilters, setShowFilters] = useState(true);
  const [ledgerOld, setLedgerOld] = useState([]);

  const [isFilteredByDate, setisFilteredByDate] = useState(false);

  const [show, setShow] = useState(false);
  const [AdminRemarks, setAdminRemarks] = useState('');
  const [DepositTID, setDepositTID] = useState('');
  const [DepositID, setDepositID] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (ID, AdminRemarks, DepositTID) => {
    setShow(true);
    setDepositID(ID);
    setDepositTID(DepositTID)
    setAdminRemarks(AdminRemarks);
  };

  let navigate = useNavigate();
  useEffect(() => {
    document.title = "Martinpay Admin";
    // setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      PaymentCompany: companyName,
      Status: status,
      PaymentMode: paymentMode,
      UserName: SearchUser,
      PAYMENT_BATCH_NUM: SearchReference,
      startDate: StartDate,
      endDate: EndDate,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetDeposits",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          // console.log("data", response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          setLedgerOld(response.data.ListofRecords)
          setGrandTotal(response.data);
          // console.log(response.data.totalRecords);F
        } else if (
          (response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters") ||
          response.data.status_code === 0
        ) {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize, status, paymode, companyName, status, paymentMode, SearchReference, SearchUser, EndDate, StartDate]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        // console.log(item);
        return (
          <tr key={index}>
            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
            <td>
              {moment(item.Created_Date).format("DD/MM/YYYY HH:mm")} <br />
              {moment(item.Updated_Date).format("DD/MM/YYYY HH:mm")}
            </td>
            <td>{item.User_Name}</td>
            <td>{item.PaymentID}</td>
            <td>{item.PAYMENT_BATCH_NUM}</td>
            <td>{NumberFormat(item.AmountUSD)} PKR</td>
            <td>{item.Message}</td>
            <td>{item.Status}</td>
            <td>
              <ul
                className="inline-action"
              >
                <li>
                  <button
                    onClick={(ID, AdminRemarks) => { handleShow(item.ID, item.AdminRemarks, item.PaymentID) }}
                    className="primary "
                  >
                    <span className="material-symbols-outlined">
                      view_in_ar
                    </span>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4 className="else">
                            No Data Found
                        </h4>
          </td>
        </tr>
      );
    }
  };

  // filter by date ...................

  const filterByDate = (e) => {
    let date = moment(e.target.value).format("MM DD YYYY");

    if (date != "") {
      let member = ledgerOld.filter(
        //console.log(moment(member.Transaction_Date).format('MM DD YYYY, h:mm:ss a'))
        (member) => moment(member.Created_Date).format("MM DD YYYY") == date
      );

      setLedger(member);
    } else {
      setLedger(ledgerOld);
    }

    setisFilteredByDate(true);
  };
  // filter by inputs ...................
  const filterAlls = (e) => {
    let term = e.target.value;
    if (term !== "") {
      let filteredArray = listOfLedger.filter((object) =>
        Object.values(object).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      // console.log("filtered ", filteredArray);

      setLedger(filteredArray);
    } else {
      setLedger(ledgerOld);
    }
  };
  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: 20000,
      PaymentCompany: companyName,
      Status: status,
      PaymentMode: paymentMode,
      UserName: SearchUser,
      PAYMENT_BATCH_NUM: SearchReference,
      startDate: StartDate,
      endDate: EndDate,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetDeposits",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log("response",response.data.ListofRecords)
        let data = response.data.ListofRecords;
        let Records = response.data.totalRecords;
        let count = 1;
        const listOfLedgerNew = data.map(({ ...item }) => ({
          Serial_No: Records - count++ + 1,
          Created_Date: moment(item.Created_Date).format("DD/MM/YYYY HH:mm"),
          Updated_Date: moment(item.Updated_Date).format("DD/MM/YYYY HH:mm"),
          Username: item.User_Name,
          Transaction_ID: item.PaymentID,
          Bank_Reference: item.PAYMENT_BATCH_NUM,
          Total_deposit_PKR: "PKR " + item.AmountUSD,
          Message: item.Message,
          Status: item.Status,
        }));
        setExcelArray(listOfLedgerNew)
      })
      .catch(function (error) {
      });
  }, [pageNo, pageSize, companyName, StartDate, EndDate, status, paymentMode]);


  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <Modal
        className="modal-theme size-md p-0"
        show={show} onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deposit Slip</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start">
          <img
            src={config.root + `Personal/MemberImages/Deposits/` + DepositTID + "DepositSlip" + ".jpg"}
            className="img-fluid"
            alt="Attachment"
          />
          <div className="form_default my-3 position-relative">
            <div className="form-group">
              <label htmlFor="">Admin Remarks</label>
              <div className="inputwrap">
                <textarea type="text"
                  className="form-control"
                  readOnly
                  disabled
                  defaultValue={AdminRemarks}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
      </Modal>
      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              REJECTED DEPOSIT
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/deposit-dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row gy-3 p-3 align-items-end">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search by Status</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="Success"> Success </option>
                          <option value="Pending"> Pending </option>
                          <option value="Failed"> Failed </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default position-relative">
                      <div className="form-group search_box">
                        <label htmlFor="">Search Member</label>
                        <div className="inputwrap">
                          <input type="text"
                            className="form-control"
                            placeholder="Find by Username/Email/Member ID"
                            value={SearchUser}
                            onChange={(e) => setSearchUser(e.target.value)}
                          />
                          <span className="material-symbols-outlined">search</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default position-relative">
                      <div className="form-group search_box">
                        <label htmlFor="">Search By Bank Reference</label>
                        <div className="inputwrap">
                          <input type="text"
                            className="form-control"
                            placeholder="Find by Bank Reference"
                            value={SearchReference}
                            onChange={(e) => setSearchReference(e.target.value)}
                          />
                          <span className="material-symbols-outlined">search</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={StartDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <ul className="downloads">
                      <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                        <Link target="_blank" to={`/admin/deposit-dashboard/rejected-deposit-pdf/?StartDate=${StartDate}&EndDate=${EndDate}&paymentMode=${paymentMode}&companyName=${companyName}&status=${status}&SearchUser=${SearchUser}`}>PDF</Link>
                      </li>
                      <li className="round-button" data-tip={'Download as Excel'} data-place="bottom">
                        <JsonToExcel
                          title="EXCEL"
                          data={ExcelArray}
                          fileName="BANK REJECTED DEPOSIT"
                          btnClassName="excel-button"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Created Date <br />Updated Date</th>
                  <th>Username</th>
                  <th>Transaction ID</th>
                  <th>Bank Reference</th>
                  <th>Total deposit PKR</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Slip</th>
                </tr>
              </thead>

              <tbody>

                <tr className="filter-row">
                  <td></td>


                  <td>
                    <div className="d-flex">
                      <div className="inputwrap">
                        <input
                          type="date"
                          style={{ width: "180px" }}
                          onChange={filterByDate}
                          className="form-control"
                        />
                      </div>
                      {isFilteredByDate === true && (
                        <button
                          type="button"
                          className="button p-0 bg-transparent"
                          onClick={() => {
                            setisFilteredByDate(false);
                            setLedger(ledgerOld);
                          }}
                        >
                          <span
                            style={{ lineHeight: "unset" }}
                            className="material-symbols-outlined"
                          >
                            close
                          </span>
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        style={{ width: "120px" }}
                        placeholder="Username"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        style={{ width: "150px" }}
                        type="text"
                        placeholder="Transaction ID"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Bank Reference"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Total deposit PKR"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Message"
                        className="form-control"
                        rea
                      />
                    </div>
                  </td>

                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Status"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inputwrap">
                      <input
                        type="text"
                        placeholder="Slip"
                        onChange={(e) => filterAlls(e)}
                        className="form-control"
                        readOnly
                      />
                    </div>
                  </td>
                </tr>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>
                    {renderLedger()}
                    {
                      listOfLedger != null && listOfLedger.length > 0 &&
                      <>
                        <tr style={{ borderButtom: "none !important" }}>
                          <th colSpan={4} className="border-bottom-0 pt-3"></th>
                          <th className="text-center border-bottom-0 pt-3">Subtotal:</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'AmountUSD'))}</th>
                          <td className="border-bottom-0 pt-3"></td>
                        </tr>
                        <tr className="" style={{ borderButtom: "1px solid !important" }}>
                          <th className="pt-3" colSpan={'4'}></th>
                          <th className="text-center">Grand Total: </th>
                          <th>PKR {NumberFormat(GrandTotal.grandTotal)}</th>
                          <td style={{ borderButtom: "1px solid !important" }}></td>
                        </tr>
                      </>
                    }
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RejectedDeposit;
