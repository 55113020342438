import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import config from '../../Config';
import axios from 'axios';
import logo from '../../assets/images/logo/mag.svg';
import background from '../../assets/images/martinpay/login-bg.png';
import { toast, ToastContainer } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import { ArrowLeftStartOnRectangleIcon, EnvelopeIcon, EyeIcon, EyeSlashIcon, UserCircleIcon, XCircleIcon, CheckCircleIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';
const EmailVerify = () => {

  const User_Name = localStorage.getItem("User_Name");
  const Email = localStorage.getItem("Email_Address");
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let navigate = useNavigate();



  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();



    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {

      setLoading(true);

      const data = {
        UserName: User_Name,
        SecurityCode: event.target.SecurityCode.value,

      };

      //console.log(data);

      var api_config = {
        method: 'post',
        url: config.base_url + 'Members/VerifyEmailAddress',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      // console.log(data);


      axios(api_config)
        .then(function (response) {

          // console.log(response.data);

          if (response.data.status_code == 1) {

            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });

            navigate("/martinpay/dashboard");

          }
          else if (response.data.status_code == 0) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          setLoading(false);

        })
        .catch(function (error) {
          setLoading(false);
          toast.error('Something went wrong..', {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }

    setValidated(true);
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
   

   

  const ResendEmail = () => {
      const data = {

          UserName: User_Name,

      };

      //console.log(data);

      var api_config = {
          method: 'post',
          url: config.base_url + 'Members/SendSignUpEmail',
          headers: {
              'Content-Type': 'application/json'
          },
          data: data
      };
      //console.log(data);
      axios(api_config)
          .then(function (response) {

              if (response.data.status_code == 1) {
                  toast.success('Email Verification Code Sent Successfully', {
                      position: toast.POSITION.TOP_RIGHT
                  });

                  setIsButtonDisabled(true);
                  setTimeout(() => {
                    setIsButtonDisabled(false);
                  }, 60000);
              }
              else {

                  toast.error(response.data.status_message, {
                      position: toast.POSITION.TOP_RIGHT
                  });
              }

              setLoading(false);


          })
          .catch(function (error) {
              setLoading(false);
              toast.error('Network Error..', {
                  position: toast.POSITION.TOP_RIGHT
              });
          });
  }




  return (
    <>
      <ToastContainer />
      <section className='business-signin-form signup-page'>
        <img src={background} />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className='container'>
            <div className="login_box">
              <div className="login_box_head">
                <img src={logo} />
                <h4>Verify your email address.</h4>
                <p>Check your inbox,spam or junk folder at {Email}</p>
              </div>
              <div className="login_box_body">
                <div className="icon-input">
                  <input
                    name="SecurityCode"
                    placeholder='Enter verification code'
                    className=""
                    required
                  />
                </div>





                <button type="submit" className="button button-bs w-100">
                  Verify Code {loading ? '...' : ''} <span><CheckBadgeIcon /></span>
                </button>
                <div className='mt-3 d-flex m-auto justify-content-center'>
                  <p className='m-0 fs-6'>Did not received code?
                    <NavLink className='fs-6'
                      onClick={ResendEmail}
                      disabled={isButtonDisabled}
                    > RESEND</NavLink></p>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
};

export default EmailVerify;
